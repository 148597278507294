import { getTexture, useAssetStore } from "@/data/assetLoader/asset.store";
import { useFrame, useThree } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import {
	AdditiveBlending,
	DoubleSide,
	type Group,
	type Mesh,
	RepeatWrapping,
} from "three";

export function CloudBox() {
	const { camera } = useThree();
	const groupRef = useRef<Group>(null!);
	const cloudRef = useRef<Mesh>(null!);
	const cloud2Ref = useRef<Mesh>(null!);
	const cloud3Ref = useRef<Mesh>(null!);
	const loaded = useAssetStore((state) => state.loaded);

	const { layer0, layer1, layer3 } = useMemo(() => {
		if (!loaded) return {};
		const layer0 = getTexture("clouds/clouds00");
		const layer1 = getTexture("clouds/clouds01");
		const layer3 = getTexture("clouds/clouds03");
		layer0.wrapS = layer1.wrapS = RepeatWrapping;
		layer0.repeat.set(2, 1);
		layer1.repeat.set(2, 1);
		layer3.repeat.set(2, 1);
		return {
			layer0,
			layer1,
			layer3,
		};
	}, [loaded]);

	useFrame((state) => {
		if (!loaded) return;
		if (!cloudRef.current || !cloud2Ref.current || !cloud3Ref.current) return;
		const dt = state.clock.getDelta() * 0.12;
		cloudRef.current.rotateY(dt * 0.9);
		cloud2Ref.current.rotateY(-dt);
		cloud3Ref.current.rotateY(dt * 1.7);
		if (!groupRef.current.position.equals(camera.position)) {
			groupRef.current.position.set(
				camera.position.x,
				camera.position.y,
				camera.position.z,
			);
		}
	});

	if (!loaded) return null;
	return (
		<group ref={groupRef}>
			<mesh
				scale={[-7, -7, -7]}
				position={[0, 0, 0]}
				ref={cloudRef}
				rotation={[0.1, 0, 0]}
			>
				<sphereGeometry args={[15, 32, 16]} />
				<meshBasicMaterial
					fog={false}
					map={layer0}
					side={DoubleSide}
					color={"#555555"}
					opacity={0.14}
					transparent
					blending={AdditiveBlending}
				/>
			</mesh>
			<mesh
				scale={[-3.5, -3.5, -3.5]}
				position={[0, 0, 0]}
				ref={cloud2Ref}
				rotation={[0.1, 0, 0]}
			>
				<sphereGeometry args={[15, 32, 16]} />
				<meshBasicMaterial
					fog={false}
					map={layer1}
					side={DoubleSide}
					color={"#555555"}
					opacity={0.16}
					transparent
					blending={AdditiveBlending}
				/>
			</mesh>
			<mesh
				scale={[-4.5, -4.5, -4.5]}
				position={[0, 0, 0]}
				ref={cloud3Ref}
				rotation={[0.1, 0, 0]}
			>
				<sphereGeometry args={[15, 32, 16]} />
				<meshBasicMaterial
					fog={false}
					map={layer3}
					side={DoubleSide}
					color={"#555555"}
					opacity={0.66}
					transparent
					blending={AdditiveBlending}
				/>
			</mesh>
		</group>
	);
}
