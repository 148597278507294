import { AssetStore } from "@/data/assetLoader/asset.store";
import { DevStore } from "@/data/dev.store";
import { UserStore } from "@/data/user-settings.store";
import type { Hexagrid } from "@/lib/hexagrid/hexagrid";
import { getEntityByRef } from "@game/sim/sim.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { Html, Line } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import hotkeys from "hotkeys-js";
import { Perf } from "r3f-perf";
import { Fragment, type PropsWithChildren, useEffect, useMemo } from "react";
import { type Vector2, Vector3 } from "three";
import { Block } from "./block";
import { getBlock } from "./block.store";
import { getGrid, gridRefs } from "./grid.store";
import { ISLANDS } from "./islands/islands.functions";
import { getEntityData } from "@/data/entity/entity.data.fn";
import { CRenderer } from "@game/sim/components/CRenderer";

const currentHeight = () =>
	(UIStore().hoveredObject
		? getEntityByRef(UIStore().hoveredObject!)?.component(CRenderer)?.block
				?.coordinate?.y || 0
		: 0) / 2;

export const SidePointsDebugViz = () => {
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	const { points, center } = useMemo(() => {
		const hObj = getEntityByRef(hoveredObject);
		if (!hObj?.component(CRenderer).block) return {};
		const coord = hObj?.component(CRenderer).block!.coordinate.grid;
		if (!coord) return {};
		const grid = getGrid(coord);
		const points = grid.orderedSidePoints;
		const height = currentHeight();
		const allpoints = points.map((p) => {
			const sortedIndex = grid.getOrderedIndex(p);
			const oppositeIndex = grid.getOrderedIndex(grid.getOppositePoint(p));
			return {
				index: p.index,
				sortedIndex,
				oppositeIndex,
				position: new Vector3(
					p.position.x + grid.hexOffset.x,
					height,
					p.position.y + grid.hexOffset.y,
				),
			};
		});
		const oppositeGridCoord = grid.getOppositeGridCoord(
			grid.orderedSidePoints[10],
			grid.orderedSidePoints[11],
		);
		const oppositeGrid = getGrid(oppositeGridCoord);
		return {
			points: allpoints,
			center: new Vector3(
				oppositeGrid.hexOffset.x,
				height,
				oppositeGrid.hexOffset.y,
			),
		};
	}, [hoveredObject]);
	return (
		<group>
			{points?.map((p) => (
				<Fragment key={p.index}>
					<mesh key={p.index} position={p.position}>
						<boxGeometry args={[0.05, 0.05, 0.05]} />
						<meshBasicMaterial color={0xff0000} />
					</mesh>
					{DevStore().showHexBorders && (
						<Html position={p.position}>
							<div style={{ fontSize: "8px" }}>{p.sortedIndex}</div>
							<div className="text-red-50" style={{ fontSize: "8px" }}>
								{p.oppositeIndex}
							</div>
						</Html>
					)}
				</Fragment>
			))}
			<mesh position={center}>
				<boxGeometry args={[0.15, 0.15, 0.15]} />
				<meshBasicMaterial color={0xff00ff} />
			</mesh>
		</group>
	);
};

export const QuadComponent = ({
	quads,
	color = 0xaaaaaa,
	lineWidth = 0.5,
	...props
}: {
	quads: Vector2[][];
	y?: number;
	color?: number | string;
	lineWidth?: number;
} & PropsWithChildren) => {
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const allSegments = useMemo(() => {
		const height = currentHeight();
		return quads.flatMap((quad) => {
			const segments = [];
			for (let i = 0; i < quad.length; i++) {
				const start = quad[i];
				const end = quad[i + 1] || quad[0];
				segments.push(new Vector3(start.x, height, start.y));
				segments.push(new Vector3(end.x, height, end.y));
			}
			return segments;
		});
	}, [quads, hoveredObject]);

	if (allSegments.length === 0) return null;

	return (
		<Line
			points={allSegments}
			color={color}
			segments={true}
			lineWidth={lineWidth}
			{...props}
			opacity={0.3}
			transparent
		/>
	);
};

const getBlockNeighbours = (block: Block) => {
	const neighbours: Block[] = [];
	block.neighbours.forEach((n) => {
		const b = getBlock(n)?.[1] || new Block(n);
		if (b) {
			neighbours.push(b as Block);
		} else {
			console.error("no block", n);
		}
	});
	return (
		<Fragment key={block.coordinate.toString()}>
			{neighbours.slice(0, 5).map((b, idx) => {
				const quad = b.grid.exportQuad(b._quad);
				let color =
					b.coordinate.grid[0] === block.coordinate.grid[0] &&
					b.coordinate.grid[1] === block.coordinate.grid[1]
						? "black"
						: "red";
				if (b._quad === block._quad) {
					color = "blue";
				}
				return (
					<QuadComponent key={idx} quads={[quad]} color={color} lineWidth={2} />
				);
			})}
		</Fragment>
	);
};

const Grid = ({
	grid,
	color = 0xaaaaaa,
}: {
	grid: Hexagrid;
	color?: number | string;
}) => {
	const quads = useMemo(() => {
		const quads = grid.exportQuads();
		return quads;
	}, [grid]);
	return (
		<group>
			<QuadComponent quads={quads} color={color} />
		</group>
	);
};

export const WorldDebugger = () => {
	const { scene } = useThree();
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	useEffect(() => {
		hotkeys("shift+t", (event, _handler) => {
			event.preventDefault();
			console.log(scene.children);
		});
		return () => {
			hotkeys.unbind("shift+t");
		};
	}, [scene]);

	const grids = useMemo(() => {
		const grids = [];
		// HACKY, setting height here and all components will use that value it's not state based
		if (hoveredObject) {
			const hObj = getEntityByRef(hoveredObject);
			if (hObj?.component(CRenderer).block) {
				grids.push(getBlockNeighbours(hObj.component(CRenderer).block!));
			}
		}
		for (const grid of Array.from(gridRefs.values())) {
			grids.push(<Grid key={grid.coordinates.toString()} grid={grid} />);
		}

		return grids;
	}, [hoveredObject]);

	return (
		<>
			{grids}
			<SidePointsDebugViz />
			<Perf position="top-left" />
		</>
	);
};

Object.assign(window, {
	getIslands: () => ISLANDS.all,
	assets: AssetStore,
	listMeshes: () => Object.keys(AssetStore().meshes),
	listMaterials: () => Object.keys(AssetStore().materials),
	listTextures: () => Object.keys(AssetStore().textures),
	toggleHexBorders: () =>
		DevStore().set({ showHexBorders: !DevStore().showHexBorders }),
	toggleTerminal: () => {
		UserStore().set({
			showTerminal: !UserStore().showTerminal,
		});
	},
	listEntityData: () => Object.values(getEntityData()),
});
