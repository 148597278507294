import { getEntityByRef } from "@game/sim/sim.store";
import type { SimEntity } from "@game/sim/SimEntity";
import type { Hex } from "viem";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface IInventoryStore {
	getActiveItem: () => SimEntity | undefined;
	inventorySlots: (Hex | undefined)[];
	getInventorySlot: (idx: number) => SimEntity | undefined;
	getItemSlotNum: (item: SimEntity) => number;
}

const useInventoryStore = create<IInventoryStore>()(
	immer((_set, get) => ({
		getActiveItem: () => {
			const ref = get().inventorySlots[get().inventorySlots.length - 1];
			return ref ? getEntityByRef(ref) : undefined;
		},
		inventorySlots: [],
		getInventorySlot: (idx: number) => {
			const ref = get().inventorySlots[idx];
			return ref ? getEntityByRef(ref) : undefined;
		},
		getItemSlotNum: (item: SimEntity) => {
			const idx = get().inventorySlots.indexOf(item.ref as Hex);
			return idx;
		},
	})),
);

const InventoryStore = () => ({
	...useInventoryStore.getState(),
	set: useInventoryStore.setState,
});
export { InventoryStore, useInventoryStore };
