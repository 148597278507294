import { type ReactNode, createContext, useContext } from "react";
import type { MUDSetup } from ".";

const MUDContext = createContext<MUDSetup>(null!);

type Props = {
	children: ReactNode;
	value: MUDSetup;
};

export const MUDProvider = ({ children, value }: Props) => {
	const currentValue = useContext(MUDContext);
	if (currentValue) throw new Error("MUDProvider can only be used once");
	return <MUDContext.Provider value={value}>{children}</MUDContext.Provider>;
};

export const useMUD = () => {
	const value = useContext(MUDContext);
	if (!value) throw new Error("Must be used within a MUDProvider");
	return value;
};
