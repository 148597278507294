import { WorldGlobals } from "@game/sim/sim.store";

export const availableProducts: TTourismProduct[] = [
	{
		name: "Mangle Sagrada",
		description: "Pump your rizz into the mangroves",
		image: "/tourism/StampKiwi.png",
		price: 20,
		enabled: true,
		progress: () => {
			const { fertility_multiplier } = WorldGlobals();
			return Math.min(Math.max(Number(fertility_multiplier || 0) / 100, 0), 1);
		},
	},
	{
		name: "Explorer VISA",
		description: "Enter the jungle (for a week or two)",
		image: "/tourism/StampAustralia.png",
		price: 500,
		enabled: false,
		progress: null,
	},
] as const;

export type TTourismProduct = {
	name: string;
	description: string;
	image: string;
	price: number;
	enabled: boolean;
	progress?: (() => number) | number | null;
};

export const getTouristDataByName = (name: string) => {
	return availableProducts.find((p) => p.name === name);
};
