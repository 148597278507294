import { AssetStore } from "@/data/assetLoader/asset.store";
import { DevStore } from "@/data/dev.store";
import { getEntityData } from "@/data/entity/entity.data.fn";
import { WorldStore } from "@game/world/world.store";
import hotkeys from "hotkeys-js";
import { useEffect } from "react";

export const enableDevmode = () => {
	if (window !== undefined) {
		const windowTools = {
			entities: WorldStore().entities,
			listMeshes: () => Object.values(AssetStore().meshes),
			listMaterials: () => Object.values(AssetStore().materials),
			listTextures: () => Object.values(AssetStore().textures),
			listEntityData: () => Object.values(getEntityData()),
		};
		Object.assign(window, windowTools);
	}
};

export const toggleDev = () => {
	const mode = !DevStore().devMode;
	console.log("🛠️", mode ? "enabled" : "disabled");
	DevStore().set({ devMode: mode });
	if (mode) {
		enableDevmode();
	}
};

const SetupDevtools = () => {
	useEffect(() => {
		Object.assign(window, {
			toggleDev,
		});
		hotkeys("shift+d", toggleDev);
		return () => {
			hotkeys.unbind("shift+d", toggleDev);
		};
	}, []);
	return null;
};

export default SetupDevtools;
