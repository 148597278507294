import type { RootState } from "@react-three/fiber";
import { SimComponent } from "./SimComponent";

export class CUpdate extends SimComponent {
	update: ((state: RootState) => void) | null = null;

	setUpdateFn(fn: (state: RootState) => void) {
		this.update = fn;
	}
}
