import ConnectButton from "@/wallet/connectButton";
import SetupDevtools from "../ui.dev";
import { Cursor } from "./cursor/cursor";
import InventoryBar from "./inventory/inventoryBar";
import "../style/index.css";
import { useDevStore } from "@/data/dev.store";
import { ENV } from "@/lib/env";
import TourismBar from "./spectator/touristBar";
import { UILayout } from "./ui.layout";
import { ItemShop } from "./itemShop/itemShop";
import LoadingScreen from "./loadingScreen/loadingScreen";
import { useUIStore } from "../ui.store";
import { AnimatePresence } from "framer-motion";
import GameMenu from "./gameMenu/gameMenu";
import { CSSEffects } from "./CSSEffects";

export default function UIRoot() {
	const developer = useDevStore((state) => state.devMode);
	const { showInventory, showWalletButton, showTourismbar, showItemShop } =
		useUIStore((state) => state.uiState);

	const isDeveloper = developer || ENV.DEV;

	return (
		<>
			<AnimatePresence>
				<CSSEffects key="CSS-effects" />
				{isDeveloper && <SetupDevtools key="devtools" />}
				<LoadingScreen key="loading-screen" />
				{showInventory && <InventoryBar key="inventory-bar" />}
				{showInventory && <Cursor />}
				{showTourismbar && (
					<UILayout data-name="tourism-mode key='tourism-mode'">
						<TourismBar key="tourist-bar" />
						{showWalletButton && <ConnectButton key="connect-button" />}
					</UILayout>
				)}
				<GameMenu key="game-menu" />
				{showItemShop && <ItemShop key="item-shop" />}
			</AnimatePresence>
		</>
	);
}
