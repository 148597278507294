import { getEntityDataByID } from "@/data/entity/entity.data.fn";
import type { SimEntity } from "@game/sim/SimEntity";
import { createPrefabInstance } from "@game/sim/sim.functions";
import { addBlock } from "@game/world/block.store";
import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import * as block from "../world/block";
import { WorldCoordinate } from "../world/world.types";
import { CRenderer } from "./components/CRenderer";

export const createEntity = (ref: string, entityTypeId: number): SimEntity => {
	const prefab = getEntityDataByID(entityTypeId);
	if (!prefab) throw new Error(`No prefab for entity type ${entityTypeId}`);
	const entity = createPrefabInstance(prefab, ref) as SimEntity;
	// Setup entity renderer when necessary
	if (entity.component(CRenderer)) {
		const coord = getMUDState().getValue(tables.WorldCoordinate, {
			inst: ref as Hex,
		});
		if (coord) {
			const c = new WorldCoordinate(
				[coord.x, coord.y],
				coord.quad,
				coord.height,
			);
			entity.component(CRenderer).setBlock(new block.Block(c));
			addBlock(entity);
			const orientation = getMUDState().getValue(tables.Orientation, {
				inst: ref as Hex,
			});
			if (orientation) {
				entity.component(CRenderer).setOrientation(orientation.orientation);
			}
		}
	}
	return entity;
};
