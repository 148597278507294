import type { THexCoordinate } from "@game/world/world.types";
import kleur from "kleur";
import { Vector2 } from "three";
import { Neighbour, Point, Quad, Triangle } from "./hexagrid.types";
import { SeedFactory } from "./seedfactory";

export const gridToLog = (grid: Hexagrid): string => {
  return `${kleur.white("GRID")} [${kleur.cyan(grid.coordinates.toString())}]`;
};

const NEIGHBOUR_DIRECTIONS = [
  [0, -1],
  [1, -1],
  [1, 0],
  [0, 1],
  [-1, 1],
  [-1, 0],
];

export class Hexagrid {
  coordinates: THexCoordinate;
  random: SeedFactory;
  baseQuadCount = 0;
  searchIterationCount = 0;
  sideSize = 6;
  centerOffset = new Vector2(0, -this.sideSize + 0.5); // centers the grid
  points: Point[] = [];
  triangles: Triangle[] = [];
  quads: Quad[] = [];
  subdQuads: Quad[] = [];
  pointNeighbours: Neighbour[] = [];
  hexOffset: Vector2;
  orderedSidePoints: Point[] = [];

  constructor({
    coordinates,
    seed = 42,
    searchIterationCount,
    hexOffset = new Vector2(0, 0),
  }: {
    coordinates: THexCoordinate;
    seed?: number;
    searchIterationCount: number;
    hexOffset: Vector2;
  }) {
    const { sideSize, centerOffset } = this;
    this.coordinates = coordinates;
    this.hexOffset = hexOffset;
    this.random = new SeedFactory({ seed });
    this.searchIterationCount = searchIterationCount;
    this.points = [];
    this.triangles = [];
    this.quads = [];

    const sideLength = 0.5 * Math.tan((Math.PI / 180) * 60);

    // Generate Vertices
    for (let x = 0; x < sideSize * 2 - 1; x++) {
      const height = x < sideSize ? sideSize + x : sideSize * 3 - 2 - x;
      const deltaHeight = sideSize - height * 0.5;

      for (let y = 0; y < height; y++) {
        const isSide =
          x === 0 || x === sideSize * 2 - 2 || y === 0 || y === height - 1;
        this.points.push(
          new Point(
            this.points.length,
            new Vector2(
              (x - sideSize + 1) * sideLength + centerOffset.x,
              y + deltaHeight + centerOffset.y
            ),
            isSide
          )
        );
        if (isSide) {
          const p = this.points[this.points.length - 1];
        }
      }
    }

    // Generate Triangles
    let offset = 0;
    for (let x = 0; x < sideSize * 2 - 2; x++) {
      const height = x < sideSize ? sideSize + x : sideSize * 3 - 2 - x;
      if (x < sideSize - 1) {
        for (let y = 0; y < height; y++) {
          this.triangles.push(
            new Triangle(
              offset + y,
              offset + y + height,
              offset + y + height + 1
            )
          );
          if (y >= height - 1) {
            break;
          }
          this.triangles.push(
            new Triangle(offset + y + height + 1, offset + y + 1, offset + y)
          );
        }
      } else {
        // right side
        for (let y = 0; y < height - 1; y++) {
          this.triangles.push(
            new Triangle(offset + y, offset + y + height, offset + y + 1)
          );
          if (y >= height - 2) {
            break;
          }
          this.triangles.push(
            new Triangle(
              offset + y + 1,
              offset + y + height,
              offset + y + height + 1
            )
          );
        }
      }
      offset += height;
    }

    // Triangles to Quads
    let triIndex = 0;
    const adjacents = new Array(3);
    let exit = 9999;

    while (true && exit > 0) {
      let searchCount = 0;
      while (
        searchCount < this.searchIterationCount &&
        !this.triangles[triIndex].valid
      ) {
        triIndex = this.random.Range(0, 999999999) % this.triangles.length;
        searchCount++;
      }
      if (searchCount === this.searchIterationCount) {
        break;
      }

      const adjacentCount = this.getAdjacentTriangles(triIndex, adjacents);
      if (adjacentCount > 0) {
        const i1 = triIndex;
        const i2 = adjacents[0];
        const indices = [
          this.triangles[i1].a,
          this.triangles[i1].b,
          this.triangles[i1].c,
          this.triangles[i2].a,
          this.triangles[i2].b,
          this.triangles[i2].c,
        ];
        indices.sort((a, b) => a - b);
        const quadIndices: number[] = new Array(4);
        let quadIndexCount = 1;
        quadIndices[0] = indices[0];
        for (let i = 1; i < 6; i++) {
          if (indices[i] !== indices[i - 1]) {
            quadIndices[quadIndexCount++] = indices[i];
          }
        }
        console.assert(quadIndexCount === 4);
        let tempIndices: number[] = [
          quadIndices[0],
          quadIndices[2],
          quadIndices[3],
          quadIndices[1],
        ];
        tempIndices = this.ensureCounterClockwiseWinding(tempIndices);
        this.quads.push(
          new Quad(
            tempIndices[0],
            tempIndices[1],
            tempIndices[2],
            tempIndices[3]
          )
        );

        this.triangles[triIndex].valid = false;
        this.triangles[adjacents[0]].valid = false;
      }

      exit--;
    }

    const middles: Record<number, number> = {};

    this.baseQuadCount = this.quads.length;
    for (let i = 0; i < this.baseQuadCount; i++) {
      const quad = this.quads[i];
      // const index = quad.a;
      const indexCenter = this.points.length;
      const p = this.getQuadCenter(quad);
      this.points.push(new Point(this.points.length, p));
      this.subdivide(4, quad.points, middles, indexCenter);
    }

    for (const triangle of this.triangles) {
      if (triangle.valid) {
        // const index = triangle.a;
        const indexCenter = this.points.length;
        const p = this.getTriangleCenter(triangle);
        this.points.push(new Point(this.points.length, p));
        this.subdivide(3, triangle.points, middles, indexCenter);
      }
    }

    // vertice neighbours
    this.pointNeighbours = new Array(this.points.length);
    this.subdQuads = [];

    for (let i = this.baseQuadCount; i < this.quads.length; i++) {
      const quad = this.quads[i];
      this.subdQuads.push(quad);
      const index = quad.points;
      for (let j = 0; j < 4; j++) {
        const index1 = index[j];
        const index2 = index[(j + 1) & 3];
        {
          if (!this.pointNeighbours[index1])
            this.pointNeighbours[index1] = new Neighbour();
          const neighbour = this.pointNeighbours[index1];
          let good = true;
          for (let k = 0; k < neighbour.neighbourCount; k++) {
            if (neighbour.neighbours[k] === index2) {
              good = false;
              break;
            }
          }
          if (good) {
            console.assert(neighbour.neighbourCount < 6);
            neighbour.neighbours[neighbour.neighbourCount++] = index2;
          }
        }
        {
          if (!this.pointNeighbours[index2])
            this.pointNeighbours[index2] = new Neighbour();
          const neighbour = this.pointNeighbours[index2];
          let good = true;
          for (let k = 0; k < neighbour.neighbourCount; k++) {
            if (neighbour.neighbours[k] === index1) {
              good = false;
              break;
            }
          }
          if (good) {
            console.assert(neighbour.neighbourCount < 6);
            neighbour.neighbours[neighbour.neighbourCount++] = index1;
          }
        }
      }
    }

    for (const q of this.quads) {
      let side = 0;
      for (let i = 0; i < 4; i++) {
        if (this.points[q.points[i]].side) {
          side++;
        }
        if (side === 2) {
          q.side = true;
          break;
        }
      }
      q.neighbours = this.getAdjacentQuads(q);
    }

    this.orderedSidePoints = this.getOrderedSidePoints();
  }

  private ensureCounterClockwiseWinding(indices: number[]): number[] {
    const quadPoints = indices.map((p) => this.points[p].position);
    const area = this.calculateSignedArea(quadPoints);
    if (area < 0) {
      // Swap two vertices to change winding order
      [indices[1], indices[3]] = [indices[3], indices[1]];
    }
    return indices;
  }

  private calculateSignedArea(quadPoints: Vector2[]): number {
    // Assuming quadPoints are in order: [a, b, c, d]
    // Calculate the signed area of the quad
    let area = 0;
    for (let i = 0; i < quadPoints.length; i++) {
      const j = (i + 1) % quadPoints.length;
      area +=
        quadPoints[i].x * quadPoints[j].y - quadPoints[j].x * quadPoints[i].y;
    }
    return area * 0.5;
  }

  relax(iterations = 1) {
    for (let i = 0; i < iterations; i++) {
      const forces: Vector2[] = [];

      for (const quad of this.subdQuads) {
        const force = new Vector2(0, 0);
        const center = this.getQuadCenter(quad);

        for (let i = 0; i < 4; i++) {
          force.add(this.getQuadPoint(quad, i).sub(center));
          force.set(force.y, -force.x);
        }

        force.divideScalar(4);

        for (let i = 0; i < 4; i++) {
          force.set(force.y, -force.x);
          const p = this.getQuadPoint(quad, i);
          if (!forces[quad.points[i]])
            forces[quad.points[i]] = new Vector2(0, 0);
          forces[quad.points[i]].add(center.clone().add(force).sub(p));
        }
      }

      const scale = 0.1;

      for (let i = 0; i < this.points.length; i++) {
        if (this.points[i].side) {
          continue;
        }
        const p = this.points[i];
        if (forces[i]) {
          p.position.add(forces[i].multiplyScalar(scale));
        }
      }
    }
  }

  private subdivide(
    count: number,
    index: number[],
    middles: Record<number, number>,
    indexCenter: number
  ) {
    const halfSegmentIndex: number[] = [];
    for (let j = 0; j < count; j++) {
      const indexA = index[j];
      const indexB = index[(j + 1) % count];
      const key = (Math.min(indexA, indexB) << 16) + Math.max(indexA, indexB);
      if (!(key in middles)) {
        halfSegmentIndex[j] = this.points.length;
        const isSide = this.points[indexA].side && this.points[indexB].side;
        this.points.push(
          new Point(
            this.points.length,
            this.points[indexA].position
              .clone()
              .add(this.points[indexB].position)
              .multiplyScalar(0.5),
            isSide
          )
        );
        middles[key] = halfSegmentIndex[j];
      } else {
        halfSegmentIndex[j] = middles[key];
      }
    }
    for (let j = 0; j < count; j++) {
      const nextIndex = (j + 1) % count;
      this.quads.push(
        new Quad(
          indexCenter,
          halfSegmentIndex[j],
          index[nextIndex],
          halfSegmentIndex[nextIndex]
        )
      );
    }
  }

  getOrderedSidePoints(): Point[] {
    if (this.orderedSidePoints.length > 0) return this.orderedSidePoints;
    const sidePoints = this.points.filter((p) => p.side);

    // calculate the angle to the vertical from the center
    const angleToVerticalFromCenter = (point: Point) => {
      const dy = point.position.y;
      const dx = point.position.x;
      let angle = Math.atan2(dy, dx) - Math.PI / 2;
      if (angle < 0) angle += 2 * Math.PI;
      return angle;
    };

    sidePoints.sort(
      (a, b) => angleToVerticalFromCenter(a) - angleToVerticalFromCenter(b)
    );

    // Ensure that the point with index 0 is the starting point
    const zeroIndex = sidePoints.findIndex((p) => p.index === 0);
    if (zeroIndex > 0) {
      const part1 = sidePoints.slice(zeroIndex);
      const part2 = sidePoints.slice(0, zeroIndex);
      this.orderedSidePoints = [...part1, ...part2];
    } else {
      this.orderedSidePoints = sidePoints;
    }

    return this.orderedSidePoints;
  }

  getOrderedIndex(point: Point): number {
    return this.orderedSidePoints.indexOf(point);
  }

  getOppositePointIndexByIndex(index: number): number {
    if (this.orderedSidePoints.length === 0) {
      this.orderedSidePoints = this.getOrderedSidePoints();
    }
    return this.getOppositePointIndex(this.orderedSidePoints[index]);
  }

  getOppositePointIndex(point: Point): number {
    if (this.orderedSidePoints.length === 0) {
      this.orderedSidePoints = this.getOrderedSidePoints();
    }
    const totalSidePoints = this.orderedSidePoints.length;
    const currentIndex = this.orderedSidePoints.indexOf(point);
    let index = -200;
    if (currentIndex === -1)
      throw new Error("Point index not found among side points.");
    const major = (currentIndex > 0 ? Math.floor(currentIndex / 10) : 0) * 10;
    const oppositeIndex =
      (major + (10 - (currentIndex - major)) - 30) % totalSidePoints;
    if (oppositeIndex > 0) index = oppositeIndex;
    else index = (totalSidePoints - -oppositeIndex) % totalSidePoints;
    return index;
  }

  getOppositePoint(point: Point): Point {
    if (this.orderedSidePoints.length === 0) {
      this.orderedSidePoints = this.getOrderedSidePoints();
    }
    return this.orderedSidePoints[this.getOppositePointIndex(point)];
  }

  getSideQuadsByOrderedPointIndices(indexA: number, indexB: number): Quad[] {
    if (this.orderedSidePoints.length === 0) {
      this.orderedSidePoints = this.getOrderedSidePoints();
    }
    const pointA = this.orderedSidePoints[indexA];
    const pointB = this.orderedSidePoints[indexB];
    if (pointA === undefined || pointB === undefined) {
      console.error(indexA, indexB);
      throw new Error("point not found");
    }
    const quads: Quad[] = [];
    // go through side quads and find the quad that contains both points
    for (let i = 0; i < this.subdQuads.length; i++) {
      const quad = this.subdQuads[i];
      if (
        quad.points.includes(pointA.index) &&
        quad.points.includes(pointB.index)
      ) {
        quads.push(quad);
      }
    }
    return quads;
  }

  getOppositeGridCoord(pointA: Point, pointB: Point): THexCoordinate {
    const indexA = this.getOrderedIndex(pointA);
    const indexB = this.getOrderedIndex(pointB);
    const index = Math.max(indexA, indexB) - 1;
    const lutIndex = Math.floor(index / 10);
    const lut = NEIGHBOUR_DIRECTIONS[lutIndex];
    return [this.coordinates[0] + lut[0], this.coordinates[1] + lut[1]];
  }

  private getAdjacentTriangles(triIndex: number, adjacents: number[]): number {
    const r = [
      this.triangles[triIndex].a,
      this.triangles[triIndex].b,
      this.triangles[triIndex].c,
    ];

    let index = 0;

    for (let i = 0; i < this.triangles.length; i++) {
      if (i === triIndex || !this.triangles[i].valid) {
        continue;
      }
      const local = [
        this.triangles[i].a,
        this.triangles[i].b,
        this.triangles[i].c,
      ];

      let sharecount = 0;
      for (let j = 0; j < 3; j++) {
        for (let k = 0; k < 3; k++) {
          if (r[j] === local[k]) {
            sharecount++;
            break;
          }
        }
      }
      console.assert(sharecount < 3);
      if (sharecount === 2) {
        console.assert(index < 3);
        adjacents[index++] = i;
      }
    }
    return index;
  }

  getAdjacentQuads(quad: Quad): Quad[] {
    const neighbours: Quad[] = [];
    for (let i = 0; i < this.subdQuads.length; i++) {
      let similar = 0;
      const n = this.subdQuads[i];
      for (let j = 0; j < n.points.length; j++) {
        for (let k = 0; k < quad.points.length; k++) {
          if (n === quad) continue;
          if (n.points[j] === quad.points[k]) {
            similar++;
          }
          if (similar === 2) {
            if (!neighbours.includes(n)) neighbours.push(n);
            break;
          }
        }
      }
    }
    return neighbours;
  }

  getQuadCenter(quad: Quad): Vector2 {
    const sum = new Vector2(0, 0);
    for (let i = 0; i < 4; i++) {
      sum.add(this.getQuadPoint(quad, i));
    }
    sum.divideScalar(4);
    return sum;
  }

  getTriangleCenter(triangle: Triangle): Vector2 {
    const sum = new Vector2(0, 0);
    for (let i = 0; i < 3; i++) {
      sum.add(this.getPoint(triangle.points[i]));
    }
    sum.divideScalar(3);
    return sum;
  }

  getQuadPoint(quad: Quad, i: number): Vector2 {
    return this.points[quad.points[i]].position.clone();
  }

  getPoint(i: number): Vector2 {
    return this.points[i].position.clone();
  }

  getQuadIndex(quad: Quad): number {
    return this.subdQuads.indexOf(quad);
  }

  getQuadByIndex(index: number): Quad {
    return this.subdQuads[index];
  }

  /**
   * Calculates the center position of a quad and applies the hex offset.
   * @param quad The quad for which to calculate the center position.
   * @returns The center position of the quad with the hex offset applied.
   */
  exportQuadCenter(quad: Quad): Vector2 {
    return this.getQuadCenter(quad).clone().add(this.hexOffset);
  }

  getQuadPoints(quad: Quad): Vector2[] {
    return quad.points.map((p) => this.points[p].position.clone());
  }

  /**
   * Exports the quad with the hexagrid offset.
   * @param quad - The quad to export.
   * @returns An array of Vector2 points with the hexagrid offset applied.
   */
  exportQuad(quad: Quad): Vector2[] {
    return quad.points.map((p) =>
      this.points[p].position.clone().add(this.hexOffset)
    );
  }

  /**
   * Exports the quads of the hexagrid with the hexagrid offset.
   * @returns An array of arrays of Vector2 representing the exported quads.
   */
  exportQuads(): Vector2[][] {
    return this.subdQuads.map((q) => this.exportQuad(q));
  }

  getVertices(): Vector2[] {
    return this.points.map((p) => p.position);
  }

  pointInQuad(point: Vector2, quad: Quad): boolean {
    const quadPoints = quad.points.map((p) => this.points[p].position);
    // Check if the point is inside the convex hull of the quad
    const edges = [
      [quadPoints[0], quadPoints[1]],
      [quadPoints[1], quadPoints[2]],
      [quadPoints[2], quadPoints[3]],
      [quadPoints[3], quadPoints[0]],
    ];

    for (const edge of edges) {
      const side =
        (point.x - edge[0].x) * (edge[1].y - edge[0].y) -
        (point.y - edge[0].y) * (edge[1].x - edge[0].x);
      if (side > 0) {
        // Point is outside the quad
        return false;
      }
    }
    // Point is inside the quad
    return true;
  }

  getNearestQuad(position: Vector2): Quad | undefined {
    for (const quad of this.subdQuads) {
      if (this.pointInQuad(position, quad)) {
        return quad;
      }
    }
    return undefined;
  }

  static hashMult = 10;

  private getQuadHashIndex(quad: Quad): [number, number] {
    const center = this.getQuadCenter(quad);
    return [
      Math.floor(center.x * Hexagrid.hashMult),
      Math.floor(center.y * Hexagrid.hashMult),
    ];
  }

  getQuadHash(quad: Quad): string {
    const indexToString = (index: [number, number]) =>
      `${index[0].toString()},${index[1].toString()}`;

    const hash = indexToString(this.getQuadHashIndex(quad));
    return hash;
  }

  checkValidity() {
    // check all quadHashes and see if we find duplicates
    const hashes: string[] = [];
    for (const quad of this.subdQuads) {
      const hash = this.getQuadHash(quad);
      if (hashes.includes(hash)) {
        console.log("duplicate hash", hash);
      }
      hashes.push(hash);
    }
  }
}
