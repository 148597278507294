import type { SimEntity } from "./SimEntity";
import type { TSimPrefab } from "./types/sim.types";

export interface ISimEntityPrefab {
	inst: SimEntity;
	prefab: TSimPrefab;
}

export class SimEntityPrefab implements ISimEntityPrefab {
	inst: SimEntity;
	prefab: TSimPrefab;

	constructor(instance: SimEntity) {
		this.prefab = this.constructor as unknown as TSimPrefab;
		this.inst = instance;
		this.inst.prefab = this.prefab;
	}
}
