/**
 * @file console.style is a micro-library for intuitively styling browser console text with CSS.
 * @author Daniel Lamb <dlamb.open.source@gmail.com>
 */

type ConsoleStyles = {
  [key: string]: (text: string) => string;
};

export const debugStyle = (() => {
  const colors = 'Black Blue Cyan Gray Green Magenta Red White Yellow'.split(' ');
  const rxTags = /<(css|b|i)(?:=['"](.*?)['"])?>(.*?)<\/(?:css|b|i)>/ig;
  const rxRules = /([a-z\-]+)\s?:\s?([^;'"]+);?/ig;
  const rxTokens = /%[sdifoO]/g;
  const rxImgs = /<img=['"](.*?)['"]>/ig;
  const bg = 'background';
  const px = 'px';

  function wrap(text: string, rules: string): string {
    return `<css="${rules}">${text}</css=>`;
  }

  function parse(log: string): string[] {
    const args = [log];
    let text = log;
    text = text.replace(rxImgs, (_matchI, $1I) => {
      let width = '';
      let height = '';
      const styles: string[] = ['font-size:1px'];
      $1I.replace(rxRules, (_matchR, $1R, $2R) => {
        switch ($1R) {
          case bg:
          case `${bg}-image`:
            styles.push(`${$1R}: ${$2R}`, `${bg}-repeat:no-repeat`);
            break;
          case 'width':
            width = $2R;
            break;
          case 'height':
            height = $2R;
            styles.push(`line-height:${$2R}`);
            break;
        }
      });
      const halfWidth = Math.ceil(parseInt(width) / 2).toString();
      const halfHeight = Math.ceil(parseInt(height) / 2).toString();
      styles.push(`${bg}-size:${width} ${height}`);
      styles.push(`padding:${halfHeight}${px} ${halfWidth}${px}`);
      return wrap('', styles.join(';'));
    });
    args[0] = text.replace(rxTags, (_matchT, $1T, $2T, $3T) => {
      $2T = $2T || '';
      $3T = $3T.replace(rxTokens, '');
      switch ($1T) {
        case 'b':
          $2T += ';font-weight:bold';
          break;
        case 'i':
          $2T += ';font-style:italic';
          break;
      }
      args.push($2T, '');
      return `%c${$3T}%c`;
    });
    return args;
  }

  const consoleColors: ConsoleStyles = {};
  colors.forEach(color => {
    consoleColors[color.toLowerCase()] = (text: string) => wrap(text, `color:${color}`);
    consoleColors[`bg${color}`] = (text: string) => wrap(text, `${bg}-color:${color}`);
  });

  function format(...args: any[]) {
    const argsToLog = parse(args.shift());
    return [...argsToLog.concat(args)];
  }

  return {
    colors: consoleColors,
    wrap,
    format
  };
})();

