import { TBluePrint, registerBlueprint } from "@/data/blueprint/blueprint.data";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import Audio from "@game/audio/audioPlayer";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CStackable } from "@game/sim/components/CStackable";
import { CTransmute } from "@game/sim/components/CTransmute";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";

export class ItemLimestonePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("limestone");
		e.addComponent(CStackable).setMaxSize(20);
		e.addComponent(CBuildable);
		e.addComponent(CTransmute).setCombos([
			[30202, 30401], // ochre to red
			[30012, 30402], // goldenrod to goldenrod
			[30013, 30403], //blackberries
			[30201, 30406], // lapis to blue
			[30203, 30407], // meteorite to black
			[30204, 30405], // turqoise to turqoise
			[30011, 30404], // carrot to orange
			[30014, 30410], //yam
			[30015, 30409], //spinach
			[30016, 30408], //pepper
		]);
	}
}

const entityData: TEntityDefinition = {
	name: "Limestone",
	simEntityPrefab: ItemLimestonePrefab,
	id: 30003,
	metadata: {
		icon: "limestone.webp",
	},
};

const limestoneWall: TBluePrint = {
	name: "LimestoneWall",
	displayName: "Limestone Wall",
	ingredients: [{ amount: 1, name: "Limestone" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	canBuildFn: (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		return true;
	},
	onBuildFn: () => {
		Audio.playSound("PUT", { volume: 0.125 });
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		target: SimEntity,
	) => {
		const { action_buildWall } = getSystemCalls();
		const faceIndex = UIStore().faceIndex;
		const block = target.component(CRenderer).block;
		if (faceIndex === -1) throw new Error("faceIndex not set");
		if (!block) throw new Error("no block");
		console.log(`${faceIndex}build the wall`);
		await action_buildWall(doer.ref, target.ref, item.ref, faceIndex);
	},
};
registerBlueprint(limestoneWall);

registerEntity(entityData);
