import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { CPlayerActionPicker } from "@game/sim/components/CPlayerActionPicker";
import { CRenderer } from "@game/sim/components/CRenderer";
import { getEntityByRef } from "@game/sim/sim.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { UI_EVENTS } from "@game/ui/ui.events";
import { UIStore } from "@game/ui/ui.store";
import { WorldStore } from "@game/world/world.store";

const getPlayerActions = (object?: SimEntity) => {
	if (UIStore().activeAction) {
		return { actions: [], altActions: [] };
	}
	const hoveredItem = UIStore().hoveredItem;
	if (UIStore().isHoveringSlot && !hoveredItem) {
		// when hovering an empty slot we don't want to search for actions, default to put in slot
		if (UIStore().actions.length > 0 || UIStore().altActions.length > 0) {
			UIStore().set({
				actions: [],
				altActions: [],
			});
		}
		return { actions: [], altActions: [] };
	}
	const hoveredObject =
		hoveredItem || object || getEntityByRef(UIStore().hoveredObject);
	let actions: BufferedAction[] = [];
	const altActions: BufferedAction[] = [];
	if (hoveredObject !== undefined) {
		const player = getEntityByRef(WorldStore().playerRef);
		const block = hoveredObject?.component(CRenderer)?.block || null;
		const clickActions = player
			?.component(CPlayerActionPicker)
			?.getClickActions({ target_ent: hoveredObject, pos: block });
		actions = clickActions || [];
	}
	UIStore().set({
		actions,
		altActions,
	});
	return {
		actions,
		altActions,
	};
};

UI_EVENTS.on("getPlayerActions", (_e) => getPlayerActions());
