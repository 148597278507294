import { Sigil } from "./sigil";
import { useSigilStore } from "./sigils.store";

export const SigilsSystem = () => {
	const { sigils, playerSigil } = useSigilStore();

	return (
		<>
			{[...sigils, playerSigil].map(
				(sigil) => sigil && <Sigil key={sigil.id} sigil={sigil} />,
			)}
		</>
	);
};
