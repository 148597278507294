import type { MUDChain } from "@latticexyz/common/chains";
import { arbitrumSepolia as _arbitrumSepolia } from "viem/chains";

export const arbitrumSepolia = Object.assign(_arbitrumSepolia as MUDChain, {
	rpcUrls: {
		default: {
			http: [
				"https://summer-responsive-orb.arbitrum-sepolia.quiknode.pro/ba6c2f470d925c7987b25663f2bba086c7d9015b/",
			],
			webSocket: [
				"wss://summer-responsive-orb.arbitrum-sepolia.quiknode.pro/ba6c2f470d925c7987b25663f2bba086c7d9015b/",
			],
		},
	},
});
