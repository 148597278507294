import { APP_DATA, APP_PLAYTEST_ACCESS } from "@/data/app.data";
import { ENV } from "./env";
import { Debug } from "./debug/debug";
import { validateKey } from "./utils.accessCode";

export interface WaitlistResponseData {
	amount_referred: number;
	created_at: string;
	email: string;
	priority: number;
	referral_link: string;
	referral_token: string;
	referred_by_signup_token: string | null;
	referred_by_waiter_token: string | null;
	removed_date: string | null;
	uuid: string;
	verified: boolean;
	waitlist_api_key: string;
	waitlist_id: number;
}

interface SignupData {
	email: string;
	waitlist_id?: number;
}

export interface WaitlistResponse {
	data?: WaitlistResponseData;
	error?: {
		message: string;
	};
}

export const copyToClipboard = (text: string) => {
	navigator.clipboard
		.writeText(text)
		.then(() => {
			console.log("Link copied to clipboard");
		})
		.catch((error) => {
			console.error("Failed to copy link to clipboard:", error);
		});
};

export const validateEmail = (email: string) => {
	const re = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
	return re.test(email);
};

export const createSignup = async (
	data: SignupData,
): Promise<WaitlistResponse> => {
	const waitlist_id = APP_DATA.waitlistID;
	const referral_link = document.URL;
	Object.assign(data, { waitlist_id, referral_link });
	try {
		const response = await fetch("https://api.getwaitlist.com/api/v1/signup", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			throw new Error(`Error: ${response.status}`);
		}

		const responseData = await response.json();
		console.log(responseData);
		return { data: responseData };
	} catch (err) {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		return { error: { message: (err as any).message } };
	}
};

export type VerificationResponse = {
	canSignup: boolean;
	message: string;
	error?: boolean;
};

export const validateSignup = async (
	data: SignupData,
): Promise<VerificationResponse> => {
	Debug("Waitlist").identity({ email: data.email });
	try {
		console.log(data.email);
		const signupData =
			data.email === "test@test.com"
				? {
						data: {
							canSignup: false,
							message: "no cheating",
							errors: false,
							priority: 99 ** 100,
						},
					}
				: await createSignup(data);

		if (!signupData.data) throw Error("no signup data");
		// check if we have an access code in ENV
		if (ENV.ACCESS_CODE) {
			// console.log("🌱 ACCESS_CODE", ENV.ACCESS_CODE);
			const key = await validateKey(
				ENV.ACCESS_CODE,
				APP_PLAYTEST_ACCESS.accessCode,
			);
			const matchesAccessCode =
				(ENV.ACCESS_CODE &&
					APP_PLAYTEST_ACCESS.accessCode === ENV.ACCESS_CODE) ||
				key !== false;
			if (!matchesAccessCode) {
				return {
					canSignup: false,
					message: "invalid access code",
					error: false,
				};
			}
			return { canSignup: true, message: "available", error: false };
		}
		if (signupData.data?.priority > APP_PLAYTEST_ACCESS.priorityMaximum) {
			return { canSignup: false, message: "priority too low", error: false };
		}
		return { canSignup: true, message: "available", error: false };
	} catch (err: unknown) {
		return { canSignup: false, message: (err as Error).message, error: true };
	}
};
