import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemTicketPrefab extends SimEntityPrefab {}

const entityData: TEntityDefinition = {
	name: "Entry Ticket",
	simEntityPrefab: ItemTicketPrefab,
	id: 10,
	metadata: {
		icon: "ticket.webp",
		price: 5.55,
	},
};

registerEntity(entityData);
