import { getTexture } from "@/data/assetLoader/asset.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import type { Block } from "@game/world/block";
import type { BoxGeometryProps } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	AdditiveBlending,
	type BufferGeometry,
	type Face,
	type Intersection,
	type Object3D,
	type Object3DEventMap,
} from "three";
import {
	extrudeBlock,
	extrudeSides,
	getSideFromHit,
} from "./constructableInput.fn";
import type { SimEntity } from "@game/sim/SimEntity";
import { CConstruction } from "@game/sim/components/CConstruction";
import { CRenderer } from "@game/sim/components/CRenderer";

export function ConstructableInput({
	entity,
}: { entity: SimEntity } & BoxGeometryProps) {
	const [side, setSide] = useState<number>(-1);
	const lastFace = useRef<Face | undefined>(undefined);
	const isBuildMode = useUIStore((state) => state.isBuildMode);

	const { block, blockCollisionGeometry, sides } = useMemo(() => {
		// SETUP
		const block = entity.component(CRenderer)?.block;
		if (!block) {
			throw new Error(
				`ConstructableInput ${entity.ref} has no CRenderer block`,
			);
		}
		// extrude the collision geo
		const blockCollisionGeometry = extrudeBlock(block);
		// now for each side we extrude all cursor indicators
		const sides = extrudeSides(block);
		return { block, blockCollisionGeometry, sides };
	}, [entity]);

	const onMouseOver = useCallback(
		(data: unknown) => {
			const hit = data as Intersection<Object3D<Object3DEventMap>>;
			if (!entity.component(CConstruction)) return;
			if (hit.face === lastFace.current) return;
			if (isBuildMode) {
				lastFace.current = hit.face || undefined;
				const s = getSideFromHit(blockCollisionGeometry, hit, block);
				if (side !== s) {
					setSide(s);
					UIStore().setFaceIndex(s);
				}
			}
		},
		[entity, block, side, blockCollisionGeometry, isBuildMode],
	);

	const onMouseLeave = useCallback(() => {
		if (!entity.component(CConstruction)) return;
		lastFace.current = undefined;
		setSide(-1);
		UIStore().setFaceIndex(-1);
	}, [entity]);

	useEffect(() => {
		entity.listenForEvent("mouseover", onMouseOver);
		entity.listenForEvent("mouseleave", onMouseLeave);
		return () => {
			entity.removeEventListener("mouseover", onMouseOver);
			entity.removeEventListener("mouseleave", onMouseLeave);
		};
	}, [entity, onMouseLeave, onMouseOver]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: we don't refresh all building indicators throughout the game plz ok
	const buildIndicators = useMemo(() => {
		return (
			<group>
				{sides.map((geo, i) => {
					const visible = side === i && isBuildMode;
					return (
						<BuildIndicator
							key={i}
							block={block}
							geo={geo}
							index={i}
							visible={visible}
						/>
					);
				})}
			</group>
		);
	}, [block, side, sides]);

	if (!block) return null;
	return (
		<>
			<group>
				{/* Collider for input */}
				<mesh
					position={block.position}
					userData={{ entityRef: entity.ref }}
					geometry={blockCollisionGeometry}
				>
					<meshBasicMaterial
						color="white"
						visible={false}
						transparent
						opacity={0.5}
						map={getTexture("ui/dev_measure")}
					/>
				</mesh>
				{/* // Render the face indicators */}
				{buildIndicators}
			</group>
		</>
	);
}

export const BuildIndicator = ({
	block,
	geo,
	index,
	visible,
}: {
	block: Block;
	geo: BufferGeometry;
	index: number;
	visible: boolean;
}) => {
	return (
		<mesh
			key={index}
			position={block.position}
			geometry={geo}
			matrixAutoUpdate={visible}
			matrixWorldAutoUpdate={visible}
		>
			<meshBasicMaterial
				visible={visible && !UIStore().isHoveringSlot}
				transparent
				opacity={0.5}
				map={getTexture("ui/selection")}
				blending={AdditiveBlending}
				// depthTest={false}
				depthWrite={false}
			/>
		</mesh>
	);
};
