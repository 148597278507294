import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { TSigil } from "./sigils.types";

export interface ISigilStore {
	sigils: TSigil[];
	playerSigil: TSigil | null;
	addSigil: (sigil: TSigil) => void;
	updateSigil: (sigil: TSigil) => void;
	removeSigil: (id: string) => void;
}

const useSigilStore = create<ISigilStore>()(
	immer((set, get) => ({
		sigils: [],
		playerSigil: null,
		addSigil: (sigil: TSigil) => {
			set((state) => {
				state.sigils.push(sigil);
			});
		},
		updateSigil: (sigil: TSigil) => {
			set((state) => {
				const idx = state.sigils.findIndex((s) => s.id === sigil.id);
				if (idx >= 0) {
					state.sigils = [
						...get().sigils.filter((s) => s.id !== sigil.id),
						sigil,
					];
				}
			});
		},
		removeSigil: (id: string) => {
			set((state) => {
				state.sigils = get().sigils.filter((s) => s.id !== id);
			});
		},
	})),
);

const SigilStore = () => ({
	...useSigilStore.getState(),
	set: useSigilStore.setState,
});
export { SigilStore, useSigilStore };
