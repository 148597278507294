import { getSystemCalls } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";

export class CEater extends SimComponent {
	getHungerPercentage() {
		return 100;
	}

	async feed(doer: SimEntity, feed: SimEntity) {
		console.log("FEED", doer, this.inst);
		const { eat } = getSystemCalls();
		await eat(doer.ref as Hex, feed.ref as Hex);
		this.inst.pushEvent("isEating", {});
	}
}
