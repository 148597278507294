import { lowPrecisionCompare } from "@game/components/constructableInput.fn";
import { Block } from "@game/world/block";
import { getBlock } from "@game/world/block.store";
import type { IBlock } from "@game/world/block.types";
import { WorldCoordinate } from "@game/world/world.types";

const getBuildAngleForNeighbour = (
	block: IBlock,
	neighbour: IBlock,
	faceIndex: number,
) => {
	// we can get which angle we're rotating in from the side[faceindex]
	// we need to know which side of the neighbour matches this block's side
	let nFaceIndex = -1;
	const p1 = block._sides[faceIndex][0].clone().add(block._quadCenter);
	const p2 = block._sides[faceIndex][1].clone().add(block._quadCenter);
	for (let i = 0; i < 4; i++) {
		let acc = 0;
		const n1 = neighbour._sides[i][0].clone().add(neighbour._quadCenter);
		const n2 = neighbour._sides[i][1].clone().add(neighbour._quadCenter);
		if (lowPrecisionCompare(p1, n1)) acc++;
		if (lowPrecisionCompare(p1, n2)) acc++;
		if (lowPrecisionCompare(p2, n1)) acc++;
		if (lowPrecisionCompare(p2, n2)) acc++;
		if (acc === 2) {
			nFaceIndex = i;
			break;
		}
	}
	if (nFaceIndex === -1) {
		const sameGrid =
			block.grid.coordinates[0] === neighbour.grid.coordinates[0] &&
			block.grid.coordinates[1] === neighbour.grid.coordinates[1];
		console.error(
			"missing neighbour",
			sameGrid ? "same grid" : "different grid",
			block,
			neighbour,
		);
		nFaceIndex = faceIndex % 4;
	}
	return nFaceIndex % 4;
};

export const getBuildPosition = (
	block: IBlock,
	faceIndex: number,
): { coordinate: WorldCoordinate; angle: number } => {
	// Defaults for when angle is up or down
	// faceIndex < 4 is side, 4 is top, 5 is bottom
	let angle = faceIndex;
	let coordinate = new WorldCoordinate(
		[block.coordinate.grid[0], block.coordinate.grid[1]],
		block.coordinate.quad,
		block.coordinate.y + (faceIndex === 4 ? 1 : -1),
	);
	// Get position and angle for sides
	if (faceIndex < 4) {
		const nts = block.sideToNeighbour[faceIndex];
		const n = block?.neighbours[nts];
		if (n === undefined) {
			throw new Error("no neighbour");
		}
		const neighbour = getBlock(n)?.[1] || new Block(n);
		angle = getBuildAngleForNeighbour(block, neighbour, faceIndex);
		coordinate = new WorldCoordinate(
			[n.grid[0], n.grid[1]],
			n.quad,
			block.coordinate.y,
		);
	}
	return {
		coordinate,
		angle: angle,
	};
};
