import { useEffect } from "react";
import type { SimEntity } from "./SimEntity";

/**
 * Triggers a callback function for *onUpdate* events on a SimEntity.
 *
 * **STRONGLY** recommended to memoize the callback function with *useCallback* when doing state updates.
 *
 * @param fn - The callback function to be invoked when the entity updates.
 * @param entity - The SimEntity instance to listen for update events.
 */
const useEntityUpdate = (callback: () => void, entity: SimEntity): void => {
	useEffect(() => {
		callback();
		entity.listenForEvent("onUpdate", callback);
		return () => {
			entity.removeEventListener("onUpdate", callback);
		};
	}, [entity, callback]);
};

export default useEntityUpdate;
