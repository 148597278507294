import { getHex } from "@/lib/format.utils";
import Audio from "@game/audio/audioPlayer";
import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { getEntityByRef } from "../sim.store";
import { SimComponent } from "./SimComponent";

export class CGrower extends SimComponent {
	crops: Record<string, SimEntity> = {};
	maxCrops = 3;

	private cyclesLeft: number;
	private maxCyclesLeft: number;

	constructor(inst: SimEntity) {
		super(inst);
		this.crops = {};
		this.cyclesLeft = 1;
		this.maxCyclesLeft = 6;
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Grower, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		const oldCrops = this.crops;
		this.crops = {};
		this.maxCrops = value.maxCrops;
		let changed = false;

		for (let i = 0; i < value.crops.length; i++) {
			if (value.crops[i] === getHex(0)) continue;
			const item = getEntityByRef(value.crops[i]);
			if (!item) {
				throw new Error(`item not found ${value.crops[i]}`);
			}
			this.crops[i] = item;
			if (oldCrops[i] !== item) {
				changed = true;
			}
		}
		if (changed) this.inst.pushEvent("onCropsChanged", {});
	}

	isEmpty(): boolean {
		return Object.values(this.crops).length === 0;
	}

	isFullFertile(): boolean {
		return this.cyclesLeft >= this.maxCyclesLeft;
	}

	getFertilePercent(): number {
		return this.cyclesLeft / this.maxCyclesLeft;
	}

	isFertile(): boolean {
		return this.cyclesLeft > 0;
	}

	async plantItem(item: SimEntity): Promise<boolean> {
		const { action_plantItem } = getSystemCalls();
		Audio.playSound("PUT");
		await action_plantItem(this.inst.ref, item.ref);
		return true;
	}

	async fertilize(item: SimEntity): Promise<boolean> {
		const { destroyEntity } = getSystemCalls();
		Audio.playSound("PUT");
		await destroyEntity(item.ref);
		return true;
	}

	getDebugString(): string {
		return `Cycles left: ${this.cyclesLeft} / ${this.maxCyclesLeft}`;
	}
}
