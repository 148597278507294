import prand from "pure-rand";

/**
 * Seeded RNG utility functions
 */
const rand = prand.unsafeUniformIntDistribution;

export type TSeededRand = (x: number, y: number) => number;

// seed a generator and export an function to re-use the rng
export const seededRand = (seed: number): TSeededRand => {
	const rng = prand.xoroshiro128plus(seed);
	return (x: number, y: number) => rand(x, y, rng);
};

export const randomFromArray = <T>(array: T[], rng: TSeededRand): T => {
	return array[Math.floor(rng(0, array.length - 1))];
};
