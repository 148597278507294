import { APP_WALLETCONNECT } from "@/data/app.data";
import { ENV } from "@/lib/env";
import { mudFoundry } from "@latticexyz/common/chains";
// import { redstone as holesky } from "@mud/lib/chains/redstone-holesky";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import type { PropsWithChildren } from "react";
import { WagmiProvider } from "wagmi";
import { arbitrumSepolia } from "wagmi/chains";

const queryClient = new QueryClient();

// HACK: clearing wagmi cahce otherwise chain is not configred
localStorage.removeItem("wagmi.store");

const chains = [ENV.DEV ? mudFoundry : arbitrumSepolia] as const;
const { projectId, metadata } = APP_WALLETCONNECT;
const config = defaultWagmiConfig({
	chains,
	projectId,
	metadata,
	enableEmail: false, // @dev enabling this causes a shitstorm of analytics errors
	enableCoinbase: false, // @dev coinbase imports 'util' which is not available in the browser
});

createWeb3Modal({
	wagmiConfig: config,
	projectId,
	enableAnalytics: false,
});

export function Web3ModalProvider({ children }: PropsWithChildren) {
	return (
		<WagmiProvider config={config}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</WagmiProvider>
	);
}
