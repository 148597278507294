import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import type { TSimPrefab } from "../types/sim.types";
import { CGrower } from "./CGrower";
import { SimComponent } from "./SimComponent";

export class CPlantable extends SimComponent {
	growTime = 120;
	product: TSimPrefab | null = null;
	minlevel = 1;

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (target.component(CGrower)?.isEmpty()) {
			actions.push("PLANT");
		}
	}

	setProductPrefab(prefab: TSimPrefab) {
		this.product = prefab;
		return this;
	}
}
