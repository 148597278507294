import { PointOctree } from "sparse-octree";
import { Vector3 } from "three";
import { IBlock } from "../block.types";
import type { WorldCoordinate } from "../world.types";

const octreeScale = 1000;
const min = new Vector3(-octreeScale, -octreeScale, -octreeScale);
const max = new Vector3(octreeScale, octreeScale, octreeScale);

export const ISLANDS = {
	octree: new PointOctree<IBlock>(min, max),
	all: [] as Island[],
	blockMapToIsland: new Map<WorldCoordinate, Island>(),
	reset: () => {
		ISLANDS.octree = new PointOctree<IBlock>(min, max);
		ISLANDS.all = [];
		ISLANDS.blockMapToIsland = new Map<WorldCoordinate, Island>();
	},
	registerBlock: (block: IBlock) => {
		ISLANDS.octree.set(new Vector3(...block.position), block);

		const neighbours = ISLANDS.octree.findPoints(
			new Vector3(...block.position),
			3,
			true,
		);
		if (neighbours.length === 0) {
			return ISLANDS.createIsland(block);
		}

		const nIslands = [] as Island[];
		for (const nb of neighbours) {
			if (nb.data === null) continue;
			const nBlock = nb.data;
			if (!nBlock) continue;
			const island = nBlock.getIsland();
			if (island) {
				nIslands.push(island);
			}
		}
		if (nIslands.length === 0) {
			return ISLANDS.createIsland(block);
		}
		if (nIslands.length === 1) {
			nIslands[0].addBlock(block);
			return nIslands[0];
		}
		// merge all islands
		const mainIsland = nIslands[0];
		for (const island of nIslands) {
			if (island === mainIsland) continue;
			mainIsland.mergeIslands(island);
		}
		mainIsland.addBlock(block);
		return mainIsland;
	},
	createIsland: (block: IBlock) => {
		const island = new Island();
		ISLANDS.all.push(island);
		island.addBlock(block);
		return island;
	},
	getClosest: (position: Vector3) => {
		let closest: Island | undefined;
		let closestDistance = Infinity;
		for (const island of ISLANDS.all) {
			const distance = island.center.distanceTo(position);
			if (distance < closestDistance) {
				closest = island;
				closestDistance = distance;
			}
		}
		return closest;
	},
};

export class Island {
	blocks: IBlock[] = [];
	center: Vector3 = new Vector3(0, 0, 0);
	boundingDistance = 0;
	top: Vector3 = new Vector3(0, 0, 0);

	addBlock(block: IBlock, calculateCenter = true) {
		if (this.blocks.includes(block)) return;
		this.blocks.push(block);
		ISLANDS.blockMapToIsland.set(block.coordinate, this);
		if (calculateCenter) {
			this.calculateCenter();
			this.calculateBoundingDistance();
		}
	}

	mergeIslands(island: Island) {
		if (this === island) {
			return;
		}
		console.log("merge");
		for (const block of island.blocks) {
			this.addBlock(block, false);
		}
		ISLANDS.all = ISLANDS.all.filter((i) => i !== island);
		this.calculateCenter();
		this.calculateBoundingDistance();
	}

	containsBlock(block: IBlock) {
		return this.blocks.includes(block);
	}

	calculateCenter() {
		const sum = new Vector3(0, 0, 0);
		for (const block of this.blocks) {
			sum.add(block.position);
		}
		this.center = sum.divideScalar(this.blocks.length);
		return this.center;
	}

	calculateTop() {
		const center = this.center;
		const top = this.blocks[0].position.clone();
		for (const block of this.blocks) {
			if (block.position.y > top.y) {
				top.copy(block.position);
			}
		}
		top.x = center.x;
		top.z = center.z;
		this.top = top;
		return top;
	}

	calculateBoundingDistance() {
		let maxDistanceSquared = 0;
		for (const block of this.blocks) {
			const distanceSquared = this.center.distanceToSquared(block.position);
			if (distanceSquared > maxDistanceSquared) {
				maxDistanceSquared = distanceSquared;
			}
		}
		this.boundingDistance = Math.sqrt(maxDistanceSquared);
		return this.boundingDistance;
	}

	getID() {
		return ISLANDS.all.indexOf(this);
	}
}
