import { useEffect, useMemo, useState } from "react";
import { cn, customFormatEther, roundToDecimal } from "@/lib/utils";
import { getEntityMetadataByID } from "@/data/entity/entity.data.fn";
import { alternateFlexLines } from "./itemShop.fn";
import { ShopModal, ShopModalBlock, ShopModalButton } from "./itemShopModal";
import {
	ItemShopStore,
	type IItemShopProduct,
	useItemShopStore,
} from "./itemShop.store";
import { useTranslation } from "react-i18next";
import usePayments from "@/wallet/usePayments";
import Audio from "@game/audio/audioPlayer";
import { formatEther, type Hex } from "viem";
import { getNetwork, getSystemCalls } from "@mud/index";
import { setupPlayer } from "@game/game.functions";
import { setUIState, UIStates } from "@game/ui/ui.states";
import { ENV } from "@/lib/env";
import { Debug } from "@/lib/debug/debug";

function ItemShopCard({
	item,
	selectionLeft,
}: { item: IItemShopProduct; selectionLeft: number }) {
	const handleClick = () => {
		if (item.selected) {
			//deselect sound
			Audio.playSound("PICKUP_LOCO", { volume: 0.055 });
		} else {
			Audio.playSound("PICKUP");
		}
		Debug("ItemShop").capture("item_click", { item, selected: !item.selected });
		ItemShopStore().updateProduct({
			...item,
			selected: !item.selected,
		});
	};

	const handleMouseOver = () => {
		Debug("ItemShop").capture("item_hover", { item, selected: !item.selected });
		ItemShopStore().set({
			detailedProduct: item,
		});
	};

	const { icon } = useMemo(() => {
		const metadata = getEntityMetadataByID(item.entityTypeId);
		return {
			icon: `/icons/${metadata?.icon ?? "unknown.webp"}`,
		};
	}, [item.entityTypeId]);

	return (
		<button
			className={cn(
				"flex flex-col items-center justify-center w-22 h-22 group pointer-events-auto transition-all cursor-pointer text-sm relative",
				selectionLeft <= 0 && !item.selected && "opacity-50",
			)}
			onClick={handleClick}
			onMouseOver={handleMouseOver}
			disabled={selectionLeft <= 0 && !item.selected}
		>
			<div
				className={cn(
					"absolute top-[15%] left-[15%] w-[70%] h-[70%] bg-[#BAC1B9] z-0 transition-all opacity-20  duration-200",
					item.selected &&
						"bg-[#256718] top-[7.5%] left-[7.5%] w-[85%] h-[85%] opacity-20  duration-75",
				)}
			/>
			<div
				className={cn(
					"w-20 h-20 overflow-auto z-50 opacity-70",
					item.selected && "opacity-100",
				)}
				style={{ background: `url(${icon}) no-repeat center/cover` }}
			/>
		</button>
	);
}

const startGame = async () => {
	setUIState(UIStates.playMode);
	setupPlayer();
	Audio.stopMusic();
	Audio.playSound("MUSIC_BOOTUP");
};

export function ItemShopPurchasePanel() {
	const { t } = useTranslation();
	const items = useItemShopStore((state) => state.products);
	const playpass = useItemShopStore((state) => state.playpass);
	const { totalPrice, selectedItems } = useMemo(() => {
		let totalPrice = 0;
		const selectedItems: IItemShopProduct[] = [];
		[...items, playpass].forEach((item) => {
			if (item.selected) {
				if (item !== playpass) selectedItems.push(item);
				const metadata = getEntityMetadataByID(item.entityTypeId);
				if (!metadata) return;
				totalPrice += metadata.price || 0;
			}
		});
		totalPrice = ENV.BYPASS_PAYMENTS
			? 0
			: totalPrice * ItemShopStore().priceModifier;
		return { totalPrice, selectedItems };
	}, [items, playpass]);
	const { pay, paymentStatus, error, balance, isConnected, connect } =
		usePayments();
	const [errorMessage, setErrorMessage] = useState<string | null>(null!);

	const handlePurchase = async () => {
		setErrorMessage(null);
		const selectedItems = items
			.filter((item) => item.selected)
			.map((item) => item.entityTypeId);
		if (ENV.BYPASS_PAYMENTS) {
			await getSystemCalls().purchaseStartingItems(
				getNetwork().playerEntity as Hex,
				selectedItems,
			);
			setTimeout(() => {
				Debug("ItemShop").capture("payment_bypass", {
					selectedItems,
					totalPrice,
				});
				startGame();
			}, 1000);
			return;
		}
		pay(totalPrice, selectedItems);
	};

	useEffect(() => {
		Audio.playSound("PUT");
	}, []);

	useEffect(() => {
		if (paymentStatus === "success") {
			console.log("success");
			setTimeout(() => {
				Debug("ItemShop").capture("payment_success", {
					selectedItems,
					totalPrice,
				});
				startGame();
			}, 1000);
		}
		if (paymentStatus === "error") {
			if (error) {
				setErrorMessage(
					t(`cryptoErrors.${error.shortMessage}`) ||
						t(`cryptoErrors.${error.name}`) ||
						t("cryptoErrors.unknown"),
				);
			} else {
				setErrorMessage(t("cryptoErrors.unknown"));
			}
		}
	}, [paymentStatus, error, t, selectedItems, totalPrice]);

	const sufficientBalance = ENV.BYPASS_PAYMENTS
		? true
		: Number(formatEther(balance.data?.value || 0n)) >= totalPrice;
	const selectionLeft = 3 - selectedItems.length;

	return (
		<ShopModal className="font-light rounded-lg px-0">
			{/* Top block with title and descriptions */}
			<ShopModalBlock className="pb-2 bg-transparent items-end flex px-6">
				<ItemShopCard item={playpass!} selectionLeft={selectionLeft} />
				<div className="text-3xl uppercase tracking-tighter flex-col flex z-10 w-full leading-7">
					{alternateFlexLines(t("shop.starting_gear"))}
					<div className="text-xs tracking-normal text-right">
						{t("shop.max_items_count", {
							count: selectionLeft,
						})}
					</div>
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="flex-row flex-wrap px-4 gap-4 pl-6 pt-0">
				{items.map((item) => (
					<ItemShopCard
						key={item.name}
						item={item}
						selectionLeft={selectionLeft}
					/>
				))}
			</ShopModalBlock>
			<ShopModalBlock className="h-full relative overflow-visible">
				<div className="flex-grow" />
				<div className="self-end flex flex-row w-full">
					<div
						className={cn(
							"flex flex-row gap-2 flex-grow self-center text-[#256718] font-bold",
							!sufficientBalance && "text-black-500",
						)}
					>
						<div className="font-bold capitalize">{t("total")}: </div>
						<div className="font-bold">
							{roundToDecimal(totalPrice, 3)} {ItemShopStore().currency}
						</div>
					</div>
					{(isConnected || ENV.BYPASS_PAYMENTS) && (
						<ShopModalButton
							onClick={
								isConnected || ENV.BYPASS_PAYMENTS ? handlePurchase : connect
							}
							className={cn(
								!sufficientBalance &&
									"disabled:bg-[#FB913E]/70 disabled:cursor-not-allowed disabled:active:scale-100",
							)}
							disabled={!sufficientBalance || paymentStatus === "pending"}
						>
							{t(paymentStatus === "pending" ? "pending" : "purchase")}
						</ShopModalButton>
					)}
					{!isConnected && !ENV.BYPASS_PAYMENTS && (
						<ShopModalButton onClick={connect}>{t("connect")}</ShopModalButton>
					)}
				</div>
				{isConnected && (errorMessage || !sufficientBalance) && (
					<div className="absolute top-[100%] mt-4 left-0 self-end flex flex-row w-full gap-2 bg-[#FB913E]/70 border-[#fff]/20 border-2 mb-6 rounded-lg text-xs">
						<div className="text-[#fff]/100 text-center p-2 px-2 font-medium ">
							{!sufficientBalance &&
								`⚠️ ${t("shop.insufficientBalance", {
									count: Number(
										customFormatEther(balance.data?.value || 0n, 2),
									),
									currency: ItemShopStore().currency,
								})} `}
							{errorMessage}
						</div>
					</div>
				)}
			</ShopModalBlock>
		</ShopModal>
	);
}
