import { FileLog } from "@/lib/debug/fileLogger";
import { ENV } from "@/lib/env";
import type { WorldCoordinate } from "@game/world/world.types";
import type { NetworkSetupResult } from "@jungle/common/src/mud.setup";
import type { WaitForTransactionResult } from "@latticexyz/store-sync";
import type { Hex } from "viem";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export function createSystemCalls({
	waitForTransaction,
	playerEntity,
	worldContract,
	publicClient,
}: NetworkSetupResult) {
	const delayedWaitForTransaction = async (
		tx: Hex,
		tries = 50,
	): Promise<WaitForTransactionResult | null> => {
		let t = tries;
		console.time("TX duration");
		const _doWait = async (tx: Hex) => {
			try {
				const result = await waitForTransaction(tx);
				return result;
			} catch (e) {
				if (t > 0) {
					await new Promise((r) => setTimeout(r, 500));
					t--;
					return _doWait(tx);
				}
				throw e;
			}
		};
		const waitResult = await _doWait(tx);
		console.timeEnd("TX duration");
		if (ENV.isDev) {
			publicClient.getTransaction({ hash: tx }).then((x) => {
				const data = {
					hash: x.hash.toString(),
					gas: x.gas.toString(),
					from: x.from,
					r: x.r.toString(),
					s: x.s.toString(),
					v: x.v.toString(),
					data: x.input.toString(),
				};
				FileLog.info(tx, data);
			});
		}
		return waitResult;
	};

	/**
	 * Creates a player.
	 * @returns A promise that resolves to the result of the transaction.
	 */
	const createPlayer = async () => {
		const tx = await worldContract.write.jungle__GameSystem_createPlayer();
		await waitForTransaction(tx);
		return tx;
	};

	const purchaseStartingItems = async (playerKey: Hex, entityIDs: number[]) => {
		const tx = await worldContract.write.jungle__Action_purchaseItems([
			playerKey,
			entityIDs,
		]);
		await waitForTransaction(tx);
		return tx;
	};

	/**
	 * Container takes item
	 * @param entityKey The key of the entity that takes the item.
	 * @param item The item to take.
	 * @param slot The slot to take the item from.
	 * @returns A promise that resolves to the result of the transaction.
	 */
	const containerTakeItem = async (
		entityKey: string,
		item: string,
		slot: number,
	) => {
		const tx = await worldContract.write.jungle__ContainerSystem_takeItem([
			entityKey as Hex,
			item as Hex,
			slot,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const destroyEntity = async (entityKey: string) => {
		const tx = await worldContract.write.jungle__EntitySystem_destroyEntity([
			entityKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const containerRemoveItem = async (entityKey: string, item: string) => {
		const tx = await worldContract.write.jungle__ContainerSystem_removeItem([
			entityKey as Hex,
			item as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const containerSwapSlots = async (
		entityKey: string,
		slot1: number,
		slot2: number,
	) => {
		const tx = await worldContract.write.jungle__ContainerSystem_swapSlots([
			entityKey as Hex,
			slot1,
			slot2,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const removePlotItem = async (entityKey: string, item: string) => {
		const tx = await worldContract.write.jungle__PlotSystem_removeItem([
			entityKey as Hex,
			item as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const checkGrowthPercentage = async (entityKey: string) => {
		const tx = await worldContract.read.jungle__CropSystem_calculateGrowth([
			entityKey as Hex,
		]);
		return tx;
	};

	const checkGrowablePercentage = async (entityKey: string) => {
		const tx = await worldContract.read.jungle__GrowableSystem_calculateGrowth([
			entityKey as Hex,
		]);
		return tx;
	};

	const putItem = async (entityKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__PlotSystem_putItem([
			entityKey as Hex,
			itemKey as Hex,
		]);
		return tx;
	};

	const harvest = async (entityKey: string) => {
		const tx = await worldContract.write.jungle__CropSystem_harvest([
			entityKey as Hex,
			playerEntity as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const eat = async (_entityKey: string, _feedKey: string) => {
		console.error("unimplemented eat");
		// const food = getEntityByRef(feedKey);
		// if (food?.component(CStackable)) {
		//   const tx = await consumeFromStack(feedKey, 1);
		//   return tx;
		// }
		// const tx = await worldContract.write.jungle__EntitySystem_destroyEntity([
		//   feedKey as Hex,
		// ]);
		// await delayedWaitForTransaction(tx);
		// return tx;
	};

	const action_plantItem = async (entityKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_plantCrop([
			entityKey as Hex,
			itemKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_rummage = async (doerKey: string, targetKey: string) => {
		const tx = await worldContract.write.jungle__Action_rummage([
			doerKey as Hex,
			targetKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_build = async (
		playerKey: string,
		ingredientKey: string,
		coordinate: WorldCoordinate,
		entityTypeId: number,
		orientation: number,
	) => {
		const buildingData = {
			entityTypeId,
			x: coordinate.grid[0],
			y: coordinate.grid[1],
			quad: coordinate.quad,
			height: coordinate.y,
		};
		const tx = await worldContract.write.jungle__Action_build([
			playerKey as Hex,
			ingredientKey as Hex,
			buildingData,
			orientation,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_drop = async (doerKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_drop([
			doerKey as Hex,
			itemKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_addToBlueprint = async (
		playerKey: string,
		itemKey: string,
		entityTypeId: number,
		coordinate: WorldCoordinate,
		orientation: number,
	) => {
		const buildingData = {
			entityTypeId,
			x: coordinate.grid[0],
			y: coordinate.grid[1],
			quad: coordinate.quad,
			height: coordinate.y,
		};
		const tx = await worldContract.write.jungle__Action_addToBlueprint([
			playerKey as Hex,
			itemKey as Hex,
			buildingData,
			orientation,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_paint = async (
		playerKey: string,
		entityKey: string,
		ingredientKey: string,
		color: number,
		altColor: number,
	) => {
		const tx = await worldContract.write.jungle__Action_paint([
			playerKey as Hex,
			entityKey as Hex,
			ingredientKey as Hex,
			color,
			altColor,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_transmute = async (
		playerKey: string,
		entityKey: string,
		entityTypeId: number,
	) => {
		const tx = await worldContract.write.jungle__Action_transmute([
			playerKey as Hex,
			entityKey as Hex,
			entityTypeId,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_buildWall = async (
		playerKey: string,
		targetKey: string,
		entityKey: string,
		index: number,
	) => {
		const tx = await worldContract.write.jungle__Action_buildWall([
			playerKey as Hex,
			targetKey as Hex,
			entityKey as Hex,
			BigInt(index),
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const test_createTestInventoryItem = async (
		playerKey: string,
		entityTypeId: number,
	): Promise<string> => {
		const tx = await worldContract.write.jungle__Action_createTestInventoryItem(
			[playerKey as Hex, entityTypeId],
		);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	return {
		action_plantItem,
		action_rummage,
		action_build,
		action_drop,
		action_addToBlueprint,
		action_paint,
		action_transmute,
		action_buildWall,
		test_createTestInventoryItem,
		checkGrowthPercentage,
		checkGrowablePercentage,
		containerRemoveItem,
		containerTakeItem,
		containerSwapSlots,
		createPlayer,
		purchaseStartingItems,
		destroyEntity,
		eat,
		harvest,
		putItem,
		removePlotItem,
	};
}
