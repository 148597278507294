import {
	type HTMLAttributes,
	useEffect,
	type PropsWithChildren,
	useCallback,
	type MouseEvent,
} from "react";
import { ItemShopDetailPanel } from "./itemShopDetailPanel";
import { ItemShopSignupForm } from "./itemShopSignupForm";
import { ItemShopStore, useItemShopStore } from "./itemShop.store";
import { ModalUILayout } from "../ui.layout";
import { useUIStore } from "@game/ui/ui.store";
import { AnimatePresence, motion } from "framer-motion";
import hotkeys from "hotkeys-js";
import { setUIState, UIStates } from "@game/ui/ui.states";
import { cn } from "@/lib/utils";
import { ItemShopPurchasePanel } from "./itemShopPurchasePanel";
import Audio from "@game/audio/audioPlayer";
import ConnectButton from "@/wallet/connectButton";
import { ENV } from "@/lib/env";

export function ItemShopOverlay({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-full backdrop-blur-[1px] pointer-events-none select-all bg-black/20 ",
				props?.className,
			)}
			{...props}
		>
			{children}
		</div>
	);
}

export function ItemShopPages({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"flex flex-row items-center w-screen h-full bg-black/5 pointer-events-all select-all gap-8 overflow-x-auto justify-center",
				props?.className,
			)}
			{...props}
		>
			{children}
		</div>
	);
}

export function ItemShop() {
	const { showItemShop, showWalletButton } = useUIStore(
		(state) => state.uiState,
	);
	const detailedProduct = useItemShopStore((state) => state.detailedProduct);
	const state = useItemShopStore((state) => state.state);

	const handleExit = useCallback(
		async (_event: KeyboardEvent | MouseEvent<HTMLDivElement>) => {
			Audio.playSound("PUT");
			setUIState(UIStates.mainMenu);
			ItemShopStore().set({ state: "SIGNUP" });
			Audio.stopMusic();
		},
		[],
	);

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				handleExit(event);
			}
		};

		hotkeys("escape", handleEscape);
		return () => {
			hotkeys.unbind("escape", handleEscape);
		};
	}, [handleExit]);

	return (
		<AnimatePresence>
			{showItemShop && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.125 }}
				>
					<div
						className="fixed pointer-events-all z-0 fullscreen"
						data-name="item-shop-click-catcher"
						onClick={(e) => {
							handleExit(e);
							e.stopPropagation();
						}}
					/>
					<ModalUILayout
						data-name="item-shop"
						className="pointer-events-none fullscreen -z-1"
					>
						<ItemShopOverlay>
							<ItemShopPages>
								{(state === "SIGNUP" || state === "CONFIRM_PASS") && (
									<ItemShopSignupForm />
								)}
								{(state === "CONFIRM_PASS" || state === "BUY_GEAR") && (
									<ItemShopDetailPanel product={detailedProduct!} />
								)}
								{state === "BUY_GEAR" && <ItemShopPurchasePanel />}
							</ItemShopPages>
						</ItemShopOverlay>
					</ModalUILayout>
				</motion.div>
			)}
			{!ENV.BYPASS_PAYMENTS &&
				(state === "CONFIRM_PASS" || state === "BUY_GEAR") && (
					<ConnectButton key="connect-button" />
				)}
		</AnimatePresence>
	);
}
