export type THexCoordinate = [number, number];
export type TWorldCoordinate =
	`${THexCoordinate[0]},${THexCoordinate[1]}|${number}|${number}`;

export class WorldCoordinate {
	grid: THexCoordinate; // Hex Grid index
	quad: number; // Hex Quad index
	y: number; // Block Y Pos
	readonly _string: TWorldCoordinate;

	constructor(grid: THexCoordinate, quad: number, y: number) {
		this.grid = grid;
		this.quad = quad;
		this.y = y;
		this._string = `${this.grid[0]},${this.grid[1]}|${this.quad}|${this.y}`;
	}

	toString(): TWorldCoordinate {
		return this._string;
	}

	equals(other: WorldCoordinate): boolean {
		return this._string === other._string;
	}

	static fromString(str: TWorldCoordinate): WorldCoordinate {
		const [grid, quad, y] = str.split("|");
		const [x, z] = grid.split(",").map((v) => parseInt(v));
		return new WorldCoordinate([x, z], parseInt(quad), parseInt(y));
	}
}
