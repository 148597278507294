import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CGrower } from "@game/sim/components/CGrower";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemFertilizerPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("fertilizer");
		e.addComponent(CUsable).setCanUseFn(this.canUseFn).setUseFn(this.useFn);
	}

	// Use check for using shovel on a rock to mine
	canUseFn = (doer: SimEntity, target: SimEntity) => {
		// HACK very hacky
		if (target._entityTypeID === 501 && target.component(CGrower)) {
			return true;
		}
		return false;
	};

	// Use function for using shovel on a rock to mine
	useFn = async (doer: SimEntity, target: SimEntity) => {
		if (target._entityTypeID === 501 && target.component(CGrower)) {
			await target.component(CGrower).fertilize(this.inst);
		}
	};
}

const entityData: TEntityDefinition = {
	name: "Fertilizer",
	simEntityPrefab: ItemFertilizerPrefab,
	id: 40002,
	metadata: {
		icon: "fertilizer.webp",
		price: 10,
	},
};

registerEntity(entityData);
