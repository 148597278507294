import { shuffleArray } from "@/lib/random/random.functions";
import { seededRand } from "@/lib/random/seeded.functions";
import { cn } from "@/lib/utils";
import Audio from "@game/audio/audioPlayer";
import type { SimEntity } from "@game/sim/SimEntity";
import { WorldStore } from "@game/world/world.store";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import {
	onInventorySlotClick,
	onInventorySlotPointerMove,
	onInventorySlotPointerOut,
} from "./inventory.functions";
import { useInventoryStore } from "./inventory.store";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CStackable } from "@game/sim/components/CStackable";

const iconAnims = {
	bounce: { scale: [0.8, 1.4, 0.92, 1] },
};

const splashRotations = shuffleArray([0, 90, 180, 270, -90, -180, -270, 360]);
const sizes = shuffleArray([0.95, 1.05, 1.03, 0.97, 1.05]);
const rotations = shuffleArray([1.75, -1.75, 3, 0, -2]);

export const ItemCard = ({
	item,
	slotNum,
}: {
	item: SimEntity | null;
	slotNum: number;
}) => {
	const [shouldUpdate, setShouldUpdate] = useState(true);
	const { activeItem } = useInventoryStore((state) => {
		return {
			activeItem: state.getActiveItem(),
		};
	});
	const controls = useAnimation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: we pick new random sound after playing and don't want to restart the effect TODO
	useEffect(() => {
		if (!item) return;
		const triggerUpdate = () => {
			setShouldUpdate(true);
		};

		const stackSizeChanged = async () => {
			controls.start("bounce", { duration: 0.8 });
			Audio.playSound("PUT");
			triggerUpdate();
		};
		stackSizeChanged();

		item?.listenForEvent("onStackSizeChanged", stackSizeChanged);
		WorldStore().getPlayer()?.listenForEvent("onSlotsChanged", triggerUpdate);
		return () => {
			item?.removeEventListener("onStackSizeChanged", stackSizeChanged);
			WorldStore()
				.getPlayer()
				?.removeEventListener("onSlotsChanged", triggerUpdate);
		};
	}, [item, shouldUpdate]);

	const { image, amount, selected, sigil, rngNum } = useMemo(() => {
		if (!item)
			return {
				image: "unknown.webp",
				amount: 0,
				selected: false,
				rngNum: slotNum,
			};
		const image = item.component(CInventoryItem).img || "unknown.webp";
		const sigil = `${item.component(CInventoryItem).sigil}.webp` || null;
		const amount = item?.component(CStackable)?.stackSize || 1;
		shouldUpdate;
		setShouldUpdate(false);
		const rngNum = seededRand(item.getSeed())(0, 100);
		return {
			image,
			amount,
			selected: item === activeItem,
			sigil,
			rngNum,
		};
	}, [item, activeItem, shouldUpdate, slotNum]);

	return (
		<div
			className={cn(
				"relative w-[5rem] max-h-[5rem] h-[5rem] hover:z-50 hover:transition-all select-auto group",
			)}
			onPointerEnter={() => onInventorySlotPointerMove(item)}
			onPointerMove={() => onInventorySlotPointerMove(item)}
			onPointerOut={() => onInventorySlotPointerOut(item)}
			onMouseLeave={() => onInventorySlotPointerOut(item)}
			onContextMenu={(event) => {
				event.preventDefault();
			}}
			style={{
				transform: `rotate(${-rotations[rngNum % rotations.length]}deg)`,
			}}
		>
			<div
				className={cn(
					"absolute transition-all bar-splash ",
					"grayscale-[20%] w-[80%] h-[80%] left-[10%] top-[10%]",
					item && "grayscale-[0%] w-[120%] h-[120%] left-[-10%] top-[-10%]",
				)}
				style={{
					transform: `rotate(${
						splashRotations[rngNum % splashRotations.length]
					}deg) scale(${sizes[rngNum % sizes.length]})`,
				}}
			/>
			<div
				className={cn(
					"absolute transition-all w-full h-full",
					"grayscale-[0%] bar-button-bg",
					!item && "grayscale-[20%] brightness-[0.9] hover:scale-105 scale-90",
				)}
			/>
			{item && sigil && (
				<>
					<div
						className={cn(
							"absolute w-full h-full",
							"z-[80] flex items-center bar-sigil-white",
						)}
					>
						<img
							src={`/icons/${sigil}`}
							className={cn(
								"w-[90%] h-[90%] m-auto opacity-0 bar-sigil-transition",
								item === activeItem && "opacity-100",
							)}
						/>
					</div>
					<div
						className={cn(
							"absolute w-full h-full",
							"z-[90] flex items-center bar-sigil-black",
						)}
					>
						<img
							src={`/icons/${sigil}`}
							className={cn(
								"w-[90%] h-[90%] mix-blend-multiply opacity-0 m-auto bar-sigil-transition",
								item === activeItem && "opacity-[0.055]",
							)}
						/>
					</div>
				</>
			)}
			<div
				className="relative w-[90%] h-[90%] left-[5%] top-[5%] cursor-pointer text-sm text-black group-hover group-hover:scale-[120%] group-hover:transition-all pointer-events-auto z-[100]"
				onClick={(e) => onInventorySlotClick(item, slotNum, e)}
				onContextMenu={(e) => onInventorySlotClick(item, slotNum, e)}
			>
				{!selected && item && (
					<motion.img
						layout
						layoutId={item.ref}
						key={item.ref}
						src={`/icons/${image}`}
						className={cn("relative pointer-events-none", selected && "hidden")}
						draggable={false}
						animate={controls}
						style={{
							scale: 1,
						}}
						variants={iconAnims}
						transition={{
							ease: "easeInOut",
							duration: 0.08,
						}}
						onContextMenu={(event) => {
							event.preventDefault();
						}}
					/>
				)}
			</div>
			{!selected && amount! > 1 && (
				<motion.div
					className="absolute top-0 right-0 text-lg text-white bg-opacity-50 pr-1 pt-0.5 text-outline pointer-events-none select-none font-berkeley shadow-lg z-[1000]"
					animate={controls}
					style={{
						scale: 1,
					}}
					variants={iconAnims}
					transition={{ duration: 0.4 }}
				>
					{amount}
				</motion.div>
			)}
		</div>
	);
};
