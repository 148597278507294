import { ENV } from "@/lib/env";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { APP_DATA } from "./app.data";

interface IUserStore {
	firstVisit: boolean;
	signedUp: boolean;
	showTerminal: boolean;
	playerId: number;
	spectator: boolean;
	cinematic: boolean;
	isPlayer: () => boolean;
	isSpectator: () => boolean;
	isCinematic: () => boolean;
}

const useUserStore = create<IUserStore>()(
	persist(
		immer((_set, get) => ({
			firstVisit: true,
			signedUp: false,
			showTerminal: false,
			playerId: -1,
			spectator: ENV.SPECTATOR,
			cinematic: ENV.CINEMATIC,
			isPlayer: () => get().playerId !== -1 && !ENV.SPECTATOR,
			isSpectator: () => ENV.SPECTATOR ?? get().spectator,
			isCinematic: () => ENV.CINEMATIC ?? get().cinematic,
		})),
		{
			name: `${APP_DATA.storageName}-user`,
			version: 5,
		},
	),
);

const UserStore = () => ({
	...useUserStore.getState(),
	set: useUserStore.setState,
});
export { UserStore, useUserStore };
