export class Triangle {
	points: number[];
	get a(): number {
		return this.points[0];
	}
	get b(): number {
		return this.points[1];
	}
	get c(): number {
		return this.points[2];
	}
	valid: boolean;

	constructor(a: number, b: number, c: number, valid = true) {
		this.points = [a, b, c];
		this.valid = valid;
	}
}

export class Quad {
	points: number[];
	get a(): number {
		return this.points[0];
	}
	get b(): number {
		return this.points[1];
	}
	get c(): number {
		return this.points[2];
	}
	get d(): number {
		return this.points[3];
	}

	neighbours: Quad[] = [];
	side = false;

	constructor(a: number, b: number, c: number, d: number) {
		this.points = [a, b, c, d];
	}
}

export class Neighbour {
	neighbourCount: number;
	neighbours: number[];

	constructor() {
		this.neighbourCount = 0;
		this.neighbours = new Array(6);
	}
}

export class Point {
	position: THREE.Vector2;
	side: boolean;
	index: number;

	constructor(index: number, position: THREE.Vector2, side = false) {
		this.index = index;
		this.position = position.clone();
		this.side = side;
	}
}
