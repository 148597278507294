import type { BoxGeometryProps, SphereGeometryProps } from "@react-three/fiber";
import { useMemo } from "react";
import type { Vector3, Vector3Tuple } from "three";
import type { SimEntity } from "../sim/SimEntity";

type TEntityInteractionProps = {
	entity: SimEntity | null;
	args?: BoxGeometryProps["args"] | SphereGeometryProps["args"];
	position?: Vector3 | Vector3Tuple;
	isInteractable?: boolean;
	collider?: "Box" | "Sphere";
	debug?: boolean;
};

const defaultProps: TEntityInteractionProps = {
	entity: null,
	args: [0.3, 0.75, 0.3],
	position: [0, 0, 0],
	isInteractable: true,
	collider: "Box",
	debug: false,
};

export const Interactable = (props: TEntityInteractionProps) => {
	const { entity } = props;

	const interactable = useMemo(() => {
		if (!entity) return null;
		const { args, position, isInteractable, collider, debug } = {
			...defaultProps,
			...props,
		};
		return (
			<mesh
				position={position}
				userData={isInteractable ? { entityRef: entity.ref } : undefined}
				visible={debug}
			>
				{collider === "Box" && (
					<boxGeometry args={args as BoxGeometryProps["args"]} />
				)}
				{collider === "Sphere" && (
					<sphereGeometry args={args as SphereGeometryProps["args"]} />
				)}
				<meshStandardMaterial color="green" />
			</mesh>
		);
	}, [entity, props]);
	return <>{interactable}</>;
};
