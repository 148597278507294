import { getEntityByRef } from "@game/sim/sim.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";

import { UI_EVENTS } from "@game/ui/ui.events";
import { useThree } from "@react-three/fiber";
import mitt from "mitt";
import { useCallback, useEffect, useRef } from "react";
import { type Intersection, type Object3D, Vector2 } from "three";
import "./theInput.actions";
import { useSigilSystem } from "@/sigils/sigils.provider";
import { useInventoryStore } from "@game/ui/components/inventory/inventory.store";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CConstruction } from "@game/sim/components/CConstruction";

export type TInputEvent = {
	mouseLeave: { id: string };
	mouseOver: { id: string; hit: Intersection };
	mouseClick: { id: string; hit: Intersection; event: MouseEvent };
};

export const inputEvents = mitt<TInputEvent>();

let lastHit: Intersection | null = null;
let hit: Intersection | null = null;
let ents: Object3D[] = [];
const hits: Intersection[] = [];

export const TheInput = () => {
	const pointer = useRef(new Vector2()).current;
	const mouseDownPosition = useRef(new Vector2()).current;

	const { camera, scene, raycaster, events } = useThree();
	const { activeItem } = useInventoryStore((state) => {
		return {
			activeItem: state.getActiveItem(),
		};
	});
	const { hoveredObject, isBuildMode } = useUIStore((state) => {
		return {
			hoveredObject: state.hoveredObject,
			isBuildMode: state.isBuildMode,
		};
	});
	const { startSigil, finishSigil } = useSigilSystem();

	useEffect(() => {
		// Nuke default R3F events
		events.enabled = false;
	}, [events]);

	useEffect(() => {
		// Flag for setting build mode
		const { actions } = UIStore();
		const hObj = getEntityByRef(hoveredObject);
		// const isBuildAction =
		actions.length > 0 && actions?.[0]?.action?.buildAction;
		const buildMode = activeItem
			? !!(activeItem?.component(CBuildable) && hObj?.component(CConstruction))
			: false;
		if (buildMode !== isBuildMode) UIStore().set({ isBuildMode: buildMode });
	}, [isBuildMode, hoveredObject, activeItem]);

	const doRaycast = useCallback(() => {
		if (!camera || !scene) return;
		raycaster.layers.disableAll();
		raycaster.layers.set(0);
		raycaster.setFromCamera(pointer, camera);

		// want to use layers but no luck, let's filter out objects we don't want to raycast
		hits.length = 0; // clean hits array
		raycaster.intersectObjects(ents, false, hits);
		lastHit = hit;
		hit = hits.length > 0 ? hits[0] : null;

		const lastRef = lastHit?.object.userData?.entityRef || undefined;
		const hitRef = hit?.object.userData?.entityRef || undefined;

		if (lastRef !== undefined && lastRef !== hitRef) {
			getEntityByRef(lastRef)?.pushEvent("mouseleave", {});
		}
		if (hitRef !== undefined) {
			if (hit?.distance! > 20) return;
			if (hit) {
				getEntityByRef(hitRef)?.pushEvent("mouseover", hit);
			}
		}
		if (UIStore().hoveredObject !== hitRef) {
			UIStore().setHoveredObject(hitRef);
			UI_EVENTS.emit("getPlayerActions");
		}
	}, [raycaster, pointer, camera, scene]);

	useEffect(() => {
		const traverse = setInterval(() => {
			ents = [];
			scene.traverse((obj) => {
				if (obj.userData?.entityRef !== undefined) {
					ents.push(obj);
				}
			});
		}, 1000);
		return () => clearInterval(traverse);
	}, [scene]);

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			if (UIStore().isHoveringUI) return;
			event.stopPropagation();
			event.preventDefault();
			doRaycast();
		};

		const handlePointerMove = (event: PointerEvent) => {
			pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
			pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
			if (!hit) return;
		};

		const handleMouseDown = (event: MouseEvent) => {
			if (UIStore().isHoveringUI) return;
			mouseDownPosition.x = (event.clientX / window.innerWidth) * 2 - 1;
			mouseDownPosition.y = -(event.clientY / window.innerHeight) * 2 + 1;
			// @dev we raycast to make sure we're always at mouse point
			doRaycast();
			setTimeout(() => {
				if (mouseDownPosition.distanceTo(pointer) > 0.05 || !hit) return;
				startSigil(hit.point, Date.now());
			}, 250);
		};

		const handleMouseUp = (_event: MouseEvent) => {
			setTimeout(() => {
				finishSigil();
			}, 250);
		};

		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("pointermove", handlePointerMove);
		window.addEventListener("mousedown", handleMouseDown);
		window.addEventListener("mouseup", handleMouseUp);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("pointermove", handlePointerMove);
			window.removeEventListener("mousedown", handleMouseDown);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [doRaycast, pointer, startSigil, finishSigil, mouseDownPosition]);

	return null;
};
