import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";
import { CInventoryItem } from "./CInventoryItem";

export class CStackable extends SimComponent {
	stackSize = 1;
	maxSize = 8 as number;

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Stackable, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		this.setStackSize(value.stackSize);
		this.maxSize = value.maxSize;
	}

	isStack() {
		return this.stackSize > 1;
	}

	isFull() {
		return this.stackSize >= this.maxSize;
	}

	setStackSize(size: number) {
		const oldSize = this.stackSize;
		this.stackSize = size;
		if (oldSize !== size) {
			this.inst.pushEvent("onStackSizeChanged", { oldSize, newSize: size });
		}
		return this;
	}

	setMaxSize(size: number) {
		this.maxSize = size;
		return this;
	}

	canCombineWith(target: SimEntity) {
		if (target.prefab !== this.inst.prefab) return false;
		if (
			target.component(CInventoryItem) &&
			target.component(CStackable) &&
			!target.component(CStackable).isFull() &&
			!this.isFull() &&
			!target.component(CInventoryItem).isHeld() &&
			!this.inst.component(CInventoryItem).isHeld()
			// target.component(CInventoryItem).canBePickedUp
		) {
			return true;
		}
		return false;
	}

	take(_num?: number) {
		console.warn("unimplemented CStackable.take()");
		return this.inst;
	}

	roomLeft() {
		return this.maxSize - this.stackSize;
	}

	add(item: SimEntity) {
		console.assert(item !== this.inst, "can't stack self");
		let ret: SimEntity | undefined;
		if (item.prefab === this.inst.prefab) {
			const amount = item.component(CStackable).stackSize;
			const newTotal = this.stackSize + amount;

			const oldSize = this.stackSize;
			const newSize = Math.min(newTotal, this.maxSize);
			// const numAdded = newSize - oldSize;
			if (this.maxSize >= newTotal) {
				item.remove();
			} else {
				item.component(CStackable).setStackSize(newTotal - this.maxSize);
				item.pushEvent("onStackSizeChanged", {
					oldSize: amount,
					newSize: item.component(CStackable).stackSize,
				});
				ret = item;
			}

			this.stackSize = newSize;
			this.inst.pushEvent("onStackSizeChanged", { oldSize, newSize });
		}
		return ret;
	}

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (
			this.canCombineWith(target) &&
			this.inst.component(CInventoryItem).isHeld() &&
			!target.component(CInventoryItem).isHeld() &&
			!this.isFull()
		) {
			actions.push("COMBINESTACK");
		}
	}
}
