import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Vector3 } from "three";
import { formatEther, type Hex } from "viem";

/**
 * Truncates a hex address (0x...) to the first 6 and last 4 characters
 * @param address The hex address to truncate
 * @param start The number of characters to start truncating from
 * @param end The number of characters to end truncating from
 * @returns The truncated address
 */
export const truncateAddress = (address: string | Hex, start = 6, end = 4) => {
	return `${address.slice(0, start)}...${address.slice(-end)}`;
};

/**
 * Merges the given class names with the tailwind classes
 * @param inputs The class names to merge
 * @returns The merged class names
 */
export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export function unsafeRandomFromArray<T>(array: T[]) {
	return array[Math.floor(Math.random() * array.length)];
}

export function downloadBlob(blob: Blob, filename: string) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.download = filename;
	a.href = url;
	a.click();
}

/**
 * Retrieves the element at the specified index in an array, or throws an error if the index is out of bounds
 * or the element is undefined or null.
 *
 * @param array The array from which to retrieve the element.
 * @param index The index of the element to retrieve.
 * @throws Will throw an error if the index is out of bounds or the element is undefined or null.
 * @returns The element at the specified index in the array.
 */
export function atIndexOrThrow<T>(array: T[], index: number): T {
	if (index < 0 || index >= array.length) {
		throw new RangeError(`Index ${index} is out of bounds.`);
	}

	const element = array[index];
	if (element === undefined || element === null) {
		throw new Error(`Element at index ${index} is undefined or null.`);
	}

	return element;
}

/**
 * Object representing directional constants.
 */
export const Directions = {
	/**
	 * Get the right direction.
	 * @returns {Vector3} The right direction (1, 0, 0).
	 */
	RIGHT: (): Vector3 => new Vector3(1, 0, 0),

	/**
	 * Get the left direction.
	 * @returns {Vector3} The left direction (-1, 0, 0).
	 */
	LEFT: (): Vector3 => new Vector3(-1, 0, 0),

	/**
	 * Get the up direction.
	 * @returns {Vector3} The up direction (0, 1, 0).
	 */
	UP: (): Vector3 => new Vector3(0, 1, 0),

	/**
	 * Get the down direction.
	 * @returns {Vector3} The down direction (0, -1, 0).
	 */
	DOWN: (): Vector3 => new Vector3(0, -1, 0),

	/**
	 * Get the forward direction.
	 * @returns {Vector3} The forward direction (0, 0, 1).
	 */
	FORWARD: (): Vector3 => new Vector3(0, 0, 1),

	/**
	 * Get the backward direction.
	 * @returns {Vector3} The backward direction (0, 0, -1).
	 */
	BACKWARD: (): Vector3 => new Vector3(0, 0, -1),
};

/**
 * Array of face directions.
 */
export const faceDirections: Vector3[] = [
	Directions.RIGHT(),
	Directions.LEFT(),
	Directions.UP(),
	Directions.DOWN(),
	Directions.FORWARD(),
	Directions.BACKWARD(),
];

export type Direction = keyof typeof Directions;

/**
 * [Exponential decay function](https://www.youtube.com/watch?v=LSNQuFEDOyQ) (instead of regular lerp) by the esteemed [Freya Holmér](https://www.patreon.com/acegikmo)
 * @param a the usual a
 * @param b target value
 * @param decay obviously not half-life, but useful approx from 1-26, from slow to fast
 * @param dt delta-time
 */
export const expDecay = (a: number, b: number, decay: number, dt: number) => {
	return b + (a - b) * Math.exp(-decay * dt);
};

export function customFormatEther(wei: bigint, maxDecimals = 18) {
	const s = formatEther(wei).toString().split(".");
	if (s.length === 1) return s[0];
	return [s[0], s[1]?.slice(0, maxDecimals)].join(".");
}

export function roundToDecimal(value: number, decimals: number) {
	const tenTo = 10 ** decimals;
	return Math.round(value * tenTo) / tenTo;
}
