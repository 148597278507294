import type { TConstraint } from "@/data/blueprint/blueprint.data";
import type { SimEntity } from "@game/sim/SimEntity";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import type { Island } from "@game/world/islands/islands.functions";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { UI_EVENTS } from "./ui.events";
import { type TUIState, UIStates } from "./ui.states";

export interface IUIStore {
	uiState: TUIState;
	dropItem: boolean; // can we drop the item (enabled after delay)
	isHoveringUI: boolean; // is the cursor hovering over the UI
	isHoveringSlot: boolean; // is the cursor hovering over an inventory slot
	hoveredItem: SimEntity | undefined; // hovered inventory item
	hoveredObject: string | undefined; // hovered world object
	isBuildMode: boolean; // are we in build mode (cursor)
	faceIndex: number; // face index of the build direction
	getBuildDirection: () => TConstraint | undefined;
	activeIsland: Island | undefined; // currently active island
	actions: BufferedAction[]; // LMB actions
	altActions: BufferedAction[]; // RMB actions
	activeAction: BufferedAction | undefined; // Running action
	loadingComplete: boolean;
	setActiveAction: (action: BufferedAction | undefined) => void;
	completeAction: () => void;
	setHoveredObject: (obj: string | undefined) => void;
	setFaceIndex: (index: number) => void;
}

const useUIStore = create<IUIStore>()(
	immer((set, get) => ({
		uiState: UIStates.loading,
		dropItem: false,
		isHoveringUI: false,
		isHoveringSlot: false,
		hoveredItem: undefined,
		hoveredObject: undefined,
		isBuildMode: false,
		faceIndex: -1,
		getBuildDirection: () => {
			const dir = get().faceIndex;
			if (dir >= 0 && dir < 4) return "SIDES";
			if (dir === 4) return "TOP";
			if (dir === 5) return "BOTTOM";
		},
		activeIsland: undefined,
		actions: [],
		altActions: [],
		activeAction: undefined,
		loadingComplete: false,
		setActiveAction: (action: BufferedAction | undefined) => {
			set((state) => {
				state.activeAction = action;
			});
			UI_EVENTS.emit("getPlayerActions");
		},
		completeAction: () => {
			set((state) => {
				state.activeAction = undefined;
				state.isHoveringSlot = false;
			});
			UI_EVENTS.emit("getPlayerActions");
		},
		setHoveredObject: (obj: string | undefined) => {
			set((state) => {
				state.hoveredObject = obj;
			});
		},
		setFaceIndex: (index: number) => {
			set((state) => {
				state.faceIndex = index;
			});
			UI_EVENTS.emit("getPlayerActions");
		},
	})),
);

const UIStore = () => ({
	...useUIStore.getState(),
	set: useUIStore.setState,
});

const removeEntity = ({ inst }: { inst: SimEntity }) => {
	UIStore().set({
		hoveredItem:
			UIStore().hoveredItem === inst ? undefined : UIStore().hoveredItem,
		hoveredObject:
			UIStore().hoveredObject === inst.ref
				? undefined
				: UIStore().hoveredObject,
	});
};

UI_EVENTS.on("removeEntity", removeEntity);

export { UIStore, useUIStore };
