import { CConstruction } from "@game/sim/components/CConstruction";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CRummage } from "@game/sim/components/CRummage";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { RockRenderer } from "./rock.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";

export class RockPrefab extends SimEntityPrefab {
	rummage: CRummage | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(RockRenderer);
		e.addTag("NOLABEL");
		e.addComponent(CConstruction).setRecipes("Scaffold", "Farm");
		this.rummage = e.addComponent(CRummage).setUseDefaultActions(false);
	}
}

const entityData: TEntityDefinition = {
	name: "Rock",
	simEntityPrefab: RockPrefab,
	id: 2,
};

registerEntity(entityData);
