import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CContainer } from "@game/sim/components/CContainer";
import { CPlayer } from "@game/sim/components/CPlayer";
import { CPlayerActionPicker } from "@game/sim/components/CPlayerActionPicker";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { WorldStore } from "@game/world/world.store";

export default class PlayerPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPlayerActionPicker);
		e.addComponent(CContainer);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
		e.addComponent(CPlayer);
		console.debug("PLAYER", e);
	}

	update() {
		if (WorldStore().getPlayer() !== this.inst) return;
		this.inst.component(CContainer).loadFromChain();
	}
}

const entityData: TEntityDefinition = {
	name: "Player",
	simEntityPrefab: PlayerPrefab,
	id: 1,
};

registerEntity(entityData);
