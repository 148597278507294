import { getMesh } from "@/data/assetLoader/asset.store";
import { generateCropPositions, randomPos } from "@/lib/placement.utils";
import { seededRand } from "@/lib/random/seeded.functions";
import { FogOutlines } from "@/lib/shaders/outline";
import DeformedMesh from "@game/components/deformModel";
import { getEntityByRef } from "@game/sim/sim.store";
import { Block } from "@game/world/block";
import { tables, useMUDStore } from "@mud";
import { useEffect, useMemo, useState } from "react";
import type { Hex } from "viem";
import type { SimEntity } from "../sim/SimEntity";
import { CRenderer } from "../sim/components/CRenderer";

export const meshes = ["modular_set/modular_set/building_module_farm_dirt002"];

export function FarmRenderer({ entity }: { entity: SimEntity }) {
	const [updateCrops, setUpdateCrops] = useState(true);
	const { block, model, pos } = useMemo(() => {
		const block = entity.component(CRenderer).block!;
		const model = getMesh(meshes[0]);
		return {
			block,
			pos: block.position.toArray(),
			model,
		};
	}, [entity]);

	const grower = useMUDStore((state) =>
		state.getValue(tables.Grower, { inst: entity.ref as Hex }),
	);

	const plot = useMUDStore((state) =>
		state.getValue(tables.Plot, { inst: entity.ref as Hex }),
	);

	const { crops, plotItems } = useMemo(() => {
		if (!grower || !plot) return {};
		const crops = grower.crops.map((c) => getEntityByRef(c)) || [];
		const plotItems = plot.items.map((c) => getEntityByRef(c)) || [];
		if (updateCrops) setUpdateCrops(false);
		return { crops, plotItems };
	}, [grower, plot, updateCrops]);

	useEffect(() => {
		const onCropsChanged = () => {
			setUpdateCrops(true);
		};
		entity?.listenForEvent("onCropsChanged", onCropsChanged);
		return () => {
			entity?.removeEventListener("onCropsChanged", onCropsChanged);
		};
	}, [entity]);

	if (!block || !model || !crops || !plotItems) return;
	const renderer = useMemo(() => {
		return (
			<group position={[...pos]}>
				<DeformedMesh
					block={block}
					model={model}
					color="white"
					material={model.material}
					userData={{ entityRef: entity.ref }}
					scale={[2, 2, 2]}
				>
					<FogOutlines
						thickness={1.4}
						color="#534646"
						opacity={0.8}
						transparent
						screenspace
					/>
				</DeformedMesh>
				{crops?.map((crop, idx) => {
					if (!crop) return null;
					const dist = 0.0925;
					const positions = generateCropPositions(crops.length, dist, -0.215);
					const pos = positions[idx];
					const rot = seededRand(idx)(0, 100);
					const Renderer = crop.component(CRenderer)?.renderer;
					if (!Renderer) {
						console.warn("No renderer found for crop", crop);
						return null;
					}
					return <Renderer key={crop.ref} entity={crop} props={{ pos, rot }} />;
				})}
				{plotItems?.map((item, idx) => {
					if (!item) return null;
					const pos = randomPos(block, idx).setY(-0.25);
					const rot = seededRand(Block.getSeed(block) * (idx + 3))(0, 100);
					const Renderer = item.component(CRenderer).renderer!;
					if (!Renderer) {
						console.warn("No renderer found for item", item);
						return null;
					}
					return <Renderer key={item.ref} entity={item} props={{ pos, rot }} />;
				})}
			</group>
		);
	}, [block, crops, entity, model, plotItems, pos]);
	return <>{renderer}</>;
}
