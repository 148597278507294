export const simpleHash = (str: string) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
	}
	// Convert to 32bit unsigned integer in base 36 and pad with "0" to ensure length is 7.
	return (hash >>> 0).toString(36).padStart(7, "0");
};

const cyrb64 = (str: string, seed = 0) => {
	let h1 = 0xdeadbeef ^ seed;
	let h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
	// For a single 53-bit numeric return value we could return
	// 4294967296 * (2097151 & h2) + (h1 >>> 0);
	// but we instead return the full 64-bit value:
	return [h2 >>> 0, h1 >>> 0];
};

export const cyrb64Hash = (str: string, seed = 0) => {
	const [h2, h1] = cyrb64(str, seed);
	return h2.toString(36).padStart(7, "0") + h1.toString(36).padStart(7, "0");
};

// Helper function to convert number to Base36
export function toBase36(num: number): string {
	return num.toString(36);
}

export function toBase64(str: string): string {
	return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export function fromBase64(str: string): string {
	return atob(str.replace(/-/g, "+").replace(/_/g, "/"));
}

export function boolToStr(value: boolean): string {
	return value ? "1" : "0";
}
