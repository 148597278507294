import { unsafeRandomFromArray } from "@/lib/utils";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";

export class CInspectable extends SimComponent {
	descriptions: string[] = [];

	collectWorldActions(_doer: SimEntity, actions: TAction[]) {
		if (this.descriptions.length > 0) {
			actions.push("LOOKAT");
		}
	}

	setDescriptions(descriptions: string[]) {
		this.descriptions = descriptions;
	}

	getDescription(_entity: SimEntity) {
		return unsafeRandomFromArray(this.descriptions);
	}
}
