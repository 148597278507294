import { cn } from "@/lib/utils";
import type {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	PropsWithChildren,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { APP_DATA } from "@/data/app.data";
import { DiscordIcon } from "@/assets/discordIcon";

const defaultModal =
	"flex flex-col items-start justify-start min-w-[320px] w-[320px] h-[600px]  pointer-events-auto select-none text-sm relative font-spaceGrotesk";

function ModalBackground({
	children,
	className,
}: { children: React.ReactNode; className?: string }) {
	return (
		<>
			<div className="absolute w-full h-full border-2 border-white/100 bg-transparent blur-[20px] bg-[#E5E5E5]/30 rounded-lg font-berkeley" />
			<div
				className={cn(
					defaultModal,
					"bg-[#E5E5E5]/90  bg-gradient-to-b from-transparent to-white/15",
					className,
				)}
			>
				{children}
			</div>
		</>
	);
}

export function ShopModal({
	children,
	className,
}: { className?: string } & PropsWithChildren) {
	return (
		<AnimatePresence>
			<motion.div
				style={{
					scale: 0.8,
					x: 90,
					opacity: 0.7,
				}}
				transition={{ duration: 0.15 }}
				animate={{ x: 0, opacity: 1, scale: 1 }}
				className={cn(defaultModal)}
			>
				<ModalBackground className={className}>{children}</ModalBackground>
			</motion.div>
		</AnimatePresence>
	);
}

export function ShopFormModal({
	children,
	className,
	...props
}: { className?: string } & PropsWithChildren<
	React.FormHTMLAttributes<HTMLFormElement>
>) {
	return (
		<form className={cn(defaultModal)} {...props}>
			<div className="absolute w-full h-full border-2 border-white/100 bg-transparent blur-xl bg-[#E5E5E5]/30 rounded-lg animate-pulse font-berkeley" />
			{/* <div className="absolute w-full h-full border border-white/100 bg-transparent rounded-lg" /> */}
			<ModalBackground className={cn("receipt-style", className)}>
				{children}
			</ModalBackground>
		</form>
	);
}

export function ShopModalBlock({
	children,
	className,
}: { className?: string } & PropsWithChildren) {
	return (
		<div
			className={cn(
				"p-6 px-6 flex flex-col items-start justify-start w-full select-none",
				className,
			)}
		>
			{children}
		</div>
	);
}

export function ShopModalButton({
	children,
	className,
	...props
}: PropsWithChildren &
	DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>) {
	return (
		<button
			{...props}
			className={cn(
				"flex lowercase font-light py-1.5 px-4 rounded-full text-white bg-[#266818] cursor-pointer active:scale-95 transition-all disabled:bg-slate-600",
				className,
			)}
		>
			{children}
		</button>
	);
}

export function ShopDiscordButton({
	children,
	className,
	...props
}: PropsWithChildren &
	DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLAnchorElement>,
		HTMLAnchorElement
	>) {
	return (
		<a
			href={APP_DATA.social.discord.url}
			{...props}
			className={cn(
				"flex lowercase font-light py-1.5 px-4 rounded-full text-white bg-[#6A74F7] cursor-pointer active:scale-95 transition-all disabled:bg-slate-600 items-center",
				className,
			)}
		>
			<DiscordIcon className="w-4 h-4 inline mr-2 items-center fill-white stroke-white" />
			{children}
		</a>
	);
}
