import {
	registerBlueprint,
	type TBluePrint,
} from "@/data/blueprint/blueprint.data";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import Audio from "@game/audio/audioPlayer";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CStackable } from "@game/sim/components/CStackable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";

export class ItemSheetMetal extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem);
		e.addComponent(CStackable);
		e.addComponent(CBuildable);
	}
}

const entityData: TEntityDefinition = {
	name: "Sheet Metal",
	simEntityPrefab: ItemSheetMetal,
	id: 30004,
	metadata: {
		icon: "corrugated_sheet.webp",
	},
};

const sheetWall: TBluePrint = {
	name: "SheetWall",
	displayName: "Corrugated Wall",
	ingredients: [{ amount: 1, name: "Sheet Metal" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	canBuildFn: (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		return true;
	},
	onBuildFn: () => {
		Audio.playSound("PUT", { volume: 0.125 });
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		target: SimEntity,
	) => {
		const { action_buildWall } = getSystemCalls();
		const faceIndex = UIStore().faceIndex;
		const block = target.component(CRenderer).block;
		if (faceIndex === -1) throw new Error("faceIndex not set");
		if (!block) throw new Error("no block");
		console.log(`${faceIndex}build the wall`);
		await action_buildWall(doer.ref, target.ref, item.ref, faceIndex);
	},
};

registerBlueprint(sheetWall);
registerEntity(entityData);
