import { cn } from "@/lib/utils";
import type { PropsWithChildren } from "react";

export default function BottomLayout({
	children,
	...props
}: PropsWithChildren & { className?: string }) {
	return (
		<div
			className={cn(
				"absolute bottom-3 m-4 flex w-full justify-center gap-6 pointer-events-none",
				props.className,
			)}
		>
			{children}
		</div>
	);
}
