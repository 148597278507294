import { useFrame, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { ParticleStore, useParticleStore } from "./particle.store";

export const ParticleSystem = () => {
	const { scene } = useThree();
	const { renderer, scene: storeScene } = useParticleStore((state) => {
		return {
			renderer: state.renderer,
			scene: state.scene,
		};
	});

	useEffect(() => {
		if (!storeScene) ParticleStore().set({ scene });
		scene.add(renderer);
	}, [renderer, scene, storeScene]);

	useFrame((_state, delta) => {
		renderer.update(delta);
	});

	return <></>;
};
