import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CConstruction } from "@game/sim/components/CConstruction";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { HouseRenderer } from "./house.renderer";
import {
	registerBlueprint,
	type TBluePrint,
} from "@/data/blueprint/blueprint.data";
import Audio from "@game/audio/audioPlayer";
import { getSystemCalls } from "@mud/index";
import type { Hex } from "viem";

export class HousePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addTag("isPaintable");
		e.addComponent(CRenderer).setRenderer(HouseRenderer);
		e.addComponent(CConstruction).setRecipes("Scaffold", "Farm");
	}
}

const entityData: TEntityDefinition = {
	name: "House",
	simEntityPrefab: HousePrefab,
	id: 500,
};

const blueprint = {
	name: "House",
	upgradesFrom: "Scaffold",
	ingredients: [{ amount: 1, name: "Limestone" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	onBuildFn: () => {
		Audio.playSound("MUSIC_BOOTUP", { volume: 0.125 });
	},
	execute: async (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_addToBlueprint } = getSystemCalls();
		const coord = origin.component(CRenderer).block!.coordinate;
		const angle = Math.floor(Math.random() * 4);
		origin.remove();
		Audio.playSound("PUT");
		await action_addToBlueprint(
			doer.ref as Hex,
			item.ref as Hex,
			entityData.id,
			coord,
			angle,
		);
	},
} as TBluePrint;

registerEntity(entityData);
registerBlueprint(blueprint);
