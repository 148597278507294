type LogLevel = "INFO" | "WARN" | "ERROR" | "DEBUG";

class Logger {
	private queue: Promise<void> = Promise.resolve();

	constructor(private logEndpoint: string) {}

	private async sendLog(message: string): Promise<void> {
		if (import.meta.env.DEV) {
			this.queue = this.queue.then(async () => {
				await fetch(this.logEndpoint, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ message }),
				});
			});
			await this.queue;
		} else {
			console.log(message); // Fallback for production
		}
	}

	private formatMessage(level: LogLevel, ...args: any[]): string {
		const timestamp = new Date().toISOString();
		const message = args
			.map((arg) =>
				typeof arg === "object" ? JSON.stringify(arg) : String(arg),
			)
			.join(" ");
		return `[${timestamp}] ${level}: ${message}`;
	}

	async log(level: LogLevel, ...args: any[]): Promise<void> {
		const formattedMessage = this.formatMessage(level, ...args);
		await this.sendLog(formattedMessage);
	}

	async info(...args: any[]): Promise<void> {
		await this.log("INFO", ...args);
	}

	async warn(...args: any[]): Promise<void> {
		await this.log("WARN", ...args);
	}

	async error(...args: any[]): Promise<void> {
		await this.log("ERROR", ...args);
	}

	async debug(...args: any[]): Promise<void> {
		await this.log("DEBUG", ...args);
	}
}

export const FileLog = new Logger("/__log");
