import Audio from "@game/audio/audioPlayer";
import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";
import { CContainer } from "./CContainer";

export class CRummage extends SimComponent {
	uses = 0;
	maxUses = 0;
	useDefaultActions = true;

	setUseDefaultActions(useDefaultActions: boolean): this {
		this.useDefaultActions = useDefaultActions;
		return this;
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Rummage, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.warn("no value for rummage", this.inst.ref);
			return;
		}
		this.uses = value.uses;
		this.maxUses = value.maxUses;
	}

	hasUsesLeft(): boolean {
		return this.uses < this.maxUses;
	}

	async rummage(player: SimEntity): Promise<boolean> {
		this.inst.pushEvent("rummageStart", {});
		const { action_rummage } = getSystemCalls();
		Audio.playSound("LEAVES");
		await action_rummage(player.ref, this.inst.ref);
		Audio.playSound("PICKUP_LEAVE");
		this.inst.pushEvent("rummageComplete", {});
		return true;
	}

	collectWorldActions(doer: SimEntity, actions: TAction[]) {
		if (
			this.useDefaultActions &&
			doer.component(CContainer) &&
			!doer.component(CContainer).isFull() && // allow stacks > && doer.component(CContainer).allStacksFull()
			this.hasUsesLeft()
		) {
			actions.push("RUMMAGE");
		}
	}
}
