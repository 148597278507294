import { ENV } from "@/lib/env";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface IDevStore {
	devMode: boolean;
	showHexBorders: boolean;
	verbose: boolean;
}

const useDevStore = create<IDevStore>()(
	immer((_set, _get) => ({
		devMode: ENV.IDKFA,
		showHexBorders: ENV.SHOW_HEX_BORDERS,
		verbose: ENV.VERBOSE,
	})),
);

const DevStore = () => ({
	...useDevStore.getState(),
	set: useDevStore.setState,
});

const logVerbose = () => DevStore().verbose;
export { DevStore, useDevStore, logVerbose };
