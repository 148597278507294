"use client";
import { formatBytes32React } from "@/lib/format.utils";
import { cn } from "@/lib/utils";
import type { SimEntity } from "@game/sim/SimEntity";
import { getEntityByRef } from "@game/sim/sim.store";
import { toggleDev } from "@game/ui/ui.dev";
import { useUIStore } from "@game/ui/ui.store";
import { useWorldStore } from "@game/world/world.store";
import hotkeys from "hotkeys-js";
import { Leva, LevaPanel, useControls, useCreateStore } from "leva";
import type { StoreType } from "leva/dist/declarations/src/types";
import { createPlugin } from "leva/plugin";
import { useEffect, useMemo, useRef, useState } from "react";
import { Inspector as ReactInspector } from "react-inspector";
import type { Hex } from "viem";

const InspectorPanel = () => {
	const [lastHovered, setLastHovered] = useState<string | undefined>();
	const { hoveredObject } = useUIStore((state) => state);
	const entity = useMemo(() => {
		if (!hoveredObject && !lastHovered) return;
		setLastHovered(hoveredObject);
		const entity = getEntityByRef(hoveredObject || lastHovered);
		if (!entity) return;
		return entity;
	}, [hoveredObject, lastHovered]);

	return (
		<>
			{entity && (
				<div className="bg-[#292D39] -mx-2 px-2 -my-2 mb-2 text-white">
					<div className="flex flex-col mb-2">
						<div className="flex flex-row py-1">
							<div
								className="inline flex-grow"
								onClick={() => {
									console.log(entity);
								}}
							>
								🔍 {entity.name}{" "}
							</div>
							<div className="flex-row inline items-end">
								{formatBytes32React(entity.ref as Hex)}
							</div>
						</div>
						<div className="mb-1 pb-1">
							<ReactInspector
								theme="chromeDark"
								data={{
									ref: entity.ref,
									name: entity.name,
									components: entity._components,
									children: entity._children,
									parent: entity._parent,
								}}
								expandLevel={1}
								name={entity.name}
								table={false}
							/>
						</div>
					</div>
				</div>
			)}
			<Hierarchy />
		</>
	);
};

const HierarchyEntry = ({ entity }: { entity: SimEntity }) => {
	const [collapsed, setCollapsed] = useState(true);
	return (
		<div className="flex flex-col mb-2 mr-2">
			<div
				onClick={() => setCollapsed(!collapsed)}
				className="-mr-2 flex flex-row"
			>
				<div className="flex flex-row flex-grow">
					<div
						className={cn(
							"inline-block text-sm mr-2 select-none text-gray-600",
							!collapsed && "rotate-90 text-white",
						)}
					>
						▶
					</div>
					<div
						className="inline text-gray-200 flex-grow"
						onClick={() => {
							console.log(entity);
						}}
					>
						{entity.name}{" "}
					</div>
					<div className="flex-row inline items-end">
						{formatBytes32React(entity.ref as Hex)}
					</div>
				</div>
			</div>
			<div className="max-w-[400px]">
				{!collapsed && (
					<div className="mb-1 ml-2">
						<ReactInspector
							theme="chromeDark"
							data={entity}
							expandLevel={1}
							name={entity.name}
							table={false}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const Hierarchy = () => {
	const [filter, setFilter] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);
	const entities = useWorldStore((state) => state.entities);

	useEffect(() => {
		hotkeys("shift+f", (event, _handler) => {
			event.preventDefault();
			inputRef.current?.focus();
		});
	}, []);

	const filteredEntities = useMemo(() => {
		if (!entities) return [];
		return entities.filter((e) => {
			if (filter === "") return true;
			return (
				e.name.toLowerCase().includes(filter.toLowerCase()) ||
				e.ref.toLowerCase().includes(filter.toLowerCase())
			);
		});
	}, [entities, filter]);

	return (
		<div className="text-white">
			<input
				ref={inputRef}
				className="pointer-events-auto button mb-2 w-full bg-transparent text-white p-1 px-2 border border-gray-600 rounded-md"
				type="text"
				placeholder="Filter"
				value={filter}
				onChange={(e) => {
					console.log(e.target.value);
					setFilter(e.target.value);
				}}
				onClick={(e) => {
					console.log(e);
					inputRef.current?.focus();
				}}
			/>
			{filter.length > 0 && (
				<div
					className="inline-block mb-1 right-0 text-gray-500 outline-none"
					style={{ fontSize: "8px" }}
				>
					{filteredEntities.length} matches
				</div>
			)}
			{filteredEntities.map((entity) => (
				<HierarchyEntry key={entity.ref} entity={entity} />
			))}
		</div>
	);
};

const inspector = createPlugin({
	component: InspectorPanel,
});

const Inspector = ({ store }: { store: StoreType }) => {
	useControls({ inspector: inspector() }, { store });
	return null;
};

export function DevToolPanel() {
	// we hand off the store so it can do HMR refreshes
	const store = useCreateStore();

	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				toggleDev();
			}
		};

		window.addEventListener("keydown", handleEscape);
		return () => {
			window.removeEventListener("keydown", handleEscape);
		};
	}, []);

	return (
		<>
			<Leva titleBar={false} />
			<LevaPanel store={store} titleBar={{ filter: false }} />
			<Inspector store={store} />
		</>
	);
}
