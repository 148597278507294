import { logVerbose } from "@/data/dev.store";
import { registerEntity } from "@/data/entity/entity.data.fn";
import { Debug } from "@/lib/debug/debug";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { WorldStore } from "@game/world/world.store";

export class WorldPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(WorldRenderer);
		WorldStore().set({
			worldRef: e.ref as string,
		});
		logVerbose() && Debug("World").log("Created world prefab");
	}
}

const WorldRenderer = () => {
	return (
		<group>
			<primitive object={{}} />
		</group>
	);
};

const EntityData = {
	name: "World",
	simEntityPrefab: WorldPrefab,
	id: 1000000,
};

registerEntity(EntityData);
