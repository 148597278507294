import type { TEntity } from "@/data/entity/entity.data.types";
import { SimEntity } from "./SimEntity";
import { addEntity, addTask } from "./sim.store";
import type { TTask } from "./task";

export function createPrefabInstance(entity: TEntity, ref: string): SimEntity {
	const inst = new entity.simEntityPrefab(
		new SimEntity(ref, entity.entityTypeId, entity.name),
	).inst;
	addEntity(inst);
	return inst;
}

export function createTaskInstance(task: TTask): TTask {
	// add task to store
	addTask(task);
	return task;
}
