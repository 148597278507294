import type { Block } from "@game/world/block";
import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";

export type TRendererProps = {
	entity: SimEntity;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	props?: any;
};
type TRendererFn = ({ entity }: TRendererProps) => React.ReactNode;

export class CRenderer extends SimComponent {
	renderer: TRendererFn | null = null;
	block: Block | null = null;
	orientation = 0;

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Orientation, {
			inst: this.inst.ref as Hex,
		});
		if (value) {
			this.orientation = value.orientation;
		}
	}

	setRenderer(renderer: TRendererFn) {
		this.renderer = renderer;
	}

	setOrientation(orientation: number) {
		this.orientation = orientation;
	}

	setBlock(block: Block) {
		this.block = block;
	}
}
