import { useState } from "react";
import { getEntityData } from "../entity/entity.data.fn";

const preload = [
	"/assets/ui/InvSlotBackground.png",
	"/assets/ui/InvBgSplash00.webp",
	"/assets/ui/selection.png",
];

export function BackgroundImgLoader() {
	const [images] = useState(
		[
			...Object.values(getEntityData())
				.map((e) => `/icons/${e.metadata?.icon}` || undefined)
				.filter((e) => e !== undefined),
			...preload,
		].filter((e, i, arr) => arr.indexOf(e) === i),
	);
	return (
		<div className="hidden">
			{images.map((e) => (
				<img src={e} key={e} />
			))}
		</div>
	);
}
