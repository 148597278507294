import { Hexagrid } from "@/lib/hexagrid/hexagrid";
import { hexToPixel } from "@/lib/hexagrid/hexagrid.functions";
// import { cantorPairing } from "@/lib/random/random.functions";
import { Vector2 } from "three";
import type { THexCoordinate } from "./world.types";

const searchIterationCount = 12;

export const createGrid = (coordinates: THexCoordinate) => {
	// use cantor pairing for the seed and make sure we skip 0,0 because cantor pairing will break
	const hexPos = new Vector2(coordinates[0], coordinates[1]);
	const seed = hexPos.equals(new Vector2(0, 0)) ? 42 : 42;
	const hexOffset = hexToPixel({ q: hexPos.x, r: hexPos.y });
	const grid = new Hexagrid({
		coordinates,
		seed,
		searchIterationCount,
		hexOffset,
	});
	grid.relax(7);
	return grid;
};
