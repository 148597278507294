import { pixelToPointyHex } from "@/lib/hexagrid/hexagrid.functions";
import { SeedFactory } from "@/lib/hexagrid/seedfactory";
import { downloadBlob } from "@/lib/utils";
import { Block } from "@game/world/block";
import { getGrid } from "@game/world/grid.store";
import { ISLANDS } from "@game/world/islands/islands.functions";
import { WorldCoordinate } from "@game/world/world.types";

import FastNoiseLite from "fastnoise-lite";
import { Vector2, Vector3 } from "three";

export type ExportedBlock = {
  coordinate: WorldCoordinate;
  entityType: number;
  rotation: number;
  island: number;
};

export type GeneratorOptions = {
  scale: number;
  islands: number;
  minExtrusion: number;
  maxExtrusion: number;
  minWalkDistance: number;
  maxWalkDistance: number;
  minHeight: number;
  maxHeight: number;
};

export type GeneratorBlock = {
  position: Vector3;
  block: Block;
  coordinate: WorldCoordinate;
  entityType: number;
  rotation: number;
};

export type GeneratorResults = {
  generationStart: number;
  generationTime: number;
  vertices: Vector3[];
  blocks: GeneratorBlock[];
};

export const IslandGenerator = ({
  options: generatorOptions,
}: {
  options?: Partial<GeneratorOptions>;
}): GeneratorResults => {
  const Rnd = new SeedFactory({ seed: 42 });
  const options = Object.assign(
    {
      scale: 128,
      islands: 10,
      minExtrusion: 1,
      maxExtrusion: 0,
      minWalkDistance: 3,
      maxWalkDistance: 10,
      minHeight: -40,
      maxHeight: 40,
    },
    generatorOptions
  );
  const startTime = Date.now();

  const noise = new FastNoiseLite();
  noise.SetSeed(42);
  noise.SetNoiseType(FastNoiseLite.NoiseType.Cellular);
  noise.SetFractalType(FastNoiseLite.FractalType.Ridged);
  noise.SetFractalOctaves(20);
  noise.SetFractalLacunarity(1.7);
  noise.SetFractalGain(1.5);

  const thresholds = [0.13, 0.9];

  // Gather noise data
  const noiseData: number[][] = [];
  let vertices: Vector3[] = [];

  const { scale } = options;
  for (let x = 0; x < scale; x++) {
    noiseData[x] = [];

    for (let y = 0; y < scale; y++) {
      noiseData[x][y] = noise.GetNoise(x, y);
      if (noiseData[x][y] > thresholds[0] && noiseData[x][y] < thresholds[1]) {
        const height = Math.round(
          Rnd.Range(options.minHeight, options.maxHeight)
        );
        vertices.push(new Vector3(x / 2, height, y / 2));
      }
    }
  }

  vertices = performRandomWalks(
    vertices,
    options.maxWalkDistance,
    options.minWalkDistance,
    Rnd
  );
  vertices = expandVertices(vertices, options, Rnd);
  vertices = performRandomWalks(vertices, 2, 0, Rnd);

  // so whatever just remove all duplicates
  vertices = vertices.filter(
    (v, i, a) => a.findIndex((t) => t.distanceTo(v) < 0.1) === i
  );

  let blocks = generateRocks(vertices, Rnd);
  // blocks = generateJungle(blocks);

  // remove duplicate blocks
  blocks = blocks.filter(
    (v, i, a) => a.findIndex((t) => t.coordinate.equals(v.coordinate)) === i
  );
  ISLANDS.reset();
  for (const block of blocks) {
    ISLANDS.registerBlock(block.block);
  }
  // ENABLE THIS TO DOWNLOAD THE JSON IN BROWSER
  // downloadJSON(blocks);

  return {
    generationStart: startTime,
    generationTime: Date.now() - startTime,
    vertices,
    blocks,
  };
};

const downloadJSON = (blocks: GeneratorBlock[]) => {
  const exportData = {
    entities: blocks.map((e) => exportBlock(e)),
    contractData: blocks.map((e) => exportContractData(e)),
  };
  // console.log(exportData);
  // console.log(ISLANDS.all);
  const json = JSON.stringify(exportData);
  const blob = new Blob([json], { type: "application/json" });
  const filename = "blocks-world.json";
  downloadBlob(blob, filename);
};

export const saveWithBun = (result: GeneratorResults, filename: string) => {
  const { blocks } = result;
  const exportData = {
    entities: blocks.map((e) => exportBlock(e)),
    contractData: blocks.map((e) => exportContractData(e)),
  };
  const json = JSON.stringify(exportData);
  const blob = new Blob([json], { type: "application/json" });
  Bun.write(`./files/${filename}`, blob);

  // generate debug single island version
  const debugEntities = blocks.filter(
    (e) => ISLANDS.all.indexOf(e.block!.getIsland()) === 0
  );
  const debugExport = {
    entities: debugEntities.map((e) => exportBlock(e)),
    contractData: debugEntities.map((e) => exportContractData(e)),
  };
  const debugBlob = new Blob([JSON.stringify(debugExport)], {
    type: "application/json",
  });
  Bun.write(`./files/debug-${filename}`, debugBlob);
};

const exportBlock = (block: GeneratorBlock) => {
  const exportData = {
    coordinate: block.coordinate.toString(),
    entityType: block.entityType,
    rotation: block.rotation,
    island: ISLANDS.all.indexOf(block.block!.getIsland()),
    x: block.coordinate.grid[0],
    y: block.coordinate.grid[1],
    quad: block.coordinate.quad,
    height: block.coordinate.y,
  };
  return exportData;
};

const exportContractData = (block: GeneratorBlock) => {
  const exportData = {
    entityTypeId: block.entityType,
    height: block.coordinate.y,
    quad: block.coordinate.quad,
    x: block.coordinate.grid[0],
    y: block.coordinate.grid[1],
  };
  return exportData;
};

const generateRocks = (
  vertices: Vector3[],
  Rnd: SeedFactory
): GeneratorBlock[] => {
  const blocks: GeneratorBlock[] = [];
  for (const vertex of vertices) {
    const coord = getCoordinate(vertex);
    if (!coord) continue;
    const block = {
      position: vertex,
      coordinate: coord,
      entityType: 2,
      rotation: Rnd.Range(0, 4),
      block: new Block(coord),
    } as GeneratorBlock;
    blocks.push(block);
  }
  return blocks;
};

const generateJungle = (rocks: GeneratorBlock[]): GeneratorBlock[] => {
  const blocks: GeneratorBlock[] = [];
  // we check each block, is there is no block above it, we add a jungle block on top of it with entityType 3
  blocks.push(...rocks);
  for (const rock of rocks) {
    const coord = rock.coordinate;
    // search through rocks to find same hexgrid, then find same quad id, then see if there's a block above it
    const above = rocks.filter(
      (r) =>
        r.coordinate.grid[0] === coord.grid[0] &&
        r.coordinate.grid[1] === coord.grid[1] &&
        r.coordinate.quad === coord.quad
    );
    // get the highest block
    const aboveBlock = above.sort((a, b) => b.coordinate.y - a.coordinate.y)[0];
    if (aboveBlock) {
      const newCoord = new WorldCoordinate(
        [aboveBlock.coordinate.grid[0], aboveBlock.coordinate.grid[1]],
        aboveBlock.coordinate.quad,
        aboveBlock.coordinate.y + 1
      );
      // check if newCoord is already in the list
      if (!blocks.find((b) => b.coordinate.equals(newCoord))) {
        const newBlock = {
          position: aboveBlock.position!.clone().add(new Vector3(0, 1, 0)),
          coordinate: newCoord,
          entityType: 3,
          rotation: aboveBlock.rotation,
          block: new Block(newCoord),
        };
        blocks.push(newBlock);
      }
    }
  }
  return blocks;
};

// shitty random walker
const performRandomWalks = (
  points: Vector3[],
  maxWalkDistance: number,
  minWalkDistance: number,
  Rnd: SeedFactory
): Vector3[] => {
  const islandVertices: Vector3[] = [];
  const directions = [
    new Vector3(1, 0, 0),
    new Vector3(-1, 0, 0),
    // new Vector3(0, 1, 0),
    new Vector3(0, -2, 0), // This compensates for the scalar 0.5
    new Vector3(0, 0, 1),
    new Vector3(0, 0, -1),
  ];
  for (const point of points) {
    const currentPoint = point.clone();
    const count =
      (Rnd.Range(0, 100) / 100) * (maxWalkDistance - minWalkDistance) +
      minWalkDistance;
    for (let step = 0; step < count; step++) {
      const direction = Rnd.randomFromArray(directions);
      currentPoint.add(direction.clone().multiplyScalar(0.5));
      islandVertices.push(currentPoint.clone());
    }
  }
  return islandVertices;
};

// shitty growth function
const expandVertices = (
  vertices: Vector3[],
  options: GeneratorOptions,
  Rnd: SeedFactory
): Vector3[] => {
  const expandedVertices: Vector3[] = [];
  const directions = [
    new Vector3(1, 0, 0),
    new Vector3(-1, 0, 0),
    new Vector3(0, 2, 0), // This compensates for the scalar 0.5
    new Vector3(0, -2, 0), // This compensates for the scalar 0.5
    new Vector3(0, 0, 1),
    new Vector3(0, 0, -1),
  ];
  for (const vertex of vertices) {
    for (const direction of directions) {
      const expandedVertex = vertex
        .clone()
        .add(direction.clone().multiplyScalar(0.5));
      expandedVertices.push(expandedVertex);
    }
  }
  return expandedVertices;
};

export function getWorldPosToGrid(pos: Vector3) {
  const hex = pixelToPointyHex(new Vector2(pos.x, pos.z));
  const grid = getGrid([hex.q, hex.r])!;
  return grid;
}

export function getWorldPosToQuad(pos: Vector3) {
  const grid = getWorldPosToGrid(pos)!;
  const offsetPos = new Vector2(
    pos.x - grid.hexOffset.x,
    pos.z - grid.hexOffset.y
  );
  const q = grid.getNearestQuad(offsetPos);
  if (!q) {
    console.error(`could not get quad for ${offsetPos.x},${offsetPos.y}`);
    return null;
  }
  return q;
}

export function getCoordinate(pos: Vector3): WorldCoordinate | null {
  const grid = getWorldPosToGrid(pos)!;
  const offsetPos = new Vector2(
    pos.x - grid.hexOffset.x,
    pos.z - grid.hexOffset.y
  );
  const q = grid.getNearestQuad(offsetPos)!;
  const index = grid.getQuadIndex(q);
  if (!q) {
    console.error(`could not get quad for ${offsetPos.x},${offsetPos.y}`);
    return null;
  }
  const coord = new WorldCoordinate(
    [grid.coordinates[0], grid.coordinates[1]],
    index,
    pos.y
  );
  return coord;
}
