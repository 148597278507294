import { theSim } from "./sim.store";

export type TTask = {
	inst: string;
	ref: string;
	isPeriodic: boolean;
	startTime: bigint;
	time: bigint;
};

/*
 * @dev Get the percentage complete of a task
 */
const getPercentageComplete = (task: TTask) => {
	if (task === undefined) return 0;
	const currentTime = theSim.latestTimestamp;
	const taskTime = Number(task.time);
	const taskStart = Number(task.startTime);
	const isPeriodic = task.isPeriodic;
	let c = ((currentTime - taskStart) * 100) / taskTime;

	if (isPeriodic) {
		c = (((currentTime - taskStart) % taskTime) * 100) / taskTime;
	}
	return c;
};

export { getPercentageComplete };
