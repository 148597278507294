import { getHex } from "@/lib/format.utils";
import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { getEntityByRef } from "../sim.store";
import { SimComponent } from "./SimComponent";

export class CPlot extends SimComponent {
	items: Record<string, SimEntity> = {};
	maxItems = 3;

	constructor(inst: SimEntity) {
		super(inst);
		this.items = {};
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Plot, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		this.items = {};
		this.maxItems = value.maxItems;

		for (let i = 0; i < value.items.length; i++) {
			if (value.items[i] === getHex(0)) continue;
			const item = getEntityByRef(value.items[i]);
			if (!item) {
				throw new Error(`item not found ${value.items[i]}`);
			}
			this.items[i] = item;
		}
	}

	isEmpty(): boolean {
		return Object.values(this.items).length === 0;
	}

	isFull(): boolean {
		return Object.values(this.items).length >= this.maxItems;
	}

	async putItem(doer: SimEntity, item: SimEntity): Promise<boolean> {
		const { putItem, containerRemoveItem } = getSystemCalls();
		await containerRemoveItem(doer.ref, item.ref);
		await putItem(this.inst.ref, item.ref);
		return true;
	}

	async removeItem(player: SimEntity, item: SimEntity): Promise<boolean> {
		const { removePlotItem, containerTakeItem } = getSystemCalls();
		await removePlotItem(this.inst.ref, item.ref);
		await containerTakeItem(player.ref, item.ref, 255);
		return true;
	}
}
