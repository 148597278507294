import { useWorldGlobals, WorldGlobals } from "@game/sim/sim.store";

export function PinkGlassEffect() {
	return (
		<div className="fullscreen bg-purple-500 mix-blend-screen opacity-50 pointer-events-none" />
	);
}

export function SmudgedGlassEffect() {
	return <div className="fullscreen backdrop-blur-xl pointer-events-none" />;
}

export function CSSEffects() {
	const { blurredVision, pinkVision } = useWorldGlobals((state) => state);

	return (
		<>
			{blurredVision && <SmudgedGlassEffect />}
			{pinkVision && <PinkGlassEffect />}
		</>
	);
}
