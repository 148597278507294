import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { CEater } from "./CEater";
import { SimComponent } from "./SimComponent";

export class CEdible extends SimComponent {
	healthValue = 10;
	hungerValue = 10;

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (target.component(CEater)) {
			actions.push("FEED");
		}
	}
}
