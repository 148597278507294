import type { Block } from "@game/world/block";
import {
	BufferAttribute,
	type BufferGeometry,
	ExtrudeGeometry,
	type Face,
	type Intersection,
	type Object3D,
	type Object3DEventMap,
	Shape,
	ShapeGeometry,
	Vector2,
	Vector3,
} from "three";

const extrudeSettings = {
	depth: 0.5,
	bevelEnabled: false,
};

const calcUVs = (geometry: BufferGeometry) => {
	geometry.computeBoundingBox();
	const bbox = geometry.boundingBox!;
	const size = new Vector3();
	bbox.getSize(size);

	const pos = geometry.getAttribute("position");
	const nor = geometry.getAttribute("normal");
	const uvs = new Float32Array(pos.count * 2); // two UV coordinates for each position

	for (let i = 0; i < pos.count; i++) {
		const vertex = new Vector3().fromBufferAttribute(pos, i);
		const normal = new Vector3().fromBufferAttribute(nor, i).normalize();

		let u: number;
		let v: number;

		// Project vertex onto plane most aligned with the normal
		if (
			Math.abs(normal.x) > Math.abs(normal.y) &&
			Math.abs(normal.x) > Math.abs(normal.z)
		) {
			// X-plane
			u = (vertex.y - bbox.min.y) / size.y;
			v = (vertex.z - bbox.min.z) / size.z;
		} else if (
			Math.abs(normal.y) > Math.abs(normal.x) &&
			Math.abs(normal.y) > Math.abs(normal.z)
		) {
			const mappings = [
				{ u: 0, v: 0 }, // Vertex 0
				{ u: 0, v: 1 }, // Vertex 1
				{ u: 1, v: 1 }, // Vertex 2
				{ u: 1, v: 0 }, // Vertex 1
			];
			u = mappings[i].u;
			v = mappings[i].v;
		} else {
			// Z-plane
			u = (vertex.x - bbox.min.x) / size.x;
			v = (vertex.y - bbox.min.y) / size.y;
		}

		// Set UV coordinates
		uvs[i * 2] = u;
		uvs[i * 2 + 1] = v;
	}

	// Update the geometry's UV attribute
	geometry.setAttribute("uv", new BufferAttribute(uvs, 2));
};

const getVertFromGeo = (geo: ExtrudeGeometry, index: number) => {
	const x = geo.getAttribute("position").getX(index);
	const y = geo.getAttribute("position").getY(index);
	const z = geo.getAttribute("position").getZ(index);
	return new Vector3(x, y, z);
};

const flipWindingOrder = (geometry: BufferGeometry) => {
	if (!geometry.index) return;
	const index = geometry.index.array;
	for (let i = 0, il = index.length / 3; i < il; i++) {
		const x = index[i * 3];
		index[i * 3] = index[i * 3 + 2];
		index[i * 3 + 2] = x;
	}
	geometry.index.needsUpdate = true;
};

function moveGeometryVerticesAlongNormals(
	geometry: BufferGeometry,
	distance: number,
) {
	const positions = geometry.getAttribute("position");
	const normals = geometry.getAttribute("normal");
	const newPosition = new Float32Array(positions.count * 3); // Same size as positions array

	for (let i = 0; i < positions.count; i++) {
		const nx = normals.getX(i);
		const ny = normals.getY(i);
		const nz = normals.getZ(i);

		const vx = positions.getX(i) + nx * distance;
		const vy = positions.getY(i) + ny * distance;
		const vz = positions.getZ(i) + nz * distance;

		newPosition.set([vx, vy, vz], i * 3);
	}

	geometry.setAttribute("position", new BufferAttribute(newPosition, 3));
}

export const extrudeBlock = (block: Block) => {
	const p = block._points;
	const points = p.map((p) => p.clone().sub(block._quadCenter));
	const shape = new Shape(points);

	const geometry = new ExtrudeGeometry(shape, extrudeSettings);
	geometry.rotateX(Math.PI / 2);
	geometry.translate(0, 0.25, 0);
	geometry.computeVertexNormals();
	geometry.normalizeNormals();
	return geometry;
};

export const extrudeSides = (block: Block) => {
	// loop over the points, and extrude a quad for each side
	const p = block._points;
	const points = p.map((p) => {
		const dist = p.clone().sub(block._quadCenter);
		const clone = dist.clone().multiplyScalar(1.05);
		return clone;
	});
	const geos = [];
	for (let i = 0; i < block._points.length; i++) {
		const p1 = points[i];
		const p2 = points[(i + 1) % block._points.length];
		const shape = new Shape([p1, p2]);
		const geometry = new ExtrudeGeometry(shape, extrudeSettings);
		geometry.rotateX(Math.PI / 2);
		geometry.translate(0, 0.25, 0); //move up
		geometry.computeVertexNormals();
		geometry.normalizeNormals();

		geos.push(geometry);
	}
	// create top and bottom
	const shapeT = new Shape(points.reverse());
	const geometryT = new ShapeGeometry(shapeT);

	flipWindingOrder(geometryT); // otherwise invisible
	geometryT.rotateX(Math.PI / 2);
	geometryT.translate(0, 0.25 + 0.015, 0);
	geometryT.computeVertexNormals();
	geometryT.normalizeNormals();
	geos.push(geometryT);

	const shapeB = new Shape(points.reverse());
	const geometryB = new ShapeGeometry(shapeB);

	geometryB.rotateX(Math.PI / 2);
	geometryB.translate(0, -0.25 - 0.015, 0);
	geometryB.computeVertexNormals();
	geometryB.normalizeNormals();
	geos.push(geometryB);

	geos.forEach((g) => calcUVs(g));

	geos.forEach((geometry) => {
		moveGeometryVerticesAlongNormals(geometry, 0.0225);
		calcUVs(geometry); // Recalculate UVs if necessary, after moving the vertices
	});
	return geos;
};

export const lowPrecisionCompare = (a: Vector2, b: Vector2) => {
	return (
		a.x.toPrecision(3) === b.x.toPrecision(3) &&
		a.y.toPrecision(3) === b.y.toPrecision(3)
	);
};

const getSide = (verts: Vector3[], block: Block) => {
	const v2 = verts.map((v) => new Vector2(v.x, v.z));
	//remove the double vert, there will be 2 vertices in the array that are the same, we need to remove one of them
	const filtered: Vector2[] = [];
	for (let i = 0; i < v2.length; i++) {
		const v = v2[i];
		if (filtered.find((f) => f.equals(v))) continue;
		filtered.push(v);
	}
	const sides = block._sides;
	let match: Vector2[] | undefined;

	// need to compare a low precision
	for (const side of sides) {
		const [a, b] = side;
		const matchA = filtered.find((f) => lowPrecisionCompare(f, a));
		const matchB = filtered.find((f) => lowPrecisionCompare(f, b));
		if (matchA && matchB) match = side;
	}
	if (!match) {
		console.error(
			`Could not find side for ${JSON.stringify(filtered)} in ${JSON.stringify(
				sides,
			)}`,
		);
		return -2;
	}
	const idx = block._sides.indexOf(match);

	return idx;
};

let cache = {
	block: null as Block | null,
	face: null as Face | null,
	side: -1,
};

export const getSideFromHit = (
	geo: ExtrudeGeometry,
	hit: Intersection<Object3D<Object3DEventMap>>,
	block: Block,
): number => {
	const face = hit.face;
	if (cache.block === block && cache.face === face && cache.side !== null)
		return cache.side;
	if (!face) return -1;
	const verts: Vector3[] = [];
	verts.push(getVertFromGeo(geo, face.a));
	verts.push(getVertFromGeo(geo, face.b));
	verts.push(getVertFromGeo(geo, face.c));
	// if all Y axis are the same, we are on the top or bottom
	if (verts.every((v) => v.y === verts[0].y)) {
		if (verts[0].y > 0) return 4;
		return 5;
	}
	cache = {
		block: block,
		face: face,
		side: getSide(verts, block),
	};
	return cache.side;
};
