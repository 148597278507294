import prand from "pure-rand";

export class SeedFactory {
  seed = 42;
  rng: ReturnType<typeof prand.xoroshiro128plus>;
  constructor({ seed }: { seed?: number }) {
    this.seed = seed || this.seed;
    this.rng = prand.xoroshiro128plus(this.seed);
  }

  Range(min: number, max: number): number {
    return prand.unsafeUniformIntDistribution(min, max, this.rng);
  }

  randomFromArray = <T>(array: T[]): T => {
    return array[Math.floor(this.Range(0, array.length - 1))];
  };
}
