import Audio from "@game/audio/audioPlayer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { CContainer } from "@game/sim/components/CContainer";
import { WorldStore } from "@game/world/world.store";

export class ItemDrumMachine extends SimEntityPrefab {
	lastIndex = 0;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("shovel");
		e.addComponent(CUsable)
			.setCanUseFn(this.canUseFn.bind(this))
			.setUseFn(this.useFn.bind(this));
	}

	canUseFn = (_doer: SimEntity, target: SimEntity) => {
		if (target === this.inst) {
			return true;
		}
		return false;
	};

	useFn = async (_doer: SimEntity, _target: SimEntity) => {
		const inv = WorldStore().getPlayer()?.component(CContainer);
		const slots = inv?.slots;
		if (slots) {
			// we want to get a seed from the itemID or empty slot
			const idx = this.lastIndex % inv?.numSlots;
			const slot = slots[idx];
			// while (!slot) {
			// 	this.lastIndex++;
			// 	idx = this.lastIndex % inv?.numSlots;
			// 	slot = slots[idx];
			// }
			if (slot) {
				console.log("idx", slot._entityTypeID * idx);
				Audio.playSound("DRUM", { index: slot._entityTypeID * (idx + 2) });
			} else {
				Audio.playSound("DRUM", { index: 0 });
			}
			this.lastIndex++;
			return;
		}
		// make sure 50 ms has passed
		console.log("play");
		Audio.playSound("DRUM");
	};
}

const entityData: TEntityDefinition = {
	name: "Drum Machine",
	simEntityPrefab: ItemDrumMachine,
	id: 50004,
	metadata: {
		icon: "drum_machine.webp",
		price: 599,
	},
} as const;

registerEntity(entityData);
