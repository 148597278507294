import { getColorByName } from "@/data/colors/palette.data";
import Audio from "@game/audio/audioPlayer";
import { getSystemCalls } from "@mud";
import { Color } from "three";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";

export const PaintColors = [
	"Bone", // base color
	"Red Ochre",
	"Goldenrod",
	"Blackberries",
	"Carrot",
	"Turqoise",
	"Lapis Lazuli",
	"Meteorite",
	"Red Pepper",
	"Spinach",
	"Lilac",
]; //(paint index starts at 1)

export class CPaint extends SimComponent {
	color = 0;

	setColor(color: number) {
		this.color = color;
	}

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (target.hasTag("isPaintable")) {
			actions.push("PAINT");
		}
	}

	async paintTarget(target: SimEntity) {
		const { action_paint } = getSystemCalls();
		await action_paint(this.inst.ref, target.ref, this.inst.ref, this.color, 0);
		Audio.playSound("FLESH_HIT", { volume: 0.125 });
	}

	static getPaint(color: number): Color {
		if (color < 0 || color > PaintColors.length) {
			throw new Error("Invalid color index");
		}
		const hex = getColorByName("houses", PaintColors[color])?.hex;
		if (!hex) {
			throw new Error("Invalid color");
		}
		return new Color(hex);
	}
}
