import type { Block } from "@game/world/block";
import type { MeshProps } from "@react-three/fiber";
import { type PropsWithChildren, createRef, useEffect, useMemo } from "react";
import { type Mesh, Vector3 } from "three";

const s = 1;

const basicBox: Vector3[] = [
	new Vector3(-1.25, 1.25, 1.25).multiplyScalar(s),
	new Vector3(-1.25, 1.25, -1.25).multiplyScalar(s),
	new Vector3(1.25, 1.25, -1.25).multiplyScalar(s),
	new Vector3(1.25, 1.25, 1.25).multiplyScalar(s),
	new Vector3(-1.25, -1.25, 1.25).multiplyScalar(s),
	new Vector3(-1.25, -1.25, -1.25).multiplyScalar(s),
	new Vector3(1.25, -1.25, -1.25).multiplyScalar(s),
	new Vector3(1.25, -1.25, 1.25).multiplyScalar(s),
];

const angles = [0, 90, 180, -90];

function linearInterpolate(
	v1: Vector3,
	v2: Vector3,
	divisions: number,
): Vector3[] {
	const points: Vector3[] = [];
	for (let i = 0; i <= divisions; i++) {
		const t = i / divisions;
		points.push(
			new Vector3(
				v1.x + (v2.x - v1.x) * t,
				v1.y + (v2.y - v1.y) * t,
				v1.z + (v2.z - v1.z) * t,
			),
		);
	}
	return points;
}

function interpolateBoxPoints(box: Vector3[], divisions: number): Vector3[] {
	const interpolatedPoints: Vector3[] = [];
	for (let i = 0; i < box.length; i++) {
		// Connect each point with the next, wrapping around at the end
		const nextIndex = (i + 1) % box.length;
		interpolatedPoints.push(
			...linearInterpolate(box[i], box[nextIndex], divisions),
		);
	}
	return interpolatedPoints;
}
const interpolatedBasicBox = interpolateBoxPoints(basicBox, 5);
function DeformedMesh({
	block,
	model,
	children,
	orientation,
	...props
}: {
	block: Block;
	model: Mesh;
	color: string;
	orientation?: number;
} & PropsWithChildren &
	MeshProps) {
	const meshRef = createRef<Mesh>();

	const { mesh } = useMemo(() => {
		if (!model || !model.geometry) return {};
		const geometry = model.geometry.clone();
		if (orientation !== undefined) {
			orientation = Math.max(0, orientation % 4);
		}
		const randomAngle = Math.floor(Math.random() * angles.length);
		// invert orientation
		const angle =
			angles[orientation !== undefined ? 4 - orientation : randomAngle];
		const radians = (angle * Math.PI) / 180;
		if (orientation) {
			geometry.rotateY(radians);
		}

		const positions = geometry.attributes.position;
		const blockPoints = block._points.map((v) => {
			const p = v.clone().sub(block._quadCenter);
			return new Vector3(p.x, 0.25, p.y);
		});

		for (let i = 0; i < 4; i++) {
			blockPoints.push(blockPoints[i].clone().setY(-0.25));
		}
		const deformationModel: Vector3[] = interpolateBoxPoints(blockPoints, 5);

		const vertex = new Vector3();
		for (let i = 0; i < positions.count; i++) {
			vertex.fromBufferAttribute(positions, i);

			const weights: number[] = [];
			for (let j = 0; j < interpolatedBasicBox.length; j++) {
				const dist = vertex.distanceTo(
					interpolatedBasicBox[j].clone().setY(vertex.y),
				);
				const force = 1 / (1 + dist);
				const w = (-dist * force) / 1;
				weights[j] = w;
			}
			const force = deformationModel.reduce((acc, curr, idx) => {
				return acc.add(curr.clone().multiplyScalar(weights[idx]));
			}, new Vector3());

			positions.setXYZ(
				i,
				(force.x / 5) * 1.125,
				vertex.y * 0.25,
				(force.z / 5) * 1.125,
			);
		}

		positions.needsUpdate = true;
		geometry.computeBoundingBox();
		geometry.computeVertexNormals();

		return { mesh: geometry };
	}, [block, model, orientation, meshRef.current]);

	useEffect(() => {
		if (!meshRef.current) return;
		meshRef.current.matrixWorldNeedsUpdate = true;
		meshRef.current.matrixAutoUpdate = true;
		meshRef.current.matrixWorldAutoUpdate = true;
		meshRef.current.updateMatrix();
		meshRef.current.updateMatrixWorld();
		meshRef.current.updateWorldMatrix(true, true);
		meshRef.current.matrixWorldAutoUpdate = false;
		meshRef.current.matrixAutoUpdate = false;
		meshRef.current.matrixWorldNeedsUpdate = false;
	}, [meshRef]);

	return (
		<>
			<mesh
				ref={meshRef}
				geometry={mesh || undefined}
				{...props}
				castShadow
				receiveShadow
				matrixAutoUpdate={false}
				matrixWorldAutoUpdate={false}
			>
				{children}
			</mesh>
		</>
	);
}

export default DeformedMesh;
