import { Instances } from "@react-three/drei";
import { Fragment, useMemo, useState } from "react";

import {
	getMesh,
	getTexture,
	useAssetStore,
} from "@/data/assetLoader/asset.store";
import type { Material } from "@/types";
import { PlantInstance } from "./plants";
import type { SimEntity } from "@game/sim/SimEntity";
import { CRenderer } from "@game/sim/components/CRenderer";

function Vegetation({ entities }: { entities: SimEntity[] }) {
	const meshes = useAssetStore((state) => state.meshes);
	const [limit] = useState(50000);

	const withPlants = entities.filter(
		(e) => e.hasTag("hasPlants") || e.hasTag("hasLowPlants"),
	);

	const { mat, mesh } = useMemo(() => {
		if (!meshes) return {};
		const plantsMesh = getMesh("jungleplants/jungleplants/jungle000");
		if (!plantsMesh) return {};

		const mat = plantsMesh.material as Material;
		const map = mat.map;
		const alphaMap = getTexture("jungleplants/plantas_dif_007_alpha");
		mat.transparent = true;
		mat.opacity = 1;
		Object.assign(mat, {
			map: map,
			metalnessMap: map,
			emissiveMap: map,
			alphaMap: alphaMap,
			fog: true,
		});
		mat.depthWrite = true;
		mat.needsUpdate = true;
		const mesh = plantsMesh.geometry;
		return { mat: mat, mesh: mesh };
	}, [meshes]);

	if (!mat || !mesh) return null;
	return (
		<>
			<Instances
				limit={limit}
				geometry={mesh}
				material={mat}
				castShadow
				receiveShadow
				frustumCulled={false}
				// layers={layers.renderOnly}
			>
				{withPlants.map((e, _idx) => {
					const r = e.component(CRenderer);
					if (!r || !r.block) return null;
					const block = r.block;
					return (
						<Fragment key={`${e.ref}${block.coordinate}`}>
							<PlantInstance block={block} entity={e} />
							{/* <VineInstance {...facility} /> */}
						</Fragment>
					);
				})}
			</Instances>
		</>
	);
}

export { Vegetation };
