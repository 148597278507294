import type { SimEntity } from "../SimEntity";
import type { ISimComponent } from "../interfaces/ISimComponent";

export class SimComponent implements ISimComponent {
	inst: SimEntity;

	constructor(inst: SimEntity) {
		this.inst = inst;
	}

	serialize(): string {
		return JSON.stringify(this);
	}
}
