import { InventoryStore } from "@game/ui/components/inventory/inventory.store";
import type { Block } from "@game/world/block";
import { WorldStore } from "@game/world/world.store";
import type { SimEntity } from "../SimEntity";
import { getActions } from "../actions/actions.fn";
import type { TAction } from "../actions/actions.types";
import { BufferedAction } from "../actions/bufferedAction";
import { CContainer } from "./CContainer";
import { SimComponent } from "./SimComponent";

export class CPlayerActionPicker extends SimComponent {
	sortActionList(
		_actions: TAction[],
		target?: SimEntity,
		useItem?: SimEntity,
	): BufferedAction[] {
		const actions = getActions(_actions);
		const ret: BufferedAction[] = [];
		if (actions.length > 0) {
			actions.sort((a, b) => {
				return (a.priority || 0) - (b.priority || 0);
			});
			for (const [_k, v] of Object.entries(actions)) {
				if (!target) {
					ret.push(
						new BufferedAction({
							self: this.inst,
							doer: this.inst,
							target: undefined,
							action: v,
							invObject: useItem,
						}),
					);
				} else if (target) {
					ret.push(
						new BufferedAction({
							self: this.inst,
							doer: this.inst,
							target: target,
							action: v,
							invObject: useItem,
						}),
					);
				}
			}
		}
		return ret;
	}

	getInventoryActions(useItem: SimEntity, alt = false): BufferedAction[] {
		const actions: TAction[] = [];
		if (useItem) {
			for (const c of useItem._components) {
				c.collectInventoryActions?.(this.inst, actions, alt);
			}
		}
		return this.sortActionList(actions, undefined, useItem);
	}

	getWorldActions(targetObject: SimEntity, alt = false): BufferedAction[] {
		const actions: TAction[] = [];

		for (const c of targetObject._components) {
			c.collectWorldActions?.(this.inst, actions, alt);
		}
		return this.sortActionList(actions, targetObject);
	}

	getBlockActions(
		_block: Block,
		targetObject: SimEntity,
		alt = false,
	): BufferedAction[] {
		const actions: TAction[] = [];

		for (const c of targetObject._components) {
			c.collectBlockActions?.(this.inst, targetObject, actions, alt);
		}
		return this.sortActionList(actions, targetObject);
	}

	getUseItemActions(
		target: SimEntity,
		useItem: SimEntity,
		alt = false,
	): BufferedAction[] {
		const actions: TAction[] = [];
		let sorted_acts: BufferedAction[] | null = null;
		if (useItem) {
			for (const c of useItem._components) {
				c.collectUseActions?.(this.inst, target, actions, alt);
			}
			sorted_acts = this.sortActionList(actions, target, useItem);
		}
		return sorted_acts || [];
	}

	// gets all available actions for LMB
	getClickActions({
		target_ent,
		pos,
	}: { target_ent?: SimEntity | null; pos?: Block | null }):
		| BufferedAction[]
		| null {
		let actions: BufferedAction[] | null = null;
		const useItem = InventoryStore().getActiveItem();

		if (useItem) {
			if (target_ent === this.inst) {
				// can't give to self, player has no avatar
			}

			if (actions === null) {
				if (target_ent) {
					actions = this.getUseItemActions(target_ent, useItem);
				} else if (pos) {
					actions = this.getBlockActions(pos, useItem);
				}
			}
		} else if (target_ent) {
			// if we're clicking on a scene object see if we can use equipped object on it.. or just use it
			actions = this.getWorldActions(target_ent);
		}
		return actions;
	}

	// gets all available actions for RMB
	getAltClickActions({
		target_ent,
		pos,
	}: { target_ent?: SimEntity | null; pos?: Block | null }):
		| BufferedAction[]
		| null {
		let actions: BufferedAction[] | null = null;
		const player = WorldStore().getPlayer();
		const useItem = InventoryStore().getActiveItem();

		if (useItem) {
			// useItem.isValid()
			if (target_ent === this.inst) {
				// can't give to self, player has no avatar
			}

			if (actions === null) {
				if (target_ent) {
					actions = this.getUseItemActions(target_ent, useItem, true);
				} else if (pos) {
					actions = this.getBlockActions(pos, useItem, true);
				}
			}
		} else if (target_ent) {
			// check if object is in player's inventory
			if (player?.component(CContainer).hasItem(target_ent)) {
				actions = this.getInventoryActions(target_ent, true);
			} else {
				// if we're clicking on a scene object see if we can use equipped object on it.. or just use it
				actions = this.getWorldActions(target_ent, true);
			}
		}
		return actions;
	}
}
