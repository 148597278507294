import type { TSeededRand } from "@/lib/random/seeded.functions";

type Color = {
	name: string;
	hex: string;
};

type Palette = {
	name: string;
	colors: Record<string, Color>;
};

type PaletteConfig = {
	[key: string]: Palette;
};

// Palette Data
export const DATA_PALETTES: PaletteConfig = {
	houses: {
		name: "houses",
		colors: {
			Bone: { name: "Bone", hex: "#e4d8c2" },
			"Red Ochre": { name: "Red Ochre", hex: "#c34522" },
			Goldenrod: { name: "Goldenrod", hex: "#fbc823" },
			Blackberries: { name: "Blackberries", hex: "#440075" },
			Carrot: { name: "Carrot", hex: "#f27d07" },
			Turqoise: { name: "Turqoise", hex: "#00a0d6" },
			"Lapis Lazuli": { name: "Lapis Lazuli", hex: "#0c2388" },
			Meteorite: { name: "Meteorite", hex: "#342d2d" },
			"Red Pepper": { name: "Red Pepper", hex: "#d60e00" },
			Spinach: { name: "Spinach", hex: "#035e1a" },
			Lilac: { name: "Lilac", hex: "#b589fb" },
			// "Radical Red": { name: "Radical Red", hex: "#f32e4d" },
			// "Violet Red": { name: "Violet Red", hex: "#fa489b" },
			// "Burning Orange": { name: "Burning Orange", hex: "#fb703e" },
			// "Turquoise Blue": { name: "Turquoise Blue", hex: "#A6C1A9" },
			// "Curious Blue": { name: "Curious Blue", hex: "#0299DD" },
			// "Dark Charcoal": { name: "Dark Charcoal", hex: "#333333" },
			// "Neon Carrot": { name: "Neon Carrot", hex: "#FE9064" },
			// "White Rock": { name: "White Rock", hex: "#e2e0c4" },
			// "Moon Mist": { name: "Moon Mist", hex: "#d9d5c9" },
		},
	},
	wires: {
		name: "wires",
		colors: {
			"Dark Charcoal": { name: "Dark Charcoal", hex: "#333333" },
			"Dim Gray": { name: "Dim Gray", hex: "#555555" },
			Matterhorn: { name: "Matterhorn", hex: "#442222" },
		},
	},
	grass: {
		name: "grass",
		colors: {
			"Green 1": { name: "Green 1", hex: "#9BD48A" },
			"Green 2": { name: "Green 2", hex: "#8FCD5F" },
			"Green 3": { name: "Green 3", hex: "#69CD1E" },
		},
	},
	jungle: {
		name: "jungle",
		colors: {
			"Jungle Green 1": { name: "Jungle Green 1", hex: "#b5e15f" },
			"Jungle Green 2": { name: "Jungle Green 2", hex: "#D2F292" },
			"Jungle Green 3": { name: "Jungle Green 3", hex: "#69CD1E" },
			"Jungle Green 4": { name: "Jungle Green 4", hex: "#4E8E1D" },
			"Jungle Green 5": { name: "Jungle Green 5", hex: "#3B6B1D" },
		},
	},
	logs: {
		name: "logs",
		colors: {
			Default: { name: "default", hex: "#555" },
			System: { name: "System Log", hex: "#f8b33e" },
			World: { name: "World Log", hex: "#1593d4" },
			Asset: { name: "Asset Log", hex: "#fb703e" },
			Game: { name: "Game Log", hex: "#71e6d4" },
			User: { name: "User Log", hex: "#fa489b" },
			Error: { name: "Error Log", hex: "#f32e4d" },
			Action: { name: "Action Log", hex: "#A8C7FA" },
			Entities: { name: "Entities Log", hex: "#f8b33e" },
			Blueprints: { name: "Blueprints Log", hex: "#1593d4" },
		},
	},
	hashes: {
		name: "hash",
		colors: {
			"Hash 1": { name: "Hash 1", hex: "#f8b33e" },
			"Hash 2": { name: "Hash 2", hex: "#1593d4" },
			"Hash 3": { name: "Hash 3", hex: "#fb703e" },
			"Hash 4": { name: "Hash 4", hex: "#71e6d4" },
			"Hash 6": { name: "Hash 6", hex: "#f32e4d" },
			"Hash 7": { name: "Jungle Green 3", hex: "#69CD1E" },
			"Hash 8": { name: "White Rock", hex: "#e2e0c4" },
			"Hash 9": { name: "White Rock", hex: "#666666" },
			"Hash 10": { name: "White Rock", hex: "#ffff22" },
		},
	},
	lights: {
		name: "lights",
		colors: {
			ambient: { name: "ambient", hex: "#BFB0E5" },
			sunlight: { name: "sunlight", hex: "#E9C7BA" },
			tint: { name: "tint", hex: "#B6D171" },
			rainfall: { name: "rainfall", hex: "#23347E" },
		},
	},
} as const;

type DataPalettes = typeof DATA_PALETTES;
type PaletteNames = keyof DataPalettes;

export const getColors = (paletteName: PaletteNames): Color[] => {
	return Object.values(DATA_PALETTES[paletteName].colors);
};

export const getUnsafeRandomColor = (paletteName: PaletteNames): Color => {
	const colors = getColors(paletteName);
	return colors[Math.floor(Math.random() * colors.length)];
};

export const getSafeRandomColor = (
	paletteName: PaletteNames,
	rng: TSeededRand,
): Color => {
	const colors = getColors(paletteName);
	return colors[Math.floor(rng(0, colors.length - 1))];
};

export const getColorByName = (
	paletteName: PaletteNames,
	colorName: string,
): Color | undefined => {
	return DATA_PALETTES[paletteName].colors[colorName];
};
