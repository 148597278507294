import Audio from "@game/audio/audioPlayer";
import { WorldStore } from "@game/world/world.store";
import { getSystemCalls } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";
import { CInventoryItem } from "./CInventoryItem";

export class CPlayer extends SimComponent {
	playerId = -1;

	setPlayerId(id: number) {
		this.playerId = id;
	}

	async dropItem(doer: SimEntity, item: SimEntity) {
		if (WorldStore().getPlayer() === doer) {
			const { action_drop } = getSystemCalls();
			Audio.playSound("PUT");
			await action_drop(doer.ref as Hex, item.ref as Hex);
			Audio.playSound("LEAVES");
			item.component(CInventoryItem).onDroppedFn?.(item);
		}
	}
}
