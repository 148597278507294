import { logVerbose } from "@/data/dev.store";
import type { TEntityTag } from "@/data/entity/entity.tags";
import { Debug } from "@/lib/debug/debug";
import { removeBlock } from "@game/world/block.store";
import { getMUDState, tables } from "@mud";
import mitt from "mitt";
import type { Hex } from "viem";
import type { ISimComponent } from "./interfaces/ISimComponent";
import { getEntityByRef, removeEntity } from "./sim.store";
import type { TComponentInstanceCtor, TSimPrefab } from "./types/sim.types";
import { CRenderer } from "./components/CRenderer";
import { UI_EVENTS } from "@game/ui/ui.events";

export type TSimEntityRef = string;

export class SimEntity {
	ref: string;
	name = "UNNAMED";
	getName = () => {
		return this.nameFn?.() || this.name;
	};
	nameFn: (() => string) | undefined = undefined;
	components: Record<string, ISimComponent> = {};
	_components: ISimComponent[] = [];
	tags: TEntityTag[] = [];
	_entityTypeID = -1;
	_parent: TSimEntityRef | null = null;
	parent = () => {
		return this._parent ? getEntityByRef(this._parent) : null;
	};
	setParent = (p: SimEntity) => {
		this._parent = p.ref;
		p.addChild(this);
		this.pushEvent("onParentChanged", { parent: p.ref });
	};
	_children: TSimEntityRef[] = [];
	children = () => {
		return this._children.map((c) => getEntityByRef(c));
	};
	addChild = (c: SimEntity) => {
		if (!this._children.includes(c.ref)) {
			this._children.push(c.ref);
			this.pushEvent("onChildAdded", { child: c.ref });
		}
	};
	removeChild = (c: SimEntity) => {
		this._children = this._children.filter((x) => x === c.ref);
		this.pushEvent("onChildRemoved", { child: c.ref });
	};
	prefab: TSimPrefab | null = null;
	private events = mitt();

	constructor(ref: string, typeID: number, name: string) {
		this.ref = ref;
		this._entityTypeID = typeID;
		this.name = name;
	}

	loadFromChain() {
		for (const c of this._components) {
			if (c.loadFromChain) {
				c.loadFromChain();
			}
		}
		const childToParent = getMUDState().getValue(tables.ChildToParent, {
			child: this.ref as Hex,
		});
		if (childToParent) {
			const parent = getEntityByRef(childToParent.parent);
			if (!parent)
				throw new Error(`no parent found for ref${childToParent.parent}`);
			this.setParent(parent);
		}
	}

	addComponent<T extends ISimComponent>(
		ctor: TComponentInstanceCtor<T>,
		...args: TComponentInstanceCtor<T>["arguments"]
	): T {
		if (this.components[ctor.name]) {
			throw new Error(
				`Component [${ctor.name}] already exists on this entity.`,
			);
		}
		const component = new ctor(this, ...args);
		this.components[ctor.name] = component;
		this._components.push(component);
		return component;
	}

	component<T extends ISimComponent>(ctor: TComponentInstanceCtor<T>): T {
		const component = this.components[ctor.name];
		return component as T;
	}

	remove(): void {
		logVerbose() &&
			Debug("World").log(`Removing entity ${this.name} ${this.ref}`);
		if (this.parent()) {
			this.parent()?.removeChild(this);
		}
		for (const [_k, v] of Object.entries(this.components)) {
			if (v.onRemoveEntity) {
				v.onRemoveEntity();
			}
		}

		for (const c of this.children()) {
			c?.remove();
		}

		// if (this.onRemoveEntity) {
		// 	this.onRemoveEntity(this);
		// }
		UI_EVENTS.emit("removeEntity", { inst: this });

		if (this.component(CRenderer)?.block) {
			const block = this.component(CRenderer).block;
			if (block) {
				removeBlock(block.coordinate);
			}
		}

		removeEntity(this);
	}

	serialize(): string {
		const components = Object.entries(this.components).map(
			([name, component]) => {
				return [name, component.serialize()] as [string, string];
			},
		);
		return JSON.stringify({ tags: this.tags, components });
	}

	addTag(tag: TEntityTag, ...tags: TEntityTag[]) {
		if (!this.tags.includes(tag)) this.tags.push(tag);
		if (tags) {
			for (const t of tags) {
				if (!this.tags.includes(t)) this.tags.push(t);
			}
		}
	}

	removeTag(tag: TEntityTag) {
		this.tags = this.tags.filter((t) => t !== tag);
	}

	hasTag(tag: TEntityTag): boolean {
		return this.tags.includes(tag);
	}

	isAsleep(): boolean {
		return this.hasTag("ASLEEP");
	}

	// Events

	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	pushEvent(event: string, data: Object) {
		this.events.emit(event, { inst: this, ...data });
	}

	listenForEvent(event: string, fn: (data: unknown) => void) {
		this.events.on(event, (e) => fn(e));
	}

	removeEventListener(event: string, fn: (data: unknown) => void) {
		this.events.off(event, (e) => fn(e));
	}

	getSeed(): number {
		const sub = this.ref.substring(this.ref.length - 15);
		const numericSeed = parseInt(sub, 16);
		return numericSeed;
	}
}
