import {
	type ContractWrite,
	createBurnerAccount,
	transportObserver,
} from "@latticexyz/common";
import { getBurnerPrivateKey } from "@latticexyz/common";
import { transactionQueue, writeObserver } from "@latticexyz/common/actions";
import type { MUDChain as _MUDChain } from "@latticexyz/common/chains";
import { encodeEntity } from "@latticexyz/store-sync/recs";
import { syncToZustand, type Table } from "@latticexyz/store-sync/zustand";
import mudConfig from "contracts/mud.config";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import worlds from "contracts/worlds.json";
import { Subject, share } from "rxjs";
import { type Middleware, trough } from "trough";
import {
	http,
	type ClientConfig,
	type FallbackTransport,
	type GetContractReturnType,
	type Hex,
	type PrivateKeyAccount,
	type PublicClient,
	type WalletClient,
	createPublicClient,
	createWalletClient,
	fallback,
	getContract,
	webSocket,
	type Chain,
	type Transport,
} from "viem";
import { setupFaucet } from "./mud.faucet";
import { supportedChains } from "./mud.supportedChains";

export type MUDChain = _MUDChain & Chain;

export type NetworkConfig = {
	chainId: number | string;
	chain: MUDChain;
	worldAddress: Hex;
	privateKey?: Hex;
	initialBlockNumber: bigint;
	faucetServiceUrl?: string;
	indexerServiceUrl?: string;
	clientOptions?: ClientConfig<
		FallbackTransport,
		MUDChain,
		PrivateKeyAccount,
		undefined
	>;
	burnerAccount?: PrivateKeyAccount;
	supportedChains?: MUDChain[];
};

export type NetworkSetupResult = {
	networkConfig: NetworkConfig | Partial<NetworkConfig>;
	publicClient: PublicClient<Transport, Chain>;
	walletClient: WalletClient<Transport, Chain>;
	write$: Subject<ContractWrite>;
	worldContract: GetContractReturnType<
		typeof IWorldAbi,
		WalletClient<FallbackTransport, MUDChain, PrivateKeyAccount, undefined>,
		Hex
	>;
	playerEntity: Hex | null;
	tables: Awaited<ReturnType<typeof syncToZustand<typeof mudConfig>>>["tables"];
	useMUDStore: Awaited<
		ReturnType<typeof syncToZustand<typeof mudConfig>>
	>["useStore"];
	getMUDStore: Awaited<
		ReturnType<typeof syncToZustand<typeof mudConfig>>
	>["useStore"]["getState"];
	latestBlock$: Awaited<
		ReturnType<typeof syncToZustand<typeof mudConfig>>
	>["latestBlock$"];
	storedBlockLogs$: Awaited<
		ReturnType<typeof syncToZustand<typeof mudConfig>>
	>["storedBlockLogs$"];
	waitForTransaction: Awaited<
		ReturnType<typeof syncToZustand<typeof mudConfig>>
	>["waitForTransaction"];
};

/**
 * @dev Example function for retrieving the environment variables for game client
 * @requires process.env values to be set
 * @returns Environment variables in `{ networkConfig }`
 */
export const exampleGetEnvClient = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	const networkConfig = {
		chainId: process.env.VITE_CHAIN_ID,
	};
	Object.assign(config.networkConfig, networkConfig);
	return config;
};

/**
 * @dev Example function for retrieving the environment variables for the game loop
 * @requires process.env values to be set
 * @returns Environment variables in `{ networkConfig }`
 */
export const exampleGetEnvGameloop = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	const networkConfig = {
		chainId: process.env.CHAIN_ID,
		worldAddress: process.env.WORLD_ADDRESS,
		privateKey: process.env.PRIVATE_KEY,
		// faucetServiceUrl: process.env.FAUCET,
		// indexerServiceUrl: process.env.INDEXER,
	};
	Object.assign(config.networkConfig, networkConfig);
	return config;
};

/**
 * @dev Client query params defaults in the format [ENV_VAR, PARAM_NAME]
 * @self Review if this is the ideal way to set this up, maybe users want to customize this
 */
const clientParams: Array<[keyof NetworkConfig, string]> = [
	["chainId", "chain"],
	["worldAddress", "world"],
	["indexerServiceUrl", "indexer"],
	["faucetServiceUrl", "faucet"],
	["initialBlockNumber", "initialBlockNumber"],
] as const as Array<[keyof NetworkConfig, string]>;

export const addSupportedChains = (supportedChains: MUDChain[]) => {
	return (config: NetworkSetupResult) => {
		const { supportedChains: _supportedChains } = config.networkConfig;
		Object.assign(config.networkConfig, {
			supportedChains: [...(_supportedChains || []), ...supportedChains],
		});
		return config;
	};
};

/**
 * @dev Retrieves location URLSearchParams and adds them to the network config
 * @require window to be available, only available in a browser environment
 * @returns Sets available query params in `{ networkConfig }`
 */
export const getQueryParamsClient = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	if (typeof window !== "undefined") {
		const queryParams = new URLSearchParams(window.location.search);
		const networkConfig: Partial<NetworkConfig> = {};
		const getParam = <T extends keyof NetworkConfig>(
			name: T,
			param: string,
		) => {
			const value = queryParams.get(param) as NetworkConfig[T] | undefined;
			if (value) networkConfig[name] = value;
		};
		clientParams.forEach(([name, param]) => getParam(name, param));
		Object.assign(config.networkConfig, networkConfig);
		return config;
	}
	throw new Error("Query params are only available in a browser");
};

/**
 * @dev Retrieves the Chain from `supportedChains` by chainId
 * @requires chainId to be set in `config.networkConfig`
 * @returns `{ networkConfig.chain }`
 */
export const getChain = (config: NetworkSetupResult): NetworkSetupResult => {
	const { chainId } = config.networkConfig;
	if (!chainId) throw new Error("Missing chainId in `config.networkConfig`");
	const chain = supportedChains.find(
		(c) => c.id.toString() === chainId.toString(),
	) as MUDChain;
	if (chain) {
		Object.assign(config.networkConfig, { chain });
		return config;
	}
	throw new Error(
		`Chain '${chainId}' not found. You may have to add it using \`addSupportedChains\`.`,
	);
};

/**
 * @dev Retrieves the World from the `contracts/worlds.json` by worldAddress using `chainId` in the config
 * @requires chainId to be set in `config.networkConfig`
 * @returns `{ networkConfig: { worldAddress, initialBlockNumber } }`
 */
export const getWorldConfig = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	const { chainId } = config.networkConfig;
	if (!chainId) throw new Error("Missing chainId");
	const world = worlds[chainId.toString()];
	if (!world)
		throw new Error(
			`No deployed world found for chain ${chainId}.  Did you run \`mud deploy\`?`,
		);
	Object.assign(config.networkConfig, {
		worldAddress: world.address,
		initialBlockNumber: world.blockNumber,
	});
	return config;
};

/**
 * @dev Setup up a Viem client using the `chain` and from `config`
 * @requires chain to be set in `config.networkConfig`
 * @returns `{ networkConfig.clientOptions, publicClient }`
 */
export const getViemClient = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	const { chain } = config.networkConfig;
	if (!chain) throw new Error("Missing chain");
	const clientOptions = {
		chain: chain,
		// FIXME: websocket should be first, but is currenly a bug in how Viem deals with emitOnBegin on websocket
		transport: transportObserver(fallback([webSocket(), http()])),
		emitOnBegin: true,
		poll: true,
		pollingInterval: 1000,
	} as const satisfies ClientConfig;

	const publicClient = createPublicClient(clientOptions);
	Object.assign(config.networkConfig, { clientOptions });
	Object.assign(config, { publicClient });
	return config;
};

/**
 * @dev Setup up a Burner account using the `clientOptions` and from `config`, defaults to creating a burner wallet with `getBurnerPrivateKey()` when missing
 * @requires clientOptions to be set in `config.networkConfig`
 * @returns `{ networkConfig.burnerAccount, walletClient, write$ }`
 */
export const getBurnerAccount = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	const { privateKey: _privateKey, clientOptions } = config.networkConfig;
	if (!clientOptions) throw new Error("Requires privateKey and clientOptions");
	if (!_privateKey && typeof window === "undefined") {
		throw new Error(
			"Usage of `getBurnerAccount` outside the browser is only available when `privateKey` is set in `config.networkConfig`",
		);
	}
	const privateKey = _privateKey || getBurnerPrivateKey();
	const burnerAccount = createBurnerAccount(privateKey as Hex);
	const burnerWalletClient = createWalletClient({
		...clientOptions,
		account: burnerAccount,
	})
		.extend(transactionQueue())
		.extend(writeObserver({ onWrite: (write) => write$.next(write) }));
	const write$ = new Subject<ContractWrite>();
	Object.assign(config.networkConfig, {
		burnerAccount,
	});
	Object.assign(config, {
		walletClient: burnerWalletClient,
		write$: write$.asObservable().pipe(share()),
	});
	return config;
};

/**
 * @dev Creates the world contract client using the `publicClient` and from `config`
 * @requires worldAddress to be set in `config.networkConfig`
 * @requires publicClient to be set in `config`
 * @param walletClient if `walletClient` is available contract writes are enabled
 * @returns `{ worldContract }`
 */
export const getWorldContract = async (
	config: NetworkSetupResult,
): Promise<NetworkSetupResult> => {
	const { publicClient } = config;
	if (!publicClient) throw new Error("Missing publicClient");
	const { worldAddress } = config.networkConfig;
	if (!worldAddress) throw new Error("Missing worldAddress");
	const client = {
		public: publicClient,
		wallet: config.walletClient || undefined,
	};
	const worldContract = await getContract({
		address: worldAddress as Hex,
		abi: IWorldAbi,
		client,
	});
	Object.assign(config, { worldContract });
	return config;
};

/**
 * @dev Creates the Zustand synced store for the world
 * @requires worldAddress to be set in `config.networkConfig`
 * @requires publicClient to be set in `config`
 * @returns `{ tables, useMUDStore, getMUDStore, latestBlock$, storedBlockLogs$, waitForTransaction }`
 */
export const getZustandStore = async (
	config: NetworkSetupResult,
): Promise<NetworkSetupResult> => {
	const { publicClient, networkConfig } = config;
	if (!publicClient) throw new Error("Missing publicClient");
	const { worldAddress, initialBlockNumber, indexerServiceUrl } = networkConfig;
	if (!worldAddress) throw new Error("Missing worldAddress");
	const {
		tables,
		useStore,
		latestBlock$,
		storedBlockLogs$,
		waitForTransaction,
	} = await syncToZustand({
		config: mudConfig,
		address: worldAddress as Hex,
		publicClient,
		startBlock: BigInt(initialBlockNumber || 0n),
		indexerUrl: indexerServiceUrl || undefined,
	});
	Object.assign(config, {
		tables,
		useMUDStore: useStore,
		getMUDStore: useStore.getState,
		latestBlock$,
		storedBlockLogs$,
		waitForTransaction,
	});
	return config;
};

/**
 * @dev Retrieve the playerEntity from the walletClient
 * @requires walletClient to be set in `config`
 * @returns `{ playerEntity }`
 */
export const getPlayerEntity = (
	config: NetworkSetupResult,
): NetworkSetupResult => {
	if (!config.walletClient?.account) throw new Error("No walletClient");
	const playerEntity = encodeEntity(
		{ address: "address" },
		{ address: config.walletClient.account.address },
	);
	Object.assign(config, { playerEntity });
	return config;
};

/**
 * @dev Hook up a faucet for the walletClient
 * @requires walletClient to be set in `config`
 * @requires faucetServiceUrl to be set in `config.networkConfig`
 */

export const getFaucet = async (
	config: NetworkSetupResult,
	options: MUDSetupOptions,
): Promise<NetworkSetupResult> => {
	if (!config.walletClient?.account) throw new Error("No walletClient");
	if (config.networkConfig.faucetServiceUrl) {
		const address = config.walletClient.account.address;
		options.output?.(`Player address: ${address}`);
		setupFaucet(
			address,
			config.networkConfig.faucetServiceUrl,
			config.publicClient,
			{
				output: options.output,
				verbose: true,
				walletClient: config.walletClient,
			},
		);
	}
	return config;
};

export const defaultSetup: MUDSetupMiddleware[] = [
	getChain,
	getWorldConfig,
	getViemClient,
	getBurnerAccount,
	getWorldContract,
	getPlayerEntity,
	getZustandStore,
];

export const exampleClientSetup: MUDSetupMiddleware[] = [
	exampleGetEnvClient,
	getQueryParamsClient,
	...defaultSetup,
];

export type MUDSetupMiddleware = (
	config: NetworkSetupResult,
) => Promise<NetworkSetupResult> | NetworkSetupResult;

export type MUDSetupOptions = {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	output?: (...args: any[]) => void;
};

/**
 * Setup MUD using middleware style plugins to flexibly extend and re-use the setup process
 * @param plugins - Array of MUD Setup middleware functions
 * @param config - Optional initial config object
 *
 * @example
 * ```ts
 * const config = await setupMUD([
 *		getChain,
 *		getWorldConfig,
 *		getViemClient,
 *		getBurnerAccount,
 *		getWorldContract,
 *		getPlayerEntity,
 *		getZustandStore,
 *	], { networkConfig: { chainId: 31337 } });
 * ```
 */
export const setupMUD = async (
	plugins: MUDSetupMiddleware[],
	config?: Partial<NetworkConfig> | undefined,
	options?: MUDSetupOptions,
): Promise<NetworkSetupResult> => {
	return await new Promise((resolve, reject) => {
		const completeSetup = (error: never, result: NetworkSetupResult) => {
			try {
				if (error) throw error;
				resolve(result);
			} catch (e) {
				reject(e);
			}
		};
		const setupChain = trough();
		plugins.forEach((plugin) => {
			setupChain.use(plugin as unknown as Middleware);
		});
		// @dev accept initial config object or empty
		setupChain.run(
			config ? { networkConfig: config } : { networkConfig: {} },
			options || {},
			completeSetup,
		);
	});
};

export const additionalMUDSetup = async (
	plugins: MUDSetupMiddleware[],
	result: NetworkSetupResult,
	options?: MUDSetupOptions,
): Promise<NetworkSetupResult> => {
	return await new Promise((resolve, reject) => {
		const completeSetup = (error: never, result: NetworkSetupResult) => {
			try {
				if (error) throw error;
				resolve(result);
			} catch (e) {
				reject(e);
			}
		};
		const setupChain = trough();
		plugins.forEach((plugin) => {
			setupChain.use(plugin as unknown as Middleware);
		});
		console.log(result);
		// @dev accept initial config object or empty
		setupChain.run(result, options || {}, completeSetup);
	});
};
