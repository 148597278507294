import type { Object3D } from "three";
import { QuarksLoader } from "three.quarks";

const loader = new QuarksLoader();

const PARTICLE_DATA: { readonly [id: string]: TParticle } = {
	leaves: {
		fileName: "/particles/leaves.json",
	} as TParticle,
	pickup: {
		fileName: "/particles/pickup.json",
	} as TParticle,
	rainfall: {
		fileName: "/particles/rainfall.json",
	} as TParticle,
};

export type TParticleData = typeof PARTICLE_DATA;
export type TParticle = {
	fileName: string;
	object?: Object3D;
};

export const loadParticle = async (name: string): Promise<Object3D> => {
	return await new Promise((resolve) => {
		loader
			.loadAsync(name)
			.then((obj) => {
				resolve(obj);
			})
			.catch((e) => {
				console.error(`Error preloading particle effect ${name}`, e);
			});
	});
};

export const loadParticles = async () => {
	const loadPromises = Object.entries(PARTICLE_DATA).map(
		async ([key, data]) => {
			try {
				const object = await loadParticle(`${data.fileName}`);
				PARTICLE_DATA[key].object = object;
			} catch (error) {
				console.error(`Failed to load particle ${key}:`, error);
			}
		},
	);

	await Promise.all(loadPromises);
};

export const getParticleData = (name: keyof TParticleData) => {
	return PARTICLE_DATA[name];
};
