import { Image } from "@react-three/drei";

interface PreloadImagesProps {
  imageUrls: string[];
}

export const ImagePreloader: React.FC<PreloadImagesProps> = ({ imageUrls }) => {
  return (
    <group visible={false}>
      {imageUrls.map((i, _idx) => {
        return <Image key={`pre${i}`} url={i} visible={false} />;
      })}
    </group>
  );
};
