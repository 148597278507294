import Audio from "@game/audio/audioPlayer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUpdate } from "@game/sim/components/CUpdate";
import { CUsable } from "@game/sim/components/CUsable";
import { WorldGlobals } from "@game/sim/sim.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemStormBottle extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("shovel");
		e.addComponent(CUsable)
			.setCanUseFn(this.canUseFn.bind(this))
			.setUseFn(this.useFn.bind(this));
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
	}

	canUseFn = (_doer: SimEntity, target: SimEntity) => {
		if (target === this.inst) {
			return true;
		}
		return false;
	};

	useFn = async (_doer: SimEntity, _target: SimEntity) => {
		Audio.playSound("STORM", { volume: 3 });
		WorldGlobals().set({ stormTimer: WorldGlobals().stormTimer + 300 });
	};

	update = () => {
		if (WorldGlobals().stormTimer >= 0) {
			WorldGlobals().set({ stormTimer: WorldGlobals().stormTimer - 1 });
		}
	};
}

const entityData: TEntityDefinition = {
	name: "Storm Bottle",
	simEntityPrefab: ItemStormBottle,
	id: 50003,
	metadata: {
		icon: "storm_bottle.webp",
		price: 204,
	},
} as const;

registerEntity(entityData);
