import { ASSET_TOTAL } from "@/data/assetLoader/asset.data";
import { AssetStore, useAssetStore } from "@/data/assetLoader/asset.store";
import { cn } from "@/lib/utils";
import { theSim } from "@game/sim/sim.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { SyncStep } from "@latticexyz/store-sync";
import { useMUDStore } from "@mud";
import { NProgress } from "@tanem/react-nprogress";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./loadingScreen.css";
import "./clouds.css";
import { setUIState, UIStates } from "@game/ui/ui.states";

function LoadingScreen() {
	const { loadingComplete } = useUIStore((state) => state);
	const { showLoadingScreen } = useUIStore((state) => state.uiState);
	const [loading, setLoading] = useState(true);
	const assetsLoaded = useAssetStore((state) => state.loaded);
	const syncProgress = useMUDStore((state) => state.syncProgress);

	useEffect(() => {
		if (syncProgress.step === SyncStep.SNAPSHOT) return;
		const threeProgress = (assetsLoaded / ASSET_TOTAL) * 100;
		const percentage = (syncProgress.percentage + threeProgress) / 2;
		if (percentage >= 100) {
			if (!UIStore().loadingComplete) {
				UIStore().set({
					loadingComplete: true,
				});
			}
			theSim.emit("loadingComplete", { lfg: true });
		}
	}, [syncProgress, assetsLoaded]);

	useEffect(() => {
		const disable = () => {
			setUIState(UIStates.mainMenu);
		};

		theSim.on("startGame", disable);
		return () => {
			theSim.off("startGame", disable);
		};
	}, []);

	useEffect(() => {
		if (!loadingComplete) return;
		setLoading(false);
		setTimeout(() => {
			theSim.emit("startGame");
		}, 1000);
	}, [loadingComplete]);

	return (
		<AnimatePresence>
			{showLoadingScreen && (
				<motion.div
					initial={{ opacity: 1 }}
					exit={{
						opacity: 0,
						transition: { ease: "easeIn", duration: 2.5 },
					}}
					transition={{ duration: 0.25 }}
					className={cn(
						"loading-wrapper fullscreen bg-[#96c2c2]",
						!loading && "pointer-events-none",
						!showLoadingScreen && "pointer-events-none",
					)}
				>
					<div className={cn("fullscreen")}>
						<div className={cn("fullscreen loading-background bg-[#96c2c2]")} />
						<div
							className={cn(
								"fullscreen loading-background mix-blend-screen opacity-30 clouds-image-00 bg-repeat bg-center bg-cover",
							)}
							style={{
								animation:
									"backgroundImageScroll 15s ease-in-out infinite alternate",
							}}
						/>
						<div
							className={cn(
								"fullscreen loading-background mix-blend-screen opacity-30 clouds-image-01 bg-repeat bg-center bg-cover",
							)}
							style={{
								animation:
									"backgroundImageScrollSize 48s linear infinite alternate",
							}}
						/>
						<div
							className={cn(
								"fullscreen loading-background mix-blend-screen opacity-5 clouds-image-03 bg-repeat bg-center bg-cover",
							)}
							style={{
								animation:
									"backgroundImageScrollSize 88s linear infinite alternate",
							}}
						/>
						<div className="font-anton tracking-wide fixed bottom-0 w-screen">
							<NProgress isAnimating>
								{({ progress: prog }) => {
									const threeProgress =
										(AssetStore().loaded / ASSET_TOTAL) * 100 || 0;
									const percentage =
										(syncProgress.percentage + threeProgress + prog) / 2;
									return (
										<div
											className={cn(
												"loading-bar mx-auto mt-1 block h-2.5 bg-white",
											)}
											style={{ width: `${Math.min(100, percentage)}%` }}
										/>
									);
								}}
							</NProgress>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default LoadingScreen;
