import { baseMesh, getMesh, getTexture } from "@/data/assetLoader/asset.store";
import {
	ColorMaskMaterial,
	type MaskMaterial,
} from "@/lib/materials/meshColorMaskMaterial";
import { randomFromArray, seededRand } from "@/lib/random/seeded.functions";
import { FogOutlines } from "@/lib/shaders/outline";
import { ConstructableInput } from "@game/components/constructableInput";
import DeformedMesh from "@game/components/deformModel";
import { Wires } from "@game/components/wires";
import { CPaint } from "@game/sim/components/CPaint";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { tables, useMUDStore } from "@mud";
import { useMemo } from "react";
import type { Hex } from "viem";

const m = baseMesh("modular_set/modular_set");

export const meshes = [
	// "building001",
	// "building002",
	// "building005",
	// "building003",
	// "building006",
	// "building008",
	// "building009",
	// "building007",
	// "building004",
	m("building010"),
	m("building011"),
];
const masks = ["modular_set/GradientTexture_mask_512"];

export function HouseRenderer({ entity }: { entity: SimEntity }) {
	const variantData = useMUDStore((state) =>
		state.getValue(tables.Variant, { inst: entity.ref as Hex }),
	);

	const { block, model, pos, renderWires, rot } = useMemo(() => {
		const block = entity.component(CRenderer).block!;
		const rng = seededRand(block._seed);
		const model = getMesh(randomFromArray(meshes, rng));
		return {
			block,
			pos: block.position.toArray(),
			renderWires: !block.hasNeighbourDown(),
			rot: rng(0, 4),
			model,
		};
	}, [entity]);

	const material = useMemo(() => {
		const rng = seededRand(block._seed);
		const color = variantData?.color || 0;
		return {
			maskColor: CPaint.getPaint(color),
			map: getTexture("modular_set/GradientTexture_m_512"),
			// aoMap: getTexture("house/ao"),
			// metalnessMap: getTexture("house/metal"),
			// roughnessMap: getTexture("house/rough"),
			// normalMap: getTexture("house/normal"),
			colorMask: getTexture(randomFromArray(masks, rng)),
			transparent: false,
		} satisfies MaskMaterial;
	}, [block, variantData]);

	if (!block || !model) return;
	const renderer = useMemo(() => {
		return (
			<group>
				<ConstructableInput entity={entity} args={[1, 1, 1]} key={entity.ref} />
				<group position={[...pos]}>
					<DeformedMesh
						block={block}
						model={model}
						color="white"
						orientation={rot}
						matrixAutoUpdate={false}
						matrixWorldAutoUpdate={false}
						scale={[2, 2, 2]}
					>
						<ColorMaskMaterial material={material} />
						<FogOutlines
							thickness={1.4}
							color="#534646"
							opacity={0.8}
							transparent
							screenspace
						/>
					</DeformedMesh>
					{renderWires && <Wires block={block} />}
				</group>
			</group>
		);
	}, [block, model, material, pos, renderWires, rot, entity]);
	return <>{renderer}</>;
}
