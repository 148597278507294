import {
	type TBluePrint,
	type TConstraint,
	getBlueprintsByNames,
} from "@/data/blueprint/blueprint.data";
import { UIStore } from "@game/ui/ui.store";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";

export class CConstruction extends SimComponent {
	recipes: TBluePrint[] = [];
	contents: SimEntity[] = [];
	buildDirections: { [key in TConstraint]: boolean } = {
		TOP: true,
		SIDES: true,
		BOTTOM: true,
	};

	isDirectionBuildable(direction: TConstraint) {
		return this.buildDirections[direction];
	}

	private isIngredientInRecipes(ingredient: SimEntity) {
		return this.recipes.find(
			(recipe) =>
				(recipe.ingredients?.some((i) => i.name === ingredient.name) ||
					recipe.tools?.some((i) => i.name === ingredient.name)) &&
				(!recipe.upgradesFrom || recipe.upgradesFrom === this.inst.name),
		);
	}

	// // getMatchingRecipes(doer: SimEntity, item: SimEntity, origin: SimEntity) {
	// // 	return this.recipes.find((recipe) =>
	// // 	()

	// getRecipes(doer: SimEntity, item: SimEntity, origin: SimEntity) {
	// 	const recipe =
	// }

	getRecipeByIngredient(ingredient: SimEntity): TBluePrint | undefined {
		// first we check if the ingredient is in one of the blueprints
		const recipe = this.isIngredientInRecipes(ingredient);
		if (!recipe) return undefined;
		const side = UIStore().getBuildDirection();
		if (!side) return undefined;
		// check if we match the correct building side
		if (recipe.buildingSide && recipe.buildingSide !== side) return undefined;
		if (!this.isDirectionBuildable(side)) return undefined;
		return recipe;
	}

	setRecipes(...bluePrintNames: string[]) {
		this.recipes = getBlueprintsByNames(...bluePrintNames);
		return this;
	}

	setBuildDirections(directions: Partial<{ [key in TConstraint]: boolean }>) {
		this.buildDirections = { ...this.buildDirections, ...directions };
		return this;
	}

	async buildRecipe(doer: SimEntity, recipe: TBluePrint, item: SimEntity) {
		recipe.execute(doer, recipe, item, this.inst);
		recipe.onBuildFn?.();
	}
}
