import { logVerbose } from "@/data/dev.store";
import { Color, MeshStandardMaterial, type Texture } from "three";
import { ExtendedMaterial } from "three-extended-material/react";

export type MaskMaterial = {
	colorMask?: Texture;
	maskColor?: Color;
} & Partial<MeshStandardMaterial>;

function ColorMaskMaterial({
	material,
	transparent,
}: { material: MaskMaterial; transparent?: boolean }) {
	return (
		<ExtendedMaterial
			superMaterial={MeshStandardMaterial}
			extensions={[
				{
					name: "colormask",
					uniforms: {
						colorMask: null as Texture | null,
						maskColor: material.maskColor || new Color(0xffffff),
					},
					vertexShader: (shader: string) => {
						// console.log(shader);
						logVerbose() && console.log("ColorMaskMaterial");
						return shader;
					},
					fragmentShader: (shader: string) => {
						const patchedShader = `
            #define USE_MAP
            uniform sampler2D colorMask;
            uniform vec3 maskColor;
      
            ${shader.replace(
							"#include <opaque_fragment>",
							// inject color mask code, colorize outgoingLight calc by mask
							`
              float mask = texture2D(colorMask, vMapUv).r;
              outgoingLight = outgoingLight * (1. - mask) + outgoingLight * maskColor.xyz * mask;
              #include <opaque_fragment>
              `,
						)}
          `;
						return patchedShader;
					},
				},
			]}
			attach="material"
			{...material}
		/>
	);
}

export { ColorMaskMaterial };
