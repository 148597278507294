import type { SimEntity } from "@game/sim/SimEntity";
import { getEntityByRef } from "@game/sim/sim.store";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface IWorldStore {
	entities: SimEntity[];
	playerRef: string | undefined;
	worldRef: string | undefined;
	getPlayer: () => SimEntity | undefined;
	getWorld: () => SimEntity | undefined;
}

const useWorldStore = create<IWorldStore>()(
	immer((_set, get) => ({
		entities: [],
		playerRef: undefined,
		worldRef: undefined,
		getPlayer: () => {
			const ref = get().playerRef;
			if (!ref) return undefined;
			return getEntityByRef(get().playerRef!);
		},
		getWorld: () => {
			const ref = get().worldRef;
			if (!ref) return undefined;
			return getEntityByRef(get().worldRef!);
		},
	})),
);

const WorldStore = () => ({
	...useWorldStore.getState(),
	set: useWorldStore.setState,
});

export { WorldStore, useWorldStore };
