import { getHex } from "@/lib/format.utils";
import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { getEntityByRef } from "../sim.store";
import { SimComponent } from "./SimComponent";
import { CInventoryItem } from "./CInventoryItem";
import { CStackable } from "./CStackable";

export class CContainer extends SimComponent {
	slots: Record<number, SimEntity | null> = {};
	numSlots = 0;
	type: "chest" | "inventory" = "chest";
	acceptsStacks = true;

	constructor(inst: SimEntity) {
		super(inst);
		for (let i = 0; i < this.numSlots; i++) {
			this.slots[i] = null;
		}
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Container, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		const oldSlots = this.slots;
		this.slots = {};
		this.setNumSlots(value.numSlots);
		let changed = false;

		for (let i = 0; i < value.slots.length; i++) {
			if (value.slots[i] === getHex(0)) continue;
			const item = getEntityByRef(value.slots[i]);
			if (!item) {
				throw new Error(`item not found ${value.slots[i]}`);
			}
			this.slots[i] = item;
			if (oldSlots[i]?.ref !== item.ref) {
				changed = true;
			}
		}
		if (changed) this.inst.pushEvent("onSlotsChanged", {});
	}

	numItems() {
		let num = 0;
		for (const slot in this.slots) {
			if (this.slots[slot]) {
				num++;
			}
		}
		return num;
	}

	isFull() {
		return this.numItems() >= this.numSlots;
	}

	allStacksFull() {
		for (const slot in this.slots) {
			if (this.slots[slot]?.component(CStackable)) {
				if (!this.slots[slot].component(CStackable).isFull()) {
					return false;
				}
			}
		}
		return true;
	}

	isEmpty() {
		for (const slot in this.slots) {
			if (this.slots[slot]) {
				return false;
			}
		}
		return true;
	}

	setNumSlots(num: number) {
		console.assert(num >= this.numSlots, "can't reduce number of slots");
		this.numSlots = num;
		for (let i = 0; i < this.numSlots; i++) {
			if (!this.slots[i]) this.slots[i] = null;
		}
	}

	hasItemInSlot(slot: number) {
		console.trace(slot, this.slots[slot], this.slots[slot] !== null);
		return this.slots[slot] !== null;
	}

	canTakeItemInSlot(item: SimEntity, slot?: number | undefined) {
		if (
			!item ||
			!item.component(CInventoryItem) ||
			!item.component(CInventoryItem).canGoInContainer
		) {
			return false;
		}
		if (slot === undefined) {
			return true;
		}
		if (slot !== undefined && slot > this.numSlots) {
			return false;
		}

		// perhaps we can stack it?
		if (slot !== undefined && this.hasItemInSlot(slot)) {
			if (
				item.component(CStackable) &&
				this.slots[slot]?.component(CStackable)
			) {
				// check compatible
				const stackable = item.component(CStackable);
				const otherStackable = this.slots[slot]?.component(CStackable);
				if (
					stackable.inst.prefab === otherStackable?.inst.prefab &&
					!(otherStackable.isFull() || stackable.isFull())
				) {
					console.log("woop", otherStackable.isFull(), stackable.isFull());
					return true;
				}
			}

			//unfortunately
			return false;
		}

		// console.warn("canTakeItemInSlot, slot item testfn not implemented");
		// if (this.itemTestFn) {
		//   return this.itemTestFn(item, slot);
		// }

		return true;
	}

	hasItem(item: SimEntity) {
		for (const slot in this.slots) {
			if (this.slots[slot] === item) {
				return true;
			}
		}
		return false;
	}

	async takeItem(item: SimEntity, slot?: number | undefined): Promise<boolean> {
		const { containerTakeItem } = getSystemCalls();
		const s = slot || 255;
		await containerTakeItem(this.inst.ref, item.ref, s);
		return true;
	}

	swapSlots(slot1: number, slot2: number) {
		const { containerSwapSlots } = getSystemCalls();
		containerSwapSlots(this.inst.ref, slot1, slot2);
	}
}
