import { cn } from "@/lib/utils";

// function that makes an array a minimum of 2 lines
function makeArray2Lines(arr: string[]) {
	if (arr.length > 1) return arr;
	return [...arr, ""];
}

export function alternateFlexLines(sentence: string) {
	// if sentence contains dots, we use split on that instead:
	let splitter = " ";
	if (sentence.includes(".")) {
		splitter = ".";
	}
	return (
		<>
			{makeArray2Lines(sentence?.split(splitter)).map((line, idx) => (
				<div
					key={idx}
					className={cn(
						idx % 1 === 0 ? "justify-end self-end" : "justify-start self-start",
					)}
				>
					{line}
				</div>
			))}
		</>
	);
}
