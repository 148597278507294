import { APP_DATA, APP_PLAYTEST_ACCESS } from "@/data/app.data";
import { toBase36, toBase64, fromBase64 } from "./utils.hash";
import { ENV } from "./env";

// Function to generate a key and convert it to Base64
export async function generateKey({
	secret,
	time = 900,
}: { secret?: string; time?: number }): Promise<string> {
	// Get the current UTC timestamp in seconds
	const currentTime = Math.floor(Date.now() / 1000);

	// Create a payload based on current time and secret
	const payload = `${currentTime}:${secret || APP_PLAYTEST_ACCESS.accessCode}`;

	// Hash the payload using Web Crypto API (SHA-256)
	const hashBuffer = await crypto.subtle.digest(
		"SHA-256",
		new TextEncoder().encode(payload),
	);
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// Convert the first 8 bytes of the hash to hex and join them (for smaller key)
	const hashHex = hashArray
		.slice(0, 8)
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");

	// Convert the timestamp and validity period to Base36 to reduce their length
	const base36Time = toBase36(currentTime);
	const validity = time;

	// Concatenate hash, timestamp, and validity period
	const combinedKey = `${hashHex}:${base36Time}:${validity}`;
	console.log(combinedKey);
	let baseKey = toBase64(combinedKey);

	if (typeof window !== "undefined") {
		const encoded = `${window.location.search}&accessCode=${toBase64(combinedKey)}`;
		baseKey = toBase64(encoded);
	}

	// Return the Base64-encoded version of the combined key
	return `C=${baseKey}`;
}

// Function to validate the Base64-encoded key
export async function validateKey(
	key: string,
	options?: { secret?: string },
): Promise<boolean> {
	const secret = options?.secret || APP_PLAYTEST_ACCESS.accessCode;
	// Decode the Base64-encoded key
	const decodedKey = fromBase64(key);

	// Split the decoded key to extract the hash, timestamp (in Base36), and validity period (in Base36)
	const [hash, base36Timestamp, validity] = decodedKey.split(":");

	// Convert Base36 values back to numbers
	const timestamp = parseInt(base36Timestamp, 36);
	const validityInSeconds = parseInt(validity, 36);

	// Get the current time in seconds
	const currentTime = Math.floor(Date.now() / 1000);

	// Check if the key is expired
	console.log(currentTime, timestamp, validityInSeconds);
	if (currentTime > timestamp + validityInSeconds) {
		return false; // Key has expired
	}

	// Recreate the payload and hash it
	const payload = `${timestamp}:${secret}`;
	const hashBuffer = await crypto.subtle.digest(
		"SHA-256",
		new TextEncoder().encode(payload),
	);
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// Compare the first 8 bytes of the hash
	const validHash = hashArray
		.slice(0, 8)
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");

	// Check if the hash matches
	return validHash === hash;
}

if (typeof window !== "undefined" && ENV.DEV) {
	Object.assign(window, {
		generateKey: async (time = 900) => {
			const key = await generateKey({
				time,
			});
			console.log(key);
			console.log(`${window.location.origin}?${key}`);
			console.log(`${APP_DATA.url}?${key}`);
		},
		validateKey: async (key: string) =>
			console.log(await validateKey(key), key),
	});
}
