import type { GeneratorBlock } from "../generator/islandgenerator";

const scale = 128;

export const WorldGenerator = ({islands}:{islands:GeneratorBlock[]}) => {
  return (
    <group>
      {islands.map(({ position, entityType, coordinate }) => {
        const {x,z} = position!;
        const {y} = coordinate;
        return (
          <mesh
            key={`${x - scale / 4},${y},${z - scale / 4}`}
            position={[x - scale / 4, y/2, z - scale / 4]}
          >
            <boxGeometry args={[0.5, 0.5, 0.5]} />
            <meshStandardMaterial
              color={entityType === 2 ? "grey" : "green"}
              fog={false}
            />
          </mesh>
        );
      })}
    </group>
  );
};
