import type { MUDChain } from "@latticexyz/common/chains";
import { chainConfig } from "viem/op-stack";

export const redstoneHolesky = {
	...chainConfig,
	id: 17001,
	sourceId: 1, // Ethereum mainnet
	name: "Redstone",
	nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
	rpcUrls: {
		default: {
			http: ["https://rpc.holesky.redstone.xyz"],
			webSocket: ["wss://rpc.holesky.redstone.xyz/ws"],
		},
	},
	blockExplorers: {
		default: {
			name: "Blockscout",
			url: "https://explorer.holesky.redstone.xyz",
		},
	},
	contracts: {
		multicall3: {
			address: "0xca11bde05977b3631167028862be2a173976ca11",
		},
	},
} as const satisfies MUDChain;
