import { Vector2, Vector3 } from "three";

const HEXSIZE = 5;

type Cube = {
	q: number;
	r: number;
	s: number;
};

type Axial = {
	q: number;
	r: number;
};

export function cubeToAxial(cube: Cube) {
	const q = cube.q;
	const r = cube.r;
	return { q, r } as Axial;
}

export function axialToCube(hex: Axial) {
	const q = hex.q;
	const r = hex.r;
	const s = -q - r;
	return { q, r, s } as Cube;
}

export function cubeRound(frac: Cube) {
	let q = Math.round(frac.q);
	let r = Math.round(frac.r);
	let s = Math.round(frac.s);

	const q_diff = Math.abs(q - frac.q);
	const r_diff = Math.abs(r - frac.r);
	const s_diff = Math.abs(s - frac.s);

	if (q_diff > r_diff && q_diff > s_diff) {
		q = -r - s;
	} else if (r_diff > s_diff) {
		r = -q - s;
	} else {
		s = -q - r;
	}

	return { q, r, s } as Cube;
}

function axial_round(hex: Axial) {
	return cubeToAxial(cubeRound(axialToCube(hex)));
}

export function pixelToPointyHex(point: Vector2) {
	const q = ((Math.sqrt(3) / 3) * point.x - (1 / 3) * point.y) / HEXSIZE;
	const r = ((2 / 3) * point.y) / HEXSIZE;
	return axial_round({ q, r } as Axial);
}

export function hexToPixel(hex: Axial) {
	const x = HEXSIZE * (Math.sqrt(3) * hex.q + (Math.sqrt(3) / 2) * hex.r);
	const y = HEXSIZE * ((3 / 2) * hex.r);
	return new Vector2(x, y);
}

function getHexCorner(center: Vector2, size: number, i: number) {
	const angle_deg = 60 * i - 30;
	const angle_rad = (Math.PI / 180) * angle_deg;
	return new Vector2(
		center.x + size * Math.cos(angle_rad),
		center.y + size * Math.sin(angle_rad),
	);
}

export function generateGridPoints(offset = new Vector2(0, 0)) {
	const points = [];
	for (let i = 0; i < 6; i++) {
		points.push(getHexCorner(offset, HEXSIZE, i));
	}
	return points;
}

export function pointInTriangle(
	p: Vector3,
	p0: Vector2,
	p1: Vector2,
	p2: Vector2,
): boolean {
	const A =
		0.5 *
		(-p1.y * p2.x + p0.y * (-p1.x + p2.x) + p0.x * (p1.y - p2.y) + p1.x * p2.y);
	const sign = A < 0 ? -1 : 1;
	const s =
		(p0.y * p2.x - p0.x * p2.y + (p2.y - p0.y) * p.x + (p0.x - p2.x) * p.z) *
		sign;
	const t =
		(p0.x * p1.y - p0.y * p1.x + (p0.y - p1.y) * p.x + (p1.x - p0.x) * p.z) *
		sign;

	return s > 0 && t > 0 && s + t < 2 * A * sign;
}

export function isWithinHex(point: Vector3, hexCenter: Vector2): boolean {
	const hexCorners = [];
	for (let i = 0; i < 6; i++) {
		hexCorners.push(getHexCorner(hexCenter, HEXSIZE, i));
	}

	for (let i = 0; i < 6; i++) {
		const nextIndex = (i + 1) % 6;
		if (
			pointInTriangle(point, hexCenter, hexCorners[i], hexCorners[nextIndex])
		) {
			return true;
		}
	}
	return false;
}