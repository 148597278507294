import type { IAction } from "./actions.types";
import { ACTIONS, type TAction } from "./actions.types";

export const getAction = (action: TAction): IAction => ACTIONS[action];

export const getActions = (actions: TAction[]): IAction[] => {
	const ret: IAction[] = [];
	for (const a of actions) {
		ret.push(getAction(a));
	}
	return ret;
};
