import { unsafeRandomFromArray } from "@/lib/utils";

const base = "/audio/";
const interaction = "/audio/interaction/";
const music = "/audio/music/";

const PICKUP = [
	`${interaction}171646__fins__scale-e6.webm`,
	`${interaction}171645__fins__scale-f6.webm`,
	`${interaction}171643__fins__scale-h6.webm`,
	`${interaction}171640__fins__scale-c7.webm`,
	`${interaction}171639__fins__scale-d6.webm`,
];

const PUT = [
	`${interaction}transition1.wav`,
	`${interaction}transition2.wav`,
	`${interaction}transition3.wav`,
	`${interaction}transition4.wav`,
	`${interaction}transition5.wav`,
	`${interaction}transition6.wav`,
	`${interaction}transition7.wav`,
];

const EAT = [
	`${interaction}eat01.wav`,
	`${interaction}eat02.wav`,
	`${interaction}eat03.wav`,
	`${interaction}eat04.wav`,
	`${interaction}eat05.wav`,
	`${interaction}eat06.wav`,
	`${interaction}eat07.wav`,
	`${interaction}eat08.wav`,
	`${interaction}eat09.wav`,
	`${interaction}eat10.wav`,
];

const LEAVES = [
	`${interaction}leaves01.wav`,
	`${interaction}leaves02.wav`,
	`${interaction}leaves03.wav`,
	`${interaction}leaves04.wav`,
	`${interaction}leaves05.wav`,
	`${interaction}leaves06.wav`,
	`${interaction}leaves07.wav`,
	`${interaction}leaves08.wav`,
	`${interaction}leaves09.wav`,
	`${interaction}leaves10.wav`,
];

const TRANSMUTE = [
	`${interaction}pickup_stone_1.mp3`,
	`${interaction}pickup_stone_2.mp3`,
	`${interaction}pickup_stone_3.mp3`,
];

const PICKUP_LOCO = [
	`${interaction}pickup_generic_3.mp3`,
	`${interaction}pickup_generic_2.mp3`,
	`${interaction}pickup_generic_1.mp3`,
];

const FLESH_HIT = [
	`${interaction}flesh_hit_7.mp3`,
	`${interaction}flesh_hit_6.mp3`,
	`${interaction}flesh_hit_5.mp3`,
	`${interaction}flesh_hit_4.mp3`,
	`${interaction}flesh_hit_3.mp3`,
	`${interaction}flesh_hit_2.mp3`,
	`${interaction}flesh_hit_1.mp3`,
];

const PICKUP_GONG = [
	`${interaction}pickup_item.mp3`,
	`${interaction}pickup_item2.mp3`,
	`${interaction}pickup_item3.mp3`,
	`${interaction}pickup_item4.mp3`,
];

const PICKUP_WOOD = [
	`${interaction}pickup_wood_1.mp3`,
	`${interaction}pickup_wood_2.mp3`,
	`${interaction}pickup_wood_3.mp3`,
	`${interaction}pickup_wood_4.mp3`,
	`${interaction}pickup_wood_5.mp3`,
	`${interaction}pickup_wood_6.mp3`,
	`${interaction}pickup_wood_7.mp3`,
];

const PICKUP_LEAVE = [
	`${interaction}pickup_leave_1.mp3`,
	`${interaction}pickup_leave_2.mp3`,
	`${interaction}pickup_leave_3.mp3`,
	`${interaction}pickup_leave_4.mp3`,
	`${interaction}pickup_leave_5.mp3`,
];

const WOOD = [
	`${interaction}wood1.wav`,
	`${interaction}wood2.wav`,
	`${interaction}wood3.wav`,
	`${interaction}chop1.mp3`,
	`${interaction}chop2.mp3`,
	`${interaction}chop3.mp3`,
	`${interaction}chop4.mp3`,
];

const DRUM = [
	`${base}drum/BD2500.WAV`,
	`${base}drum/BD5000.WAV`,
	`${base}drum/CB.WAV`,
	`${base}drum/CH.WAV`,
	`${base}drum/CP.WAV`,
	`${base}drum/CY1010.WAV`,
	`${base}drum/HC00.WAV`,
	`${base}drum/LC25.WAV`,
	`${base}drum/LT00.WAV`,
	`${base}drum/OH25.WAV`,
	`${base}drum/SD0010.WAV`,
	`${base}drum/SD1000.WAV`,
];

const STORM = [
	`${base}storm/storm00.mp3`,
	`${base}storm/storm01.mp3`,
	`${base}storm/storm02.mp3`,
];

const MUSIC_INDIGO = [`${music}indigo.mp3`];
const MUSIC_BOOTUP = [
	`${music}bootup.mp3`,
	`${music}bootup2.mp3`,
	`${music}bootup3.mp3`,
	`${music}bootup4.mp3`,
	`${music}bootup5.mp3`,
	`${music}bootup6.mp3`,
	// `${music}bootup7.mp3`,
	`${music}bootup8.mp3`,
];
const MUSIC_STORMBIRD = [`${music}storm_bird.mp3`];

const pickedSounds: { [key: string]: string[] } = {};

let resetTimer: Timer | null = null;

const resetPickedSounds = () => {
	Object.keys(pickedSounds).forEach((key) => {
		pickedSounds[key] = [];
	});
};

const getNextSound = (arr: string[], key: string): string => {
	if (!pickedSounds[key]) {
		pickedSounds[key] = [];
	}

	let availableSounds = arr.filter(
		(sound) => !pickedSounds[key].includes(sound),
	);

	if (availableSounds.length === 0) {
		pickedSounds[key] = [];
		availableSounds = arr;
	}

	const nextSound = unsafeRandomFromArray(availableSounds);
	pickedSounds[key].push(nextSound);

	if (resetTimer) clearTimeout(resetTimer);
	resetTimer = setTimeout(resetPickedSounds, 40000);

	return nextSound;
};

export const nextSound = (data: keyof typeof AUDIO_DATA): string => {
	return getNextSound(AUDIO_DATA[data], data);
};

export const playIndex = (data: keyof typeof AUDIO_DATA, index: number) => {
	const idx = Math.floor(
		(index / AUDIO_DATA[data].length) % AUDIO_DATA[data].length,
	);
	const i = AUDIO_DATA[data][idx];
	console.log(
		i,
		index,
		idx,
		index % AUDIO_DATA[data].length,
		AUDIO_DATA[data].length,
	);
	return i;
};

export const AUDIO_DATA = {
	EAT,
	LEAVES,
	PICKUP,
	PUT,
	TRANSMUTE,
	PICKUP_LOCO,
	PICKUP_GONG,
	WOOD,
	MUSIC_INDIGO,
	MUSIC_BOOTUP,
	FLESH_HIT,
	PICKUP_WOOD,
	PICKUP_LEAVE,
	MUSIC_STORMBIRD,
	DRUM,
	STORM,
} as const;
