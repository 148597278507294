import { getMesh } from "@/data/assetLoader/asset.store";
import type { TRendererProps } from "@game/sim/components/CRenderer";
import { useMemo } from "react";

export const ItemShovelRenderer = ({
	entity,
	props: { pos, rot },
}: TRendererProps) => {
	const { mesh } = useMemo(() => {
		const mesh = getMesh("shovel000");
		return {
			mesh,
		};
	}, []);

	if (!mesh) return null;
	const renderer = useMemo(() => {
		return (
			<group position={pos} scale={[0.35, 0.35, 0.35]}>
				<mesh
					userData={{ entityRef: entity.ref }}
					visible={false}
					position={[0, 0.25, 0]}
				>
					<boxGeometry args={[0.5, 0.75, 0.5]} />
					<meshBasicMaterial color="magenta" />
				</mesh>
				<mesh
					geometry={mesh.geometry}
					material={mesh.material}
					rotation={[0, rot, 0]}
				/>
			</group>
		);
	}, [mesh, pos, rot, entity.ref]);
	return <>{renderer}</>;
};
