import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { UILayout } from "../ui.layout";
import { useUIStore } from "@game/ui/ui.store";
import { setUIState, UIStates } from "@game/ui/ui.states";
import { useTranslation } from "react-i18next";
import Audio from "@game/audio/audioPlayer";
import "./logo.css";

export default function GameMenu() {
	const { t } = useTranslation();
	const { showGameLogo, showSignupButton } = useUIStore(
		(state) => state.uiState,
	);

	const handleSignup = () => {
		Audio.playSound("PICKUP_GONG");
		Audio.playMusic("MUSIC_STORMBIRD");
		setUIState(UIStates.signUp);
	};

	return (
		<AnimatePresence>
			{showGameLogo && (
				<UILayout
					className="fullscreen pointer-events-auto"
					data-name="game-logo"
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.25 }}
						className={cn(
							"absolute mx-auto uppercase top-[67.5%] z-[10000] flex flex-col w-full items-center",
						)}
					>
						{/* <div className="relative w-[400px] h-[162px] aspect-auto logo-image bg-contain" /> */}
						<div
							data-src="cloudlines.png"
							className="relative max-w-[400px] h-[162px] aspect-[1/2.4691358] logo-image border-0 w-[80%] bg-contain bg-scale bg-no-repeat"
							// style={{ backgroundSize: "100% auto" }}
						/>
						{showSignupButton && (
							<motion.button
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ type: "spring", duration: 0.25 }}
								onClick={handleSignup}
								className="uppercase text-white font-extrabold bg-transparent mx-auto px-4 py-2 rounded-full active:scale-95 transition-all disabled:bg-slate-600 hover:scale-105 border-2 border-white/0 relative overflow-clip hover:border-white/5 transition-all hover:bg-white/5 font-berkeley"
							>
								<div className="trail" />
								{t("signupButton")}
							</motion.button>
						)}
					</motion.div>
				</UILayout>
			)}
		</AnimatePresence>
	);
}
