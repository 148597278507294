import { _ACTIONS, ActionsMap } from "./actions";
import type { BufferedAction } from "./bufferedAction";

type TActionFn = (act: BufferedAction) => Promise<boolean>;
type TActionStrFn = (act: BufferedAction) => string;

export interface IAction {
	priority?: number;
	fn: TActionFn;
	strFn?: TActionStrFn;
	buildAction?: boolean;
}

export const ACTIONS = _ACTIONS as ActionsMap;
export type TAction = keyof typeof ACTIONS;

