import { useEffect, useMemo } from "react";
import { getEntityMetadataByID } from "@/data/entity/entity.data.fn";
import { cn, roundToDecimal } from "@/lib/utils";
import { alternateFlexLines } from "./itemShop.fn";
import { ShopModalButton, ShopModal, ShopModalBlock } from "./itemShopModal";
import {
	type IItemShopProduct,
	ItemShopStore,
	useItemShopStore,
} from "./itemShop.store";
import { useTranslation } from "react-i18next";
import Audio from "@game/audio/audioPlayer";

export function ItemShopDetailPanel({
	product,
}: { product: IItemShopProduct }) {
	const { t } = useTranslation();
	const state = useItemShopStore((state) => state.state);

	const { name, description, subtitle, price, icon } = useMemo(() => {
		if (!product) return { price: 0 };
		const metadata = getEntityMetadataByID(product.entityTypeId);
		const name = product.name;
		return {
			name: t(`items.${name}.shop_name`) || "unknown",
			description: t(`items.${name}.description`) || "unknown",
			subtitle: t(`items.${name}.shop_subtitle`) || "unknown",
			price: metadata?.price || 0,
			icon: `/icons/${metadata?.icon ?? "unknown.webp"}`,
		};
	}, [product, t]);

	const { button } = useMemo(() => {
		if (state === "CONFIRM_PASS") {
			const onContinue = () => {
				Audio.playSound("PICKUP_GONG");
				ItemShopStore().set({ state: "BUY_GEAR" });
			};
			const button = (
				<>
					<ShopModalButton onClick={onContinue}>
						{t("continue")}
					</ShopModalButton>
				</>
			);
			return { button };
		}
		return { button: null };
	}, [state, t]);

	useEffect(() => {
		Audio.playSound("PUT");
	}, []);

	return (
		<ShopModal className="font-light overflow-clip rounded-lg receipt-style">
			{/* Top block with title and descriptions */}
			<ShopModalBlock className="pb-0 bg-transparent">
				<div className="text-5xl uppercase tracking-tighter flex-col flex z-10">
					{alternateFlexLines(name || "unknown")}
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="relative py-0 -mb-4 bg-transparent">
				<div
					className={cn(
						"absolute top-[-10%] left-[15%] w-[70%] h-[70%] bg-[#C0CBBD] z-0 transition-all duration-200",
					)}
				/>
				<div
					className="w-[80%] aspect-square overflow-auto z-10"
					style={{ background: `url(${icon}) no-repeat center/cover` }}
				/>
			</ShopModalBlock>
			<ShopModalBlock className="pt-0 z-10 bg-transparent">
				<div className="text-3xl uppercase tracking-tighter flex-col flex w-full leading-7">
					{alternateFlexLines(subtitle || "unknown")}
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="py-0 z-10 bg-transparent flex-grow h-full">
				<div className="flex-grow" />
				{description}
			</ShopModalBlock>
			<ShopModalBlock className="bg-transparent">
				<div className="self-end flex flex-row w-full">
					<div className="flex flex-row gap-2 flex-grow self-center text-[#256718]">
						<div className="font-bold text-xl">
							{price > 0
								? `${roundToDecimal(price * ItemShopStore().priceModifier, 3)} ${ItemShopStore().currency}`
								: "FREE"}
						</div>
					</div>
					{button}
				</div>
			</ShopModalBlock>
		</ShopModal>
	);
}
