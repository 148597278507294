import { CConstruction } from "@game/sim/components/CConstruction";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { ScaffoldRenderer } from "./scaffold.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import {
	registerBlueprint,
	type TBluePrint,
} from "@/data/blueprint/blueprint.data";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";
import type { Hex } from "viem";
import Audio from "@game/audio/audioPlayer";

export class ScaffoldPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(ScaffoldRenderer);
		e.addComponent(CConstruction).setRecipes(
			"Scaffold",
			"Farm",
			"SheetWall",
			"LimestoneWall",
		);
	}
}

const entityData: TEntityDefinition = {
	name: "Scaffold",
	simEntityPrefab: ScaffoldPrefab,
	id: 450,
};

const blueprint = {
	name: "Scaffold",
	ingredients: [{ amount: 1, name: "Bamboo" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	canBuildFn(doer, recipe, item, origin) {
		return true;
	},
	onBuildFn: () => {
		Audio.playSound("PUT", { volume: 0.125 });
	},
	execute: async (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT");
		const faceIndex = UIStore().faceIndex;
		const block = origin.component(CRenderer).block;
		if (faceIndex === -1) throw new Error("faceIndex not set");
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(block, faceIndex);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;
registerBlueprint(blueprint);

registerEntity(entityData);
