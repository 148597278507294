import { useAssetStore } from "@/data/assetLoader/asset.store";
import { logVerbose } from "@/data/dev.store";
import { Debug } from "@/lib/debug/debug";
import { useFrame } from "@react-three/fiber";
import { useMemo } from "react";
import { Vegetation } from "./components/vegetation";
import { ParticleSystem } from "./effects/particleSystem";
import { WorldStore, useWorldStore } from "./world/world.store";
import { CRenderer } from "./sim/components/CRenderer";
import { CUpdate } from "./sim/components/CUpdate";

let tick = 0;

export const GameLoop = () => {
	const loadingComplete = useAssetStore((state) => state.loadingComplete);
	const entities = useWorldStore((state) => state.entities);

	const renderEntites = useMemo(() => {
		return entities.map((p, _idx) => {
			if (!p || !p.component(CRenderer)) return null;
			const Renderer = p.component(CRenderer).renderer;
			const block = p.component(CRenderer).block;
			if (!Renderer || !block) return;
			return <Renderer entity={p} key={p.ref} />;
		});
	}, [entities]);

	useFrame((state) => {
		tick++;
		if (tick >= 33) {
			let updateCalls = 0;
			tick = 0;
			// console.countReset("growth");
			WorldStore().entities.forEach((e) => {
				if (e.component(CUpdate)) {
					e.component(CUpdate).update?.(state);
					e.pushEvent("onUpdate", {});
					updateCalls++;
				}
			});
			logVerbose() && Debug("Game").log(`Update calls: ${updateCalls}`);
		}
	});

	if (!loadingComplete) return null;
	return (
		<>
			{renderEntites}
			<Vegetation entities={entities.map((p) => p)} />
			<ParticleSystem />
		</>
	);
};
