import { SIGILS, type TSigilType } from "@/data/sigil.data";
import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { getEntityByRef } from "../sim.store";
import type { TInstFn } from "../types/sim.types";
import { CPlot } from "./CPlot";
import { SimComponent } from "./SimComponent";
import { getEntityMetadataByID } from "@/data/entity/entity.data.fn";
import { CContainer } from "./CContainer";
import { WorldStore } from "@game/world/world.store";

export class CInventoryItem extends SimComponent {
	// name = "";
	img: string | null = null;
	owner: SimEntity | null = null;
	canGoInContainer = true;
	onActiveItemFn: TInstFn | null = null;
	onDroppedFn: TInstFn | null = null;
	onPickupFn: TInstFn | null = null;
	sigil: string | null = null;

	constructor(inst: SimEntity) {
		super(inst);
		const entityMetadata = getEntityMetadataByID(inst._entityTypeID);
		this.img = entityMetadata?.icon || "unknown.webp";
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.InventoryItem, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		const prevOwner = this.owner;
		if (value.owner) {
			const owner = getEntityByRef(value.owner);
			if (owner !== prevOwner && owner === WorldStore().getPlayer()) {
				if (prevOwner === null) {
					this.onPickupFn?.(this.inst);
				}
			}
			if (!owner) {
				this.onDroppedFn?.(this.inst);
				return;
			}
			this.setOwner(owner);
		}
	}

	setImage(image: string) {
		this.img = image;
		return this;
	}

	setSigil(sigil: TSigilType) {
		this.sigil = `${SIGILS[sigil]}`;
		return this;
	}

	setOwner(owner: SimEntity) {
		this.owner = owner;
		return this;
	}

	clearOwner() {
		this.owner = null;
	}

	setOnDroppedFn(fn: TInstFn) {
		this.onDroppedFn = fn;
		return this;
	}

	setOnPickupFn(fn: TInstFn) {
		this.onPickupFn = fn;
		return this;
	}

	setOnActiveItemFn(fn: TInstFn) {
		this.onActiveItemFn = fn;
		return this;
	}

	isHeld() {
		return this.owner !== null;
	}

	isHeldBy(entity: SimEntity) {
		return this.owner === entity;
	}

	canBePickedUp() {
		return (
			!this.isHeld() || (this.isHeld() && !this.owner?.component(CContainer))
		);
	}

	removeFromOwner(_wholeStack: boolean) {
		if (this.owner) {
			this.owner.pushEvent("itemRemovedFromOwner", { item: this.inst });
		}
	}

	onPutInInventory(owner: SimEntity) {
		this.setOwner(owner);
		owner.addChild(this.inst);
	}

	onRemoved() {
		if (this.owner) {
			this.owner.removeChild(this.inst);
		}
		this.clearOwner();
	}

	onRemoveEntity() {
		this.removeFromOwner(true);
	}

	collectWorldActions(doer: SimEntity, actions: TAction[]) {
		if (
			this.canBePickedUp() &&
			doer.component(CContainer) &&
			this.owner?.component(CPlot)
		) {
			actions.push("PICKUP");
		}
	}
}
