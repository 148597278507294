import type { Object3D } from "three";
import type { ParticleEmitter, ParticleSystem } from "three.quarks";
import {
	type TParticleEffect,
	type TParticleEffectRef,
	ParticleStore,
} from "./particle.store";

/**
 * Creates a particle effect in the scene.
 *
 * @example
 * ```ts
 * const effect = await createParticleEffect({
 *   particle: getParticleData("leaves"),
 *   position: new Vector3(0, 0, 0),
 *   scale: new Vector3(0.25, 0.25, 0.25),
 *   lifetime: 3000,
 * });
 * ```
 *
 * @param {TParticleEffect} props - The particle effect properties
 * @param {Object3D} props.particle - The particle object to clone or load
 * @param {Object} props.position - The position to place the particle effect
 * @param {Object3D} props.scale - The scale to apply to the particle effect
 * @param {Object3D} props.rotation - The rotation to apply to the particle effect
 * @param {number} [props.lifetime=10000] - How long the particle effect should last before expiring
 *
 * @returns {Promise<TParticleEffectRef>} A promise that resolves to a reference to the particle effect
 *
 * @throws {Error} If the particle system is not initialized
 */
export const createParticleEffect = async ({
	particle,
	...props
}: TParticleEffect): Promise<TParticleEffectRef> => {
	const { loader, renderer, scene } = ParticleStore();
	if (!scene || !loader || !renderer) {
		console.log(scene, loader, renderer);
		throw new Error("Particle system not initialized");
	}
	let effect: TParticleEffectRef;
	return await new Promise((resolve) => {
		const processEmitters = (obj: Object3D) => {
			const p: ParticleSystem[] = [];
			obj.traverse((child) => {
				if (child.type === "ParticleEmitter") {
					renderer.addSystem((child as ParticleEmitter).system);
					p.push((child as ParticleEmitter).system as ParticleSystem);
				}
			});
			if (props.position) obj.position.copy(props.position);
			if (props.scale)
				obj.scale.set(props.scale.x, props.scale.y, props.scale.z);
			if (props.rotation) obj.rotation.copy(props.rotation);
			scene.add(obj);
			effect = {
				particles: p,
				particleEffect: obj,
				createdAt: new Date(),
				expiration: props.lifetime || 10000,
				onDestroy: props.onDestroy,
			};
			expireParticleEffect(effect);
			ParticleStore().addParticleEffect(effect);
			return effect;
		};

		if (particle.object) {
			resolve(processEmitters(particle.object.clone()));
		} else {
			loader.load(
				particle.fileName,
				(obj) => {
					processEmitters(obj);
					resolve(effect);
				},
				() => {},
				(error: unknown) => {
					console.error("Error loading particle effect", error);
				},
			);
		}
	});
};

/**
 * Schedules the expiration of a particle effect.
 *
 * @param {TParticleEffectRef} effect - The reference to the particle effect to schedule expiration for
 *
 * @remarks
 * This function sets a timeout to remove the particle effect from the store
 * when its expiration time has elapsed.
 *
 * If an existing timeout is present, it will be cleared before setting a new one.
 */
export const expireParticleEffect = (effect: TParticleEffectRef) => {
	// @dev we can use this to clean up manually if we want to
	// FIXME: use markForDestroy and autoDestroy
	if (!effect.timeout) Object.assign(effect, { timeout: null });
	if (effect.timeout) clearTimeout(effect.timeout);
	effect.timeout = setTimeout(() => {
		ParticleStore().removeParticleEffect(effect);
		if (effect.onDestroy) effect.onDestroy(effect);
	}, effect.expiration);
};
