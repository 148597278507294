import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { CPlot } from "./CPlot";
import { SimComponent } from "./SimComponent";

export class CAnimal extends SimComponent {
	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (target.component(CPlot)?.isEmpty()) {
			actions.push("PUT");
		}
	}

	collectWorldActions(_doer: SimEntity, actions: TAction[]) {
		actions.push("PET");
	}

	pet(doer: SimEntity) {
		console.log(`${doer.getName()} pets ${this.inst.getName()}`);
		this.inst.pushEvent("isPetted", {});
	}
}
