import type { HTMLAttributes, MouseEvent, PropsWithChildren } from "react";
import { UIStore } from "../ui.store";
import { cn } from "@/lib/utils";

export function UILayout({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"pointer-events-none fixed left-0 top-0 h-screen w-screen",
				props?.className,
			)}
			{...props}
		>
			{children}
		</div>
	);
}

export function ModalUILayout({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		event.preventDefault();
		if (!UIStore().isHoveringUI) return;
		UIStore().set({
			isHoveringUI: true,
		});
	};

	const handleMouseOut = () => {
		if (!UIStore().isHoveringUI) return;
		UIStore().set({
			isHoveringUI: false,
		});
	};

	return (
		<div
			className={cn(
				"pointer-events-auto fixed left-0 top-0 h-screen w-screen",
				props?.className,
			)}
			onMouseMove={handleMouseMove}
			onMouseOut={handleMouseOut}
			{...props}
		>
			{children}
		</div>
	);
}
