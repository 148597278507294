import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import type { BufferedAction } from "../actions/bufferedAction";
import { SimComponent } from "./SimComponent";

export class CUsable extends SimComponent {
	useFn: ((doer: SimEntity, target: SimEntity) => Promise<void>) | null = null;
	canUseFn: ((doer: SimEntity, target: SimEntity) => boolean) | null = null;
	useStrFn: ((act: BufferedAction) => string) | null = this.defaultUseStrFn;

	constructor(inst: SimEntity) {
		super(inst);
		this.canUseFn = () => true;
	}

	collectInventoryActions(_doer: SimEntity, actions: TAction[], alt?: boolean) {
		if (this.useFn !== null && alt) {
			if (this.canUseFn?.(_doer, this.inst) || !this.canUseFn) {
				actions.push("USE_ITEM");
			}
		}
	}

	collectUseActions(
		_doer: SimEntity,
		target: SimEntity,
		actions: TAction[],
		_alt?: boolean,
	) {
		if (this.useFn !== null) {
			if (this.canUseFn?.(_doer, target)) {
				actions.push("USE_ITEM");
			}
		}
	}

	defaultUseStrFn(act: BufferedAction) {
		return `Use ${
			act?.invObject?.getName() || act?.target?.getName() || "ERROR"
		}`;
	}

	setUseFn(fn: (doer: SimEntity, target: SimEntity) => Promise<void>) {
		this.useFn = fn;
		return this;
	}

	setCanUseFn(fn: (doer: SimEntity, target: SimEntity) => boolean) {
		this.canUseFn = fn;
		return this;
	}

	setUseStrFn(fn: (act: BufferedAction) => string) {
		this.useStrFn = fn;
		return this;
	}
}
