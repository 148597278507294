import { getMesh } from "@/data/assetLoader/asset.store";
import { randomFromArray, seededRand } from "@/lib/random/seeded.functions";
import { FogOutlines } from "@/lib/shaders/outline";
import { ConstructableInput } from "@game/components/constructableInput";
import DeformedMesh from "@game/components/deformModel";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import type { MeshBasicMaterialProps } from "@react-three/fiber";
import { useMemo } from "react";
import { Vector3 } from "three";

const modelPath = "rocks/rocks";

const meshes = [`${modelPath}/rock000`];
const bottom = [
	`${modelPath}/RockBottom000`,
	`${modelPath}/RockBottom001`,
	`${modelPath}/RockBottom002`,
];

const rockColor = "#534746";

export function RockRenderer({ entity }: { entity: SimEntity }) {
	const {
		pos,
		model,
		blockBelow,
		block,
		rockBottom,
		rot,
		material,
		orientation,
	} = useMemo(() => {
		const block = entity.component(CRenderer).block!;
		const rng = seededRand(block._seed);
		const rockBottom = getMesh(randomFromArray(bottom, rng));
		const p = block.position;
		const model = getMesh(randomFromArray(meshes, rng));
		const o = rng(0, 4);
		if (!model) return {};
		const mat = model.material as MeshBasicMaterialProps;
		return {
			pos: p,
			model,
			material: mat,
			blockBelow: !block.hasNeighbourDown(),
			block,
			rockBottom,
			rot: new Vector3(0, (rng(0, 4) * Math.PI) / 4, 0).toArray(),
			orientation: o,
		};
	}, [entity]);

	const { children } = useMemo(() => {
		if (block?.hasNeighbourUp()) return { children: [] };
		const children = entity.children();
		if (children.length > 0) {
			return { children };
		}
		return { children: [] };
	}, [entity, block]);

	if (!model) return null;
	const renderer = useMemo(() => {
		return (
			<>
				<DeformedMesh
					block={block}
					model={model}
					color={rockColor}
					position={[0, 0.005, 0]}
					rotation={[0, 0, 0]}
					scale={[1.125, 1.05, 1.125]}
					orientation={orientation}
					matrixAutoUpdate={false}
					matrixWorldAutoUpdate={false}
				>
					<meshBasicMaterial {...material} color={rockColor} />
					<FogOutlines
						thickness={1.4}
						color="#534646"
						opacity={0.8}
						transparent
						screenspace
					/>
				</DeformedMesh>
				{blockBelow && (
					<DeformedMesh
						block={block}
						model={rockBottom}
						color={rockColor}
						position={[0, -0.025, 0]}
						scale={[0.5, 0.5, 0.5]}
						rotation={[...rot]}
						matrixAutoUpdate={false}
						matrixWorldAutoUpdate={false}
					>
						<meshBasicMaterial {...material} color={rockColor} />
						<FogOutlines
							thickness={1.4}
							color="#534646"
							opacity={0.8}
							transparent
							screenspace
						/>
					</DeformedMesh>
				)}
			</>
		);
	}, [block, blockBelow, material, model, orientation, rockBottom, rot]);

	return (
		<>
			<group>
				<ConstructableInput
					entity={entity}
					args={[0.5, 0.5, 0.5]}
					key={entity.ref}
				/>
				<group position={pos}>
					{renderer}
					{children.map((child) => {
						const Renderer = child?.component(CRenderer).renderer!;
						if (!child || !Renderer) return null;
						const pos = new Vector3(0, 0, 0);
						return (
							<Renderer key={child.ref} entity={child} props={{ pos, block }} />
						);
					})}
				</group>
			</group>
		</>
	);
}
