import { useUIStore } from "@game/ui/ui.store.js";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import CursorRenderer from "./cursor.renderer";
import { UILayout } from "../ui.layout";

type TMousePosition = { x: number; y: number };

const defaultRadius = 12 / 2;
const followSpeed = 0.3;
const zIndex = 100;

export function Cursor() {
	const isHoveringUI = useUIStore((state) => state.isHoveringUI);

	const [pos, setPos] = useState<TMousePosition>({
		x: window.innerWidth / 2,
		y: window.innerHeight * 1.2,
	});

	useEffect(() => {
		const mouseMove = (event: MouseEvent) => {
			setPos({
				x: event.clientX - defaultRadius,
				y: event.clientY - defaultRadius,
			});
		};
		window.addEventListener("mousemove", mouseMove);
		return () => {
			window.removeEventListener("mousemove", mouseMove);
		};
	}, []);

	const calculatePosition = (): TMousePosition => {
		return { x: pos.x, y: pos.y };
	};

	return (
		<UILayout data-name="cursor">
			<AnimatePresence mode="wait">
				<motion.div
					initial={{
						x: pos.x,
						y: pos.y,
						zIndex,
					}}
					animate={{
						x: calculatePosition().x,
						y: calculatePosition().y,
						zIndex,
					}}
					style={{
						position: "fixed",
						pointerEvents: "none",
						alignItems: "center",
						overflow: "visible",
						width: 0, //centers the cursor
						height: 0, //centers the cursor
					}}
					transition={{
						type: "tween",
						duration: followSpeed,
						ease: "circOut",
					}}
					id="mouse-follower"
				>
					{!isHoveringUI && <CursorRenderer />}
				</motion.div>
			</AnimatePresence>
		</UILayout>
	);
}
