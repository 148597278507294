import { truncateAddress } from "@/lib/utils";
import { getNetwork } from "@mud";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import worldABI from "contracts/out/IWorld.sol/IWorld.abi.json";
import { useEffect, useState } from "react";
import { type Hex, formatUnits, parseEther } from "viem";
import {
	type BaseError,
	useAccount,
	useBalance,
	useEnsName,
	useWaitForTransactionReceipt,
	useWatchBlockNumber,
	useWriteContract,
} from "wagmi";

export type TPaymentStatus = "pending" | "success" | "error" | "confirming";

export const useTourismPayments = () => {
	const { open } = useWeb3Modal();
	const [connectText, setConnectText] = useState("Connect");
	const [amount, setAmount] = useState(0.001);
	const { address, isDisconnected, isConnected } = useAccount();
	const { data: ensName, refetch: refetchEnsName } = useEnsName({ address });
	const balance = useBalance({ address });
	const [paymentStatus, setPaymentStatus] = useState<TPaymentStatus | null>(
		null,
	);

	const {
		writeContract,
		data: transactionHash,
		isError,
		isSuccess: isConfirmed,
		error,
	} = useWriteContract();
	const transactionReceipt = useWaitForTransactionReceipt({
		hash: transactionHash,
	});
	const { data: targetBalance, refetch: refetchBalance } = useBalance({
		address: "0x9464EdEa4F13AE45576d88d4A557Ce6E6c00164d",
	});

	useWatchBlockNumber({
		onBlockNumber(_blockNumber) {
			refetchBalance();
		},
	});

	useEffect(() => {
		refetchEnsName();
		if (isConnected && address) {
			const name = ensName || truncateAddress(address);
			setConnectText(name);
		}
		if (isDisconnected) {
			setConnectText("Connect");
		}
	}, [address, isDisconnected, isConnected, ensName, refetchEnsName]);

	const connect = () => {
		open();
	};

	useEffect(() => {
		if (!error) return;
		const e = error as BaseError;
		console.error("error", e.shortMessage);
		console.error("error", e.name);
	}, [error]);

	const pay = async (customAmount: number) => {
		if (!isConnected) {
			await open();
		}
		const { worldAddress } = getNetwork().networkConfig;
		setPaymentStatus("confirming");
		console.warn(
			"we need to check if user has balance, this TX can fail without error",
		);
		const tx = await writeContract({
			address: worldAddress as Hex,
			abi: worldABI,
			functionName: "jungle__Action_accelerate",
			args: [],
			value: parseEther((customAmount || amount).toString()),
		});
		console.log("tx", tx);
	};

	useEffect(() => {
		if (transactionHash) {
			console.log("Transaction hash:", transactionHash);
			refetchBalance();
		}
		if (isConfirmed) {
			setPaymentStatus("pending");
		}
		if (transactionReceipt.data) {
			setPaymentStatus("success");
		}
		if (isError) {
			setPaymentStatus("error");
		}
	}, [
		transactionHash,
		transactionReceipt,
		refetchBalance,
		isError,
		isConfirmed,
	]);

	return {
		connectText,
		connect,
		isConnected,
		pay,
		amount,
		setAmount,
		targetBalance: targetBalance
			? Number(formatUnits(targetBalance.value, 18))
			: 0,
		paymentStatus,
		error: error as BaseError | null,
		balance,
	};
};

export default useTourismPayments;
