import { getTouristDataByName } from "@/data/tourism.data";
import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { getTaskByRef } from "../sim.store";
import { type TTask, getPercentageComplete } from "../task";
import type { TAsyncInstFn } from "../types/sim.types";
import { SimComponent } from "./SimComponent";

type TGrowStage = {
	name?: string;
	timing: number;
	growFn?: TAsyncInstFn;
};

export class CGrowable extends SimComponent {
	stages: TGrowStage[] = [{ timing: 0 }]; // stage timings/rates
	currentStage = 0;
	growthPercent = 0;
	currentTime = 0;
	task: TTask | undefined = undefined;

	constructor(inst: SimEntity) {
		super(inst);
		this.loadFromChain();
	}

	setStages(stages: TGrowStage[]) {
		this.stages = stages;
		return this;
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Growable, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.error("no value for container", this.inst.ref);
			return;
		}
		for (let i = 0; i < value.stageTimes.length; i++) {
			if (this.stages[i]) {
				this.stages[i].timing = value.stageTimes[i];
			} else {
				this.stages[i] = { timing: value.stageTimes[i] };
			}
		}
		this.task = getTaskByRef(value.taskKey);
		this.checkGrowth();
	}

	isEmpty(): boolean {
		return this.stages.length === 0;
	}

	getCurrentStage(): TGrowStage {
		return this.stages[this.currentStage];
	}

	getDebugString(): string {
		const stage = this.getCurrentStage();
		return stage
			? `Growable: ${stage.name} (${stage.timing}/[${this.currentTime}])`
			: `Growable: empty || ERROR || [${this.currentTime}]`;
	}

	async checkGrowth() {
		if (!this.task) return;
		const task = this.task;
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		const multiplier =
			1 + (getTouristDataByName("Mangle Sagrada")! as any).progress();
		// console.log("🌱 Multiplier", multiplier);
		const value = Math.max(
			0,
			Math.min(100, getPercentageComplete(task) * multiplier),
		);
		this.growthPercent = Number(value) / 100;
		let totalTime = 0;
		for (let i = 0; i < this.stages.length; i++) {
			totalTime += this.stages[i].timing;
		}
		const time = Math.floor(totalTime * this.growthPercent);
		this.currentTime = time;
		let sum = 0;
		for (let i = 0; i < this.stages.length; i++) {
			if (time < this.stages[i].timing + sum) {
				this.currentStage = i;
				break;
			}
			sum += this.stages[i].timing;
		}
		if (time >= sum) this.currentStage = this.stages.length - 1;
	}
}
