import { Debug } from "@/lib/debug/debug";
import type {
	TEntity,
	TEntityDefinition,
	TEntityMetadata,
} from "./entity.data.types";
import { getBlueprints } from "../blueprint/blueprint.data";

const _ENTITIES: { readonly [id: number]: TEntityDefinition } = {};

export const getEntityData = () => {
	return { ..._ENTITIES };
};

export const addEntities = (entities: {
	readonly [id: number]: TEntityDefinition;
}) => {
	// check for duplicate entries
	Object.entries(entities).forEach(([id, entity]) => {
		if (_ENTITIES[Number(id)]) {
			Debug("Entities").warn(
				`Registering existing entity id ${id} (existing: ${_ENTITIES[Number(id)].name}, new: ${entity.name})`,
			);
		}
	});
	Object.assign(_ENTITIES, entities);
};

export const getEntityDataByID = (id: number): TEntity => ({
	entityTypeId: id,
	..._ENTITIES[id],
});

export const getEntityDataByName = (name: string): TEntity | undefined => {
	const entry = Object.entries(_ENTITIES).find(
		([_, value]) => value.name === name,
	);
	return entry ? { entityTypeId: parseInt(entry[0]), ...entry[1] } : undefined;
};
export const getEntityMetadataByID = (
	id: number,
): TEntityMetadata | undefined => getEntityData()[id]?.metadata;

export const getEntityMetadataByName = (
	name: string,
): TEntityMetadata | undefined =>
	Object.values(getEntityData()).find((e) => e?.name === name)?.metadata;

export const registerEntity = async (entity: TEntityDefinition) => {
	addEntities({ [entity.id]: entity });
};

export const logEntities = () => {
	Debug("Entities").groupCollapsed("✅ Entities Loaded");
	console.table(
		Object.values(getEntityData()).map((e) => ({ id: e.id, name: e.name })),
	);
	console.groupEnd();
	Debug("Blueprints").groupCollapsed("✅ Blueprints Loaded");
	console.table(Object.values(getBlueprints()).map((e) => ({ id: e.name })));
	console.groupEnd();
};
