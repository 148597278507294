import { getSafeRandomColor } from "@/data/colors/palette.data";
import { type TSeededRand, seededRand } from "@/lib/random/seeded.functions";
import { Directions } from "@/lib/utils";
import type { Block } from "@game/world/block";
import { Detailed, Line } from "@react-three/drei";
import { useMemo, useState } from "react";
import { QuadraticBezierCurve3, Vector3 } from "three";

export const Wires = ({ block }: { block: Block }) => {
	const { rng, colors, wiresArray } = useMemo(() => {
		const rng = seededRand(block._seed);
		const numWires = rng(1, 4);
		const colors: string[] = [];
		for (let i = 0; i < numWires; i++) {
			colors.push(getSafeRandomColor("wires", rng).hex);
		}
		const wiresArray: Vector3[][] = [];
		for (let i = 0; i < numWires; i++) {
			// Random start and end X and Z coordinates within the tile
			const startX = 0.35 * (rng(1, 100) / 100 - 0.5);
			const endX = 0.35 * (rng(1, 100) / 100 - 0.5);
			const startZ = 0.35 * (rng(1, 100) / 100 - 0.5);
			const endZ = 0.35 * (rng(1, 100) / 100 - 0.5);
			// Fixed Y coordinate to 1 (top of the tile)
			const startY = -1;
			const endY = -1;

			// Random droop amount between -0.5 and -1
			const randY = (rng(1, 100) / 100) * 0.2;
			const controlPointY = -1 * (rng(1, 100) / 100);

			// Random X, Z for control point
			const controlPointX = (startX + endX) / 2;
			const controlPointZ = (startZ + endZ) / 2;

			const startPoint = new Vector3(startX, startY, startZ).add(
				Directions.DOWN().multiplyScalar(-1),
			);
			const endPoint = new Vector3(endX, endY, endZ).add(
				Directions.DOWN().multiplyScalar(-1),
			);
			const controlPoint = new Vector3(
				controlPointX,
				controlPointY,
				controlPointZ,
			).add(Directions.DOWN().multiplyScalar(randY));

			// Create a quadratic bezier curve and get points along the curve
			const curve = new QuadraticBezierCurve3(
				startPoint,
				controlPoint,
				endPoint,
			);
			const points = curve.getPoints(30);

			wiresArray.push(points);
		}
		return { numWires, rng, colors, wiresArray };
	}, [block]);

	if (wiresArray.length === 0) return null;
	return (
		<group position={[0, -0.25, 0]}>
			{wiresArray.map((points, idx) => {
				return <Wire key={idx} points={points} color={colors[idx]} rng={rng} />;
			})}
		</group>
	);
};

const Wire = ({
	points,
	color,
	rng,
}: { points: Vector3[]; color: string; rng: TSeededRand }) => {
	const [width] = useState(rng(0, 10) * 0.1 + 0.13);
	return (
		<Detailed distances={[0, 10]}>
			<Line
				points={points}
				color={color}
				transparent
				opacity={0.4}
				linewidth={width}
				depthWrite={true}
				fog={true}
			/>
			<primitive object={{}} />
		</Detailed>
	);
};
