import { customFormatEther, truncateAddress } from "@/lib/utils";
import { UILayout } from "@game/ui/components/ui.layout";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { useAccount, useEnsName } from "wagmi";
import usePayments from "./usePayments";
import { ItemShopStore } from "@game/ui/components/itemShop/itemShop.store";

/*
 * @deprecated Try to deprecate this ASAP
 */
const ConnectButton = () => {
	const { open } = useWeb3Modal();
	const [connectText, setConnectText] = useState("Connect");
	const { address, isDisconnected, isConnected } = useAccount();
	const { data: ensName, refetch } = useEnsName({ address });
	const { balance } = usePayments();

	useEffect(() => {
		refetch();
		if (isConnected && address) {
			const name = ensName || truncateAddress(address!);
			setConnectText(name);
		}
		if (isDisconnected) {
			setConnectText("Connect");
		}
	}, [address, isDisconnected, isConnected, ensName, refetch]);

	return (
		<UILayout data-name="connect-button">
			<div className="flex items-right justify-right m-2 pt-[1px] gap-1">
				<div className="flex-grow" />

				{balance.data && (
					<div className="flex self-center text-white font-bold mr-2">
						{customFormatEther(balance.data?.value || 0n, 2).toString()}{" "}
						{ItemShopStore().currency}
					</div>
				)}
				<button
					className="uppercase text-white font-extrabold bg-transparent mx-auto px-4 py-2 rounded-full active:scale-95 transition-all disabled:bg-slate-600 hover:scale-105 border-2 border-white/0 relative overflow-clip hover:border-white/5 transition-all hover:bg-white/5 font-berkeley pointer-events-auto cursor-pointer"
					onClick={() => {
						console.log("Connect");
						open();
					}}
				>
					{connectText}
				</button>
			</div>
		</UILayout>
	);
};

export default ConnectButton;
