import { Debug } from "@/lib/debug/debug";
import { useGLTF, useTexture } from "@react-three/drei";
import { useEffect, useMemo, useTransition } from "react";
import type { Material, Mesh, Texture } from "three";
import type { GLTF } from "three-stdlib";
import { logVerbose } from "../dev.store";
import {
	ASSET_PRELOAD_IMAGES,
	ASSET_PATHS_MODELS as model_paths,
	ASSET_PATHS_TEXTURES as texture_paths,
	ASSET_TOTAL as total_assets,
} from "./asset.data";
import { AssetStore } from "./asset.store";
import { ImagePreloader } from "./imagePreloader";

let l = 0;

function ModelLoader({
	path,
	onComplete,
}: {
	path: string;
	onComplete: (name: string) => void;
}) {
	const [_isPending, startTransition] = useTransition();
	useEffect(() => {
		useGLTF.preload(path);
	}, [path]);

	const model = useMemo(() => {
		const m = useGLTF(path) as GLTF & {
			nodes: Record<string, Mesh>;
			materials: Record<string, Material>;
		};
		return m;
	}, [path]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (model) {
			startTransition(() => {
				for (const node of Object.values(model.nodes)) {
					try {
						const assetName = `${path.replace("/assets/", "").replace(".glb", "").toLowerCase()}/${node.name.toLowerCase().replace(".", "")}`;
						AssetStore().addMesh(assetName, node);
					} catch (error) {
						console.error(`Error adding model:${node.name}`, error);
					}
				}
				onComplete(path);
			});
		}
	}, [model]);

	return null;
}

function TextureLoader({
	path,
	onComplete,
}: {
	path: string;
	onComplete: (name: string) => void;
}) {
	const [_isPending, startTransition] = useTransition();
	useEffect(() => {
		useTexture.preload(path);
	}, [path]);

	const texture = useTexture(path) as Texture;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (texture) {
			startTransition(() => {
				try {
					const sanitizedPath = path
						.replace("/assets/", "")
						.replace(".png", "")
						.replace(".tga", "")
						.replace(".webp", "");
					// .replace(/[^a-zA-Z0-9\_]/g, "/");
					texture.flipY = false;
					texture.name = sanitizedPath;
					texture.needsUpdate = true;
					AssetStore().addTexture(sanitizedPath, texture);
				} catch (error) {
					console.error(`Error adding texture: ${path}`, error);
				}
				onComplete(path);
			});
		}
	}, [texture, path]);

	return null;
}

function AssetLoader() {
	const finishedLoading = async (_name?: string) => {
		if (l === 0 && logVerbose()) console.time("Asset Loading");
		l++;
		if (l >= total_assets) {
			AssetStore().set({
				loaded: l,
			});
			logVerbose() && console.timeEnd("Asset Loading");
			Debug("Asset").log("✅ Finished loading assets");
			if (!AssetStore().loadingComplete) {
				AssetStore().setComplete();
			}
		}
	};

	// if (import.meta.hot) {
	// 	import.meta.hot.on("assets-update", (data) => {
	// 		console.log("🌱 Asset update detected");
	// 		import.meta.hot!.invalidate();
	// 	});
	// }

	return (
		<>
			{model_paths.map((model) => (
				<ModelLoader key={model} path={model} onComplete={finishedLoading} />
			))}
			{texture_paths.map((texture) => (
				<TextureLoader
					key={texture}
					path={texture}
					onComplete={finishedLoading}
				/>
			))}
			<ImagePreloader imageUrls={ASSET_PRELOAD_IMAGES} />
		</>
	);
}

export default AssetLoader;
