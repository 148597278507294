import type { MUDChain } from "@latticexyz/common/chains";
import { arbitrumSepolia as _arbitrumSepolia } from "viem/chains";

export const testConduit = Object.assign(_arbitrumSepolia as MUDChain, {
	name: "Conduit Cloudlines",
	id: 573146821555,
	blockExplorers: {
		default: {
			name: "Conduit",
			url: "https://explorer-cloudlines-7gm4rnga08.t.conduit.xyz",
		},
	},
	rpcUrls: {
		default: {
			http: ["https://rpc-cloudlines-7gm4rnga08.t.conduit.xyz"],
			webSocket: ["wss://rpc-cloudlines-7gm4rnga08.t.conduit.xyz"],
		},
	},
});
