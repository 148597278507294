import Audio from "@game/audio/audioPlayer";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { ItemShovelRenderer } from "./shovel.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CContainer } from "@game/sim/components/CContainer";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CRummage } from "@game/sim/components/CRummage";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemGoldenShovelPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("shovel");
		e.addComponent(CBuildable);
		e.addComponent(CRenderer).setRenderer(ItemShovelRenderer);
		e.addComponent(CUsable)
			.setCanUseFn(this.canUseFn)
			.setUseFn(this.useFn)
			.setUseStrFn(this.useStrFn);
	}

	useStrFn = (act: BufferedAction) => {
		if (act.doer.component(CContainer).isFull()) {
			return "Inventory full";
		}
		if (
			act.target?._entityTypeID === 2 &&
			act.target.component(CRummage) &&
			!act.doer.component(CContainer).isFull()
		) {
			return "Use Golden Shovel";
		}
		return "";
	};

	// Use check for using shovel on a rock to mine
	canUseFn = (_doer: SimEntity, target: SimEntity) => {
		// HACK very hacky
		if (
			target._entityTypeID === 2 &&
			target.component(CRummage)
			// && !doer.component(CContainer).isFull()
		) {
			return true;
		}
		return false;
	};

	// Use function for using shovel on a rock to mine
	useFn = async (doer: SimEntity, target: SimEntity) => {
		if (doer.component(CContainer).isFull()) {
			Audio.playSound("PICKUP_LOCO");
			return;
		}
		if (
			target._entityTypeID === 2 &&
			target.component(CRummage) &&
			!doer.component(CContainer).isFull()
		) {
			await target.component(CRummage).rummage(doer);
		}
	};
}

const entityData: TEntityDefinition = {
	name: "Golden Shovel",
	simEntityPrefab: ItemGoldenShovelPrefab,
	id: 50001,
	metadata: {
		icon: "golden_shovel_2.webp",
		price: 85,
	},
} as const;

registerEntity(entityData);
