/**
 * HexGrid Seed generation
 */
function intToNat(num: number): number {
	return num >= 0 ? num * 2 : num * -2 - 1;
}

// used for hexgrid seed generation
export function cantorPairing(x: number, y: number): number {
	const nx = intToNat(x);
	const ny = intToNat(y);
	return ((nx + ny) * (nx + ny + 1)) / 2 + ny;
}

export function shuffleArray<T>(array: T[]): T[] {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}
