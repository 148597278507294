import type { Material, Mesh, Texture } from "three";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ASSET_PATHS_MODELS, ASSET_PATHS_TEXTURES } from "./asset.data";

export interface IAssetStore {
	loaded: number;
	total: number;
	loadingComplete: boolean;
	setComplete: () => void;
	meshes: Record<string, Mesh>;
	addMesh: (name: string, mesh: Mesh) => void;
	textures: Record<string, Texture>;
	addTexture: (name: string, texture: Texture) => void;
	materials: Record<string, Material>;
	addMaterial: (name: string, material: Material) => void;
}

const useAssetStore = create<IAssetStore>()(
	immer((set) => ({
		loaded: 0,
		total: ASSET_PATHS_MODELS.length + ASSET_PATHS_TEXTURES.length,
		loadingComplete: false,
		setComplete: () => {
			set((state) => {
				state.loadingComplete = true;
			});
		},
		meshes: {},
		addMesh: (name: string, mesh: Mesh) => {
			set((state) => {
				state.meshes[name.toLowerCase().replace(".", "")] = mesh;
			});
		},
		textures: {},
		addTexture: (name: string, texture: Texture) => {
			set((state) => {
				state.textures[name.toLowerCase().replace(".", "")] = texture;
			});
		},
		materials: {},
		addMaterial: (name: string, material: Material) => {
			set((state) => {
				state.materials[name.toLowerCase().replace(".", "")] = material;
			});
		},
	})),
);

const AssetStore = () => ({
	...useAssetStore.getState(),
	set: useAssetStore.setState,
});
export { AssetStore, useAssetStore };

/**
 * Retrieves a mesh from the asset store by its name.
 * @param name - The name of the mesh, without extension or path
 * @returns The mesh object if found, or undefined if not found.
 *
 * @example ```ts
 * const mesh = getMesh("house");
 * ```
 */
export const getMesh = (name: string) => {
	const meshName = name.toLowerCase().replace(".", "");
	const mesh = AssetStore().meshes[meshName];
	if (!mesh) {
		// if (AssetStore().meshes)
		// throw new ErrorBase({
		// 	name: "ASSET_NOT_FOUND",
		// 	message: `could not find mesh ${name}`,
		// });
		console.warn(`could not find mesh ${meshName}`);
	}
	return mesh;
};

export const getMaterial = (name: string) => {
	const materialName = name.toLowerCase().replace(".", "");
	const material = AssetStore().materials[materialName];
	if (!material) {
		// throw new ErrorBase({
		// 	name: "ASSET_NOT_FOUND",
		// 	message: `could not find material ${name}`,
		// });
		console.warn(`could not find material ${materialName}`);
	}
	return material;
};

/**
 * Retrieves a texture by its name from the asset store.
 * @param name - Texture path without /textures/ or extension
 * @returns The texture object if found, otherwise undefined.
 *
 * @example ```ts
 * const texture = getTexture("clouds/clouds00");
 * ```
 */
export const getTexture = (name: string) => {
	const textureName = name.toLowerCase().replace(".", "");
	const texture = AssetStore().textures[textureName];
	if (!texture) {
		// throw new ErrorBase({
		// 	name: "ASSET_NOT_FOUND",
		// 	message: `could not find texture ${name}`,
		// });
		console.warn(`could not find texture ${textureName}`);
	}
	return texture;
};

export const baseMesh = (path: string) => {
	return (meshName: string) => {
		return `${path}/${meshName}`;
	};
};
