import { cn } from "@/lib/utils";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import BottomLayout from "../ui.bottom";
import "./index.css";
import { LoadingSpinner } from "@/data/spinner";
import { availableProducts } from "@/data/tourism.data";
import type { TTourismProduct } from "@/data/tourism.data";
import { Debug } from "@/lib/debug/debug";
import useTourismPayments from "@/wallet/useTourismPayments";
import { theSim } from "@game/sim/sim.store";

const bannerAnims = {
	popScale: {
		scale: [1, 1.1, 1],
		duration: 0.24,
		zIndex: 10000,
		ease: "easeInOut",
	},
	errorTweak: {
		rotate: [0, -35, 0, 35, 0, -15, 0, 15, 0, -5, 0, 5, -0.485],
		duration: 2,
		zIndex: 10000,
		ease: "easeInOut",
	},
	doneTweak: {
		scale: [1.75, 0.5, 1.25, 0.95, 1],
		duration: 2,
		zIndex: 10000,
		ease: "easeInOut",
	},
	hover: {
		scale: [1.025],
		duration: 0.12,
		zIndex: 10000,
		ease: "easeInOut",
	},
	default: {
		scale: [1],
		duration: 0.12,
		zIndex: 10000,
		ease: "easeInOut",
	},
};

const barAnims = {
	popScale: {
		scale: [1],
		duration: 1.5,
		zIndex: 10100,
		ease: "easeInOut",
	},
};

export const TouristBanner = ({ product }: { product: TTourismProduct }) => {
	const [progressValue, setProgressValue] = useState(0);
	const { name, image, price, description, progress } = product;
	const { pay, targetBalance, paymentStatus } = useTourismPayments();
	const bannerControls = useAnimation();
	const barControls = useAnimation();

	useEffect(() => {
		const onBlockUpdate = () => {
			if (progress) {
				if (typeof progress === "function") {
					const pv = progress();
					setProgressValue(pv);
					console.log("🌱 Progress", pv);
				} else {
					setProgressValue(progress || targetBalance);
				}
			}
		};
		theSim.on("blockUpdate", onBlockUpdate);
		return () => {
			theSim.off("blockUpdate", onBlockUpdate);
		};
	}, [progress, targetBalance]);

	const handlePayment = async () => {
		await pay(price / 10000);
		bannerControls.start("popScale");
		Debug("Tourist").capture("Tourist payment", { product });
	};

	useEffect(() => {
		if (paymentStatus === "success") {
			barControls.start("popScale");
			bannerControls.start("doneTweak");
		}
		if (paymentStatus === "error") {
			bannerControls.start("errorTweak");
		}
		if (paymentStatus === "pending") {
			console.trace("pending");
		}
	}, [paymentStatus, barControls, bannerControls]);

	const isDisabled =
		paymentStatus === "pending" ||
		paymentStatus === "confirming" ||
		!product.enabled;
	const isPending = paymentStatus === "pending";

	return (
		<motion.div
			className="relative flex items-center justify-center w-full pointer-events-none"
			initial={{ opacity: 0, y: 100, scale: 0 }}
			animate={{ opacity: 1, y: 0, scale: 1 }}
			exit={{ opacity: 0, y: 150, scale: 0 }}
			transition={{ duration: 0.15 }}
		>
			<motion.div
				className="relative flex product-banner-wrap group pointer-events-auto transition-all hover:scale-105 scale-100"
				animate={bannerControls}
				variants={bannerAnims}
			>
				<div className="relative border border-black product-banner-border product-banner-shadow flex flex-row overflow-clip -z-10 p-2 grow">
					<div
						className="absolute w-full h-full rotate-[0.885deg] scale-110 z-0 -mt-3 ml-4 opacity-80"
						style={{
							background: "url(/tourism/BannerBg.png) no-repeat center/cover",
						}}
					/>
					<div className="relative flex items-center w-auto h-full aspect-square z-10">
						<div
							className="absolute top-0 left-0 w-full h-full rounded-full overflow-clip"
							style={{
								background: "url(/tourism/Stamp.png) no-repeat center/cover",
							}}
						/>
						<div
							className={cn("flex ml-[2%] w-[94%] h-[94%] rounded-full z-10")}
							style={{ background: `url(${image}) no-repeat center/cover` }}
						/>
					</div>
					<div className="flex flex-col ml-2 gap-0 grow -mt-2 z-10">
						<div className="flex flex-row items-baseline gap-2">
							<div className="text-black font-fredoka text-[2.375rem] font-semibold -mb-2 rotate-[-0.485deg]">
								{name.toLowerCase()}
							</div>
							<div className="price text-xl font-spaceGrotesk font-extrabold">
								🪙 {price}
							</div>
						</div>
						<div className="text-black font-spaceGrotesk pl-0.5">
							{description.toLowerCase()}
						</div>
					</div>
					<div className="flex items-center">
						<button
							className={cn(
								"bg-[#E8E8E8] text-black font-spaceGrotesk text-[1.5rem] font-semibold rounded-full p-1 px-2 h-12 border border-black buy-button-shadow w-[8rem] mr-3 pointer-events-auto z-[100000] disabled:shadow-none disabled:border-[#D2D2D2] items-center text-center relative justify-center self-center flex hover:border-[#193E46] select-none",
								!product.enabled && "text-sm",
							)}
							onClick={() => handlePayment()}
							disabled={isDisabled}
						>
							{isPending ? (
								<LoadingSpinner className="text-black" />
							) : product.enabled ? (
								"BUY"
							) : (
								"OUT OF STOCK"
							)}
						</button>
					</div>
				</div>
				{progress && (
					<motion.div
						className="absolute -bottom-[10%] bg-[#D9D9D9] rounded-full h-5 border border-black product-banner-shadow left-[15%] w-[60%] z-10 rotate-[-0.485deg] overflow-clip"
						animate={barControls}
						variants={barAnims}
					>
						<div
							className="absolute w-full h-full scale-110 z-0 rounded-full transition-all"
							style={{
								background: "#649C5C",
								width: `${progressValue * 100}%`,
							}}
						/>
					</motion.div>
				)}
			</motion.div>
		</motion.div>
	);
};

export const TouristProduct = ({
	product,
	onClick,
}: { product: TTourismProduct; onClick: () => void }) => {
	const { image, enabled } = product;
	return (
		<div
			className={cn(
				"flex flex-col items-center justify-center w-20 h-20 group pointer-events-auto transition-all cursor-pointer",
				!enabled && "grayscale-[100%] scale-95",
				enabled && "hover:scale-110",
			)}
			onClick={onClick}
		>
			<div
				className="w-20 h-20 rounded-full overflow-clip"
				style={{ background: "url(/tourism/Stamp.png) no-repeat center/cover" }}
			/>
			<div
				className={cn(
					"absolute w-[4.75rem] h-[4.75rem] rounded-full overflow-clip",
				)}
				style={{ background: `url(${image}) no-repeat center/cover` }}
			/>
		</div>
	);
};

export default function TourismBar() {
	const [selectedIndex, setSelectedIndex] = useState(-1);
	useState<TTourismProduct | null>(null);

	const handleClick = (product: TTourismProduct) => {
		const idx = availableProducts.findIndex((p) => p.name === product.name);
		if (idx >= 0) {
			if (idx !== selectedIndex) {
				setSelectedIndex(idx);
			} else {
				setSelectedIndex(-1);
			}
		}
	};

	return (
		<>
			<BottomLayout className="flex-col">
				<div className="relative">
					<AnimatePresence>
						{selectedIndex >= 0 && (
							<TouristBanner
								key={availableProducts[selectedIndex].name}
								product={availableProducts[selectedIndex]}
							/>
						)}
					</AnimatePresence>
				</div>
				<div className="flex flex-row items-center justify-center">
					{availableProducts.map((product) => (
						<TouristProduct
							key={product.name}
							product={product}
							onClick={() => handleClick(product)}
						/>
					))}
				</div>
			</BottomLayout>
		</>
	);
}
