export const SIGILS = {
	bamboo: "sigil_35",
	fertilizer: "sigil_30",
	limestone: "sigil_20",
	shovel: "sigil_45",
	vegetable: "sigil_34",
	pigment: "sigil_33",
	gemstone: "sigil_36",
	wheat: "sigil_31",
	// lapis: "",
	// meteorite: "sigil_14",
	// redOchre: "sigil_24",
	// goldenrod: "",
	// carrot: "",
	// berries: "",
	// turqoise: "",
	// pepper: "",
	// spinach: "",
	// yam: "",
	seeds: "sigil_29",
	// wood: "",
	// fish: "",
} as const;

export type TSigilType = keyof typeof SIGILS;

export const getSigilByIcon = (icon: string) => {
	return Object.entries(SIGILS).find(([key, value]) => value.includes(icon));
};
