import { baseMesh, getMesh, getTexture } from "@/data/assetLoader/asset.store";
import { getSafeRandomColor } from "@/data/colors/palette.data";
import {
	ColorMaskMaterial,
	type MaskMaterial,
} from "@/lib/materials/meshColorMaskMaterial";
import {
	randomFromArray,
	seededRand,
	type TSeededRand,
} from "@/lib/random/seeded.functions";
import { FogOutlines } from "@/lib/shaders/outline";
import { BoidSystem } from "@game/components/boidSystem";
import { ConstructableInput } from "@game/components/constructableInput";
import DeformedMesh from "@game/components/deformModel";
import { useEffect, useMemo, useRef, useState } from "react";
import { Color, type Vector3, type Mesh } from "three";
import type { SimEntity } from "../sim/SimEntity";
import { CRenderer } from "../sim/components/CRenderer";
import { tables, useMUDStore } from "@mud/index";
import type { Hex } from "viem";
import type { Block } from "@game/world/block";

const m = baseMesh("modular_set/modular_set");

const meshes = [m("building_scaffold002"), m("building_scaffold003")];
const decoratorMeshes = {
	30003: [
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_roof"),
		m("Building_stone_floor"),
	],
	30004: [
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_roof"),
		m("Building_sheet_floor"),
	],
};

const buildingModules = [
	m("Building_module_ac000"),
	m("Building_module_window.004"),
	m("Building_module_window.005"),
	m("Building_module_window.006"),
	m("Building_module_window.007"),
	m("Building_module_window.008"),
	m("Building_module_window.009"),
	m("Building_module_window.010"),
	m("Building_module_door.000"),
	m("Building_module_door.001"),
	// "Building_module_window.011",
	// "Building_module_window.013",
	// "Building_module_window.014",
];

const ModuleRenderer = ({
	id,
	block,
	mesh,
	orientation,
	material,
	scale = 2,
	position,
}: {
	id: string;
	block: Block;
	mesh: Mesh;
	orientation: number;
	material: MaskMaterial;
	scale?: number;
	position?: Vector3 | undefined;
}) => {
	return (
		<DeformedMesh
			key={id}
			block={block}
			model={mesh}
			color="white"
			orientation={orientation}
			matrixAutoUpdate={false}
			matrixWorldAutoUpdate={false}
			scale={[scale, scale, scale]}
			position={position}
		>
			<ColorMaskMaterial material={material} transparent={false} />
			<FogOutlines
				thickness={1.4}
				color="#534646"
				opacity={0.8}
				transparent
				screenspace
			/>
		</DeformedMesh>
	);
};

const DecoratorRenderer = ({
	id,
	block,
	mesh,
	orientation,
	material,
	rng,
}: {
	id: string;
	block: Block;
	mesh: Mesh;
	orientation: number;
	material: MaskMaterial;
	rng: TSeededRand;
}) => {
	// biome-ignore lint/correctness/useExhaustiveDependencies: <trigger on neighbour change>
	const { randomModule, randomPos } = useMemo(() => {
		if (orientation > 3 || block.hasNeighbourInDirections([orientation]))
			return { randomModule: null };
		if (!mesh) return { randomModule: null };
		const randomModule =
			rng(0, 100) > 50 ? randomFromArray(buildingModules, rng) : null;
		return {
			randomModule,
			randomPos: undefined,
		};
	}, [
		mesh,
		rng,
		block.neighbours, //trigger on neighbour change
		orientation,
		block.hasNeighbourInDirections,
	]);
	if (!mesh) return null;
	return (
		<>
			<ModuleRenderer
				id={id}
				block={block}
				orientation={orientation}
				material={material}
				mesh={mesh}
			/>
			{randomModule && (
				<ModuleRenderer
					id={`${id}${randomModule}`}
					block={block}
					mesh={getMesh(randomModule)}
					orientation={orientation}
					material={material}
					scale={2.082}
					position={randomPos}
				/>
			)}
		</>
	);
};

export function ScaffoldRenderer({ entity }: { entity: SimEntity }) {
	const [shouldUpdate, setShouldUpdate] = useState(true);
	const isHalfScaffold = useRef(false);

	const decoratorData = useMUDStore((state) =>
		state.getValue(tables.Decorator, { inst: entity.ref as Hex }),
	);

	const { renderer, block, rng, fullMesh } = useMemo(() => {
		const renderer = entity.component(CRenderer);
		const block = renderer.block!;
		const rng = seededRand(block._seed);
		const fullMesh = randomFromArray(meshes, rng);
		return { renderer, block, rng, fullMesh };
	}, [entity]);

	useEffect(() => {
		const onNeighbourUpdate = () => {
			setShouldUpdate(true);
		};

		entity.listenForEvent("neighbourUpdate", onNeighbourUpdate);
		return () => {
			entity.removeEventListener("neighbourUpdate", onNeighbourUpdate);
		};
	}, [entity]);

	const { model, material, pos, orientation } = useMemo(() => {
		if (shouldUpdate) {
			setShouldUpdate(false);
		}
		const orientation = renderer.orientation;
		const wasHalfScaffold = isHalfScaffold.current;
		const hasSideWall = decoratorData?.decoratorType.some(
			(d, idx) => (idx + orientation) % 4 === 2 && d > 0,
		);
		const halfScaffold =
			!hasSideWall &&
			!block.hasNeighbourInDirections([(orientation + 2) % 4, 5]);
		if (halfScaffold !== wasHalfScaffold) {
			isHalfScaffold.current = halfScaffold;
			block.getNeighbourEntities().forEach((e) => {
				e.pushEvent("neighbourUpdate", {});
			});
		}
		const model = getMesh(
			halfScaffold
				? "modular_set/modular_set/building_scaffold_half001"
				: fullMesh,
		);
		return {
			block,
			pos: block.position.toArray(),
			model,
			orientation,
			material: {
				maskColor: new Color(getSafeRandomColor("houses", rng).hex),
				map: getTexture("modular_set/GradientTexture_m_512"),
				opacity: 1,
				transparent: false,
			} satisfies MaskMaterial,
		};
	}, [
		block,
		rng,
		fullMesh,
		renderer,
		shouldUpdate,
		decoratorData?.decoratorType,
	]);

	const { decorators, walls } = useMemo(() => {
		if (!decoratorData) return { decorators: [], walls: 0 };
		const decorators = decoratorData.decoratorType.map((d, idx) => {
			if (d === 0) return { mesh: null };
			const mesh = getMesh(
				decoratorMeshes[d as keyof typeof decoratorMeshes]?.[idx] || "",
			);
			return { mesh };
		});
		return {
			decorators,
			walls: decorators.filter((d) => d.mesh !== null).length,
		};
	}, [decoratorData]);

	if (!block || !model) return;
	const renderMemo = useMemo(() => {
		return (
			<group>
				<ConstructableInput entity={entity} args={[1, 1, 1]} key={entity.ref} />
				<group position={[...pos]}>
					<DeformedMesh
						block={block}
						model={model}
						color="white"
						orientation={orientation}
						matrixAutoUpdate={false}
						matrixWorldAutoUpdate={false}
						scale={[2, 2, 2]}
					>
						<ColorMaskMaterial material={material} transparent={false} />
						<FogOutlines
							thickness={2}
							color="#534646"
							opacity={0.8}
							transparent
							screenspace
						/>
					</DeformedMesh>
					{/* {walls < 2 && <BoidSystem />} */}
					{decorators.map((d, idx) => {
						if (!d.mesh) return null;
						return (
							<DecoratorRenderer
								key={`${entity.ref + idx}wall`}
								id={`${entity.ref + idx}wall`}
								block={block}
								mesh={d.mesh}
								orientation={idx}
								material={material}
								rng={rng}
							/>
						);
					})}
				</group>
			</group>
		);
	}, [
		block,
		model,
		material,
		pos,
		orientation,
		entity,
		decorators,
		rng,
		walls,
	]);
	return <>{renderMemo}</>;
}
