import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPaint } from "@game/sim/components/CPaint";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemMeteoritePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(7);
		e.addComponent(CInventoryItem).setSigil("gemstone");
	}
}

const entityData: TEntityDefinition = {
	name: "Meteorite",
	simEntityPrefab: ItemMeteoritePrefab,
	id: 30203,
	metadata: {
		icon: "meteorite.webp",
	},
};

registerEntity(entityData);
