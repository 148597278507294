import { UserStore } from "@/data/user-settings.store";
import { UIStore } from "./ui.store";
import { getPlayerContainer } from "@game/game.functions";
import { ItemShopStore } from "./components/itemShop/itemShop.store";
import { Debug } from "@/lib/debug/debug";
import { logVerbose } from "@/data/dev.store";
import { theSim } from "@game/sim/sim.store";

export type TUIState = {
	showInventory?: boolean;
	showTourismbar?: boolean;
	showWalletButton?: boolean;
	showLoadingScreen?: boolean;
	showGameLogo?: boolean;
	showItemShop?: boolean;
	showSignupButton?: boolean;
	backgroundLoading?: boolean;
	onEnter?: () => void;
	onExit?: () => void;
};

let startTime = Date.now();

function findStateKeyByValue(obj: TUIState) {
	return Object.entries(UIStates).find((e) => e[1] === obj)?.[0];
}

export const setUIState = (newState: TUIState) => {
	const currentState = UIStore().uiState;
	if (currentState === newState) return;

	currentState?.onExit?.();
	logVerbose() &&
		Debug("UI-STATE").warn(
			`exited: ${findStateKeyByValue(currentState)} entered: ${findStateKeyByValue(newState)}`,
		);
	UIStore().set({
		uiState: newState,
	});
	newState.onEnter?.();
};

const defaultState: TUIState = {
	showInventory: false,
	showTourismbar: false,
	showWalletButton: false,
	showLoadingScreen: false,
	showGameLogo: false,
	showItemShop: false,
	showSignupButton: false,
	onEnter: () => {
		Debug("⏱️ TIME").log(`default: ${Date.now() - startTime}ms`);
		startTime = Date.now();
	},
};

export const UIStates = {
	cinematic: {
		...defaultState,
		showGameLogo: false,
		onEnter: () => {
			Debug("⏱️ TIME").log(`cinematic: ${Date.now() - startTime}ms`);
		},
	},
	loading: {
		...defaultState,
		showLoadingScreen: true,
		showGameLogo: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`loading: ${Date.now() - startTime}ms`);
			theSim.emit("loading");
		},
	},
	mainMenu: {
		...defaultState,
		showLoadingScreen: false,
		showGameLogo: true,
		onEnter: async () => {
			Debug("⏱️ TIME").log(`menu: ${Date.now() - startTime}ms`);
			if (UserStore().isCinematic()) {
				Debug("Game").log("Cinematic mode");
				setUIState(UIStates.cinematic);
				return;
			}
			// check if player or spectator
			const pc = await getPlayerContainer();
			if (pc) {
				if (UserStore().isPlayer()) {
					setUIState(UIStates.playMode);
					return;
				}
			}
			if (UserStore().isSpectator()) {
				setUIState(UIStates.tourismMode);
				return;
			}
			setUIState(UIStates.readyToSignup);
		},
	},
	playMode: {
		...defaultState,
		showInventory: true,
		backgroundLoading: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`playMode: ${Date.now() - startTime}ms`);
		},
	},
	tourismMode: {
		...defaultState,
		showTourismbar: true,
		showWalletButton: true,
	},
	readyToSignup: {
		...defaultState,
		showGameLogo: true,
		showItemShop: false,
		showSignupButton: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`readyToSignup: ${Date.now() - startTime}ms`);
		},
	},
	signUp: {
		...defaultState,
		showItemShop: true,
		showWalletButton: true,
		backgroundLoading: true,
		onExit: () => {
			ItemShopStore().set({
				detailedProduct: ItemShopStore().playpass,
			});
		},
	},
} as const;

export type TUIStates = keyof typeof UIStates;
