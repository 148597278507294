import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemBedPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem);
		e.addComponent(CBuildable);
	}
}

const entityData: TEntityDefinition = {
	name: "Bed",
	simEntityPrefab: ItemBedPrefab,
	id: 40000,
	metadata: {
		icon: "bed.webp",
	},
};

registerEntity(entityData);
