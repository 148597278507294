import { DATA_PALETTES } from "@/data/colors/palette.data";
import { type Hex, keccak256, pad, toHex } from "viem";

// function that is the same as formatBytes but instead creates a react object with colored spans:
export const formatBytes32React = (str: Hex) => {
	const hash = keccak256(str);
	const colors = Object.values(DATA_PALETTES.hashes.colors);
	const modMax = colors.length;

	const getChunkColor = (chunk: string) => {
		const value = Array.from(chunk).reduce(
			(sum, char) => sum + char.charCodeAt(0) ** 2,
			0,
		);
		return colors[value % modMax].hex;
	};

	const final = (
		<>
			<span style={{ color: "#777777" }}>0x</span>
			<span style={{ color: getChunkColor(hash.slice(-4)) }}>
				{str.slice(2, 6)}
			</span>
			<span style={{ color: getChunkColor(hash.slice(8, 12)) }}>
				{str.slice(6, 10)}
			</span>
			<span style={{ color: "#999999" }}>...</span>
			<span style={{ color: getChunkColor(hash.slice(-12, -8)) }}>
				{str.slice(-8, -4)}
			</span>
			<span style={{ color: getChunkColor(hash.slice(4)) }}>
				{str.slice(-4)}
			</span>
		</>
	);

	return final;
};
export const formatBytes32String = (str: Hex) => {
	const hash = keccak256(str);
	const colors = Object.values(DATA_PALETTES.hashes.colors);
	const modMax = colors.length;

	const getChunkColor = (chunk: string) => {
		const value = Array.from(chunk).reduce(
			(sum, char) => sum + char.charCodeAt(0) ** 2,
			0,
		);
		return colors[value % modMax].hex;
	};

	const final = [
		`%c0x%c${str.slice(2, 6)}%c${str.slice(6, 10)}%c...%c${str.slice(
			-8,
			-4,
		)}%c${str.slice(-4)}`,
	];
	final.push("color: #777777");
	final.push(`color: ${getChunkColor(hash.slice(-4))}`);
	final.push(`color: ${getChunkColor(hash.slice(8, 12))}`);
	final.push("color: #999999");
	final.push(`color: ${getChunkColor(hash.slice(-12, -8))}`);
	final.push(`color: ${getChunkColor(hash.slice(4))}`);

	return final;
};

export const getHex = (num: number) => {
	return pad(toHex(num));
};
