import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CGrower } from "@game/sim/components/CGrower";
import type { CPlot } from "@game/sim/components/CPlot";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { FarmRenderer } from "./farm.renderer";
import {
	type TBluePrint,
	registerBlueprint,
} from "@/data/blueprint/blueprint.data";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";
import type { Hex } from "viem";
import Audio from "@game/audio/audioPlayer";

export class FarmPrefab extends SimEntityPrefab {
	grower: CGrower | null = null;
	plot: CPlot | null = null;

	constructor(e: SimEntity) {
		super(e);
		this.grower = e.addComponent(CGrower);
		e.addComponent(CRenderer).setRenderer(FarmRenderer);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
	}

	update = () => {
		this.grower?.loadFromChain();
		this.plot?.loadFromChain();
	};
}

const entityData: TEntityDefinition = {
	name: "Farm",
	simEntityPrefab: FarmPrefab,
	id: 501,
};

const blueprint = {
	name: "Farm",
	tools: [{ name: "Shovel" }],
	ingredients: [],
	buildingSide: "TOP",
	requiresNeighbour: ["BOTTOM"],
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT");
		const faceIndex = UIStore().faceIndex;
		const block = origin.component(CRenderer).block;
		if (faceIndex === -1) throw new Error("faceIndex not set");
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(block, faceIndex);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;

registerEntity(entityData);
registerBlueprint(blueprint);
